import { defineStore } from "pinia"
export function createCustomMapStore(storeId) {
  return defineStore(storeId, {
    state: () => ({
      mapData: {},
      cycleAggregates: null,
      visibleLayers: [],
      mapLayers: {"Hexagon": [], "Trip": [], "Cycles": [], "Circle": []},
      filterChildObjects: {},
      filterDesc: {}
    }),
    getters: {
      getAggregate: (state) => (aggregateName) => {
        return state.mapData[aggregateName]
      },
      getMapLayersWithName: (state) => (mbIdValue) => {
        const mapLayers = state.mapLayers
        for (const key in mapLayers) {
          if (Array.isArray(mapLayers[key])) {
            const found = mapLayers[key].some(item => item.mbId === mbIdValue)
            if (found) {
              return true
            }
          }
        }
        return false
      },
      getStateForKey: (state) => (stateKeyFor) => {
        return state[stateKeyFor]
      },
      getCycleAggregates: (state) => {
        return state.cycleAggregates
      },
      getLayerVisibility: (state) => (layerName) => {
        return state.visibleLayers.indexOf(layerName) > -1
      },
      getFilterChildObject: (state)  =>  {
        return state.filterChildObjects
      },
      getLayers: (state) => {
        return state.visibleLayers
      },
      getFilterDesc: (state) => {
        return state.filterDesc
      }
    },
    actions: {
      setAggregate(aggregateName, aggValue) {
        this.mapData[aggregateName] = Object.freeze(aggValue)
      },
      setAggregateProp(aggregateName, itemKey, itemId, aggregateProp, aggValue) {
        this.mapData[aggregateName].find(obj => obj[itemKey] === itemId)[aggregateProp] = Object.freeze("")
        this.mapData[aggregateName].find(obj => obj[itemKey] === itemId)[aggregateProp] = Object.freeze(aggValue)
      },
      resetAggregate(aggregateName) {
        // Reset the array to an empty, frozen array
        this.mapData[aggregateName] = Object.freeze([])
      },
      setMapLayer(mapLayer) {
        this.mapLayers[mapLayer.visualisedAs] = [...this.mapLayers[mapLayer.visualisedAs], mapLayer]
      },
      removeMapLayer(mapLayer) {
        const layers = this.mapLayers[mapLayer.visualisedAs]
        const indexToRemove = layers.findIndex(layer => layer === mapLayer)
        if (indexToRemove !== -1) {
          // Remove the layer at the found index
          layers.splice(indexToRemove, 1)
        }
      },
      setMapLayerProp(mapType, mapLayerIndex, propName, propVal) {
        if (typeof this.mapLayers[mapType][mapLayerIndex] !== "object" || this.mapLayers[mapType][mapLayerIndex] === null) {
          this.mapLayers[mapType][mapLayerIndex] = {}
        }

        this.mapLayers[mapType][mapLayerIndex][propName] = propVal
      },
      setLayerVisibility(layerName, visibility) {
        if (this.visibleLayers.indexOf(layerName) > -1) {
          if (!visibility) {
            this.visibleLayers = this.visibleLayers.filter((e) => e !== layerName)
          }
        } else {
          if (visibility) {
            this.visibleLayers.push(layerName)
          }
        }
      },
      setFilterChildObject(Obj) {
        this.filterChildObjects = Obj
      },
      setFilterDesc(Obj) {
        this.filterDesc = Obj
      },
      setFilterChildArrayToObject(Obj, lName) {
        this.filterChildObjects[lName] = Obj
      },
      setFilterReplaceObject(updatedValues) {
        updatedValues.forEach((value, index) => {
          const selectedText = value.selectedTexts[0]
          const selectedType = value.selectedInputType
          let selectedRanges
          if (selectedType === "number") {
            selectedRanges = value.selectedRanges.map(Number)
          }
          if (selectedType === "date") {
            selectedRanges = value.selectedDates
          }
          const originalObjectKey = value.selectedModuleLayerName

          if (originalObjectKey) {
            // Find and replace the corresponding object in the originalObject
            const originalObjectIndex = this.filterChildObjects[originalObjectKey].findIndex(
              (obj) => obj.name === selectedText
            )

            if (originalObjectIndex !== -1) {
              this.filterChildObjects[originalObjectKey][originalObjectIndex] = {
                name: selectedText,
                type: selectedType,
                min: !selectedRanges ? "null" : selectedRanges[0].toString(),
                max: !selectedRanges ? "null" : selectedRanges[1].toString(),
                layerName: this.filterChildObjects[originalObjectKey][originalObjectIndex].layerName,
                uniqueValue: value.selectedSplitStrings
              }
            }
          }
        })
      },
      async removeFilterChildObject(index, layerName, namedModule) {
        this.filterChildObjects[namedModule + "_" + layerName].forEach((child) => {
          if (child.layerName === layerName && this.filterChildObjects[namedModule + "_" + layerName].indexOf(child) === index) {
            this.filterChildObjects[namedModule + "_" + layerName].splice(index, 1)
          }
        })
      },
      setCycleAggregates(cycleAggregates) {
        this.cycleAggregates = Object.freeze(cycleAggregates)
      }
    }
  })()
}
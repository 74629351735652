<template>
  <v-overlay
    v-model="visible"
    class="survey-sidebar"
    width="55%"
    height="100%"
  >
    <div
      v-for="index in currentIndex"
      :key="'vff' + index"
    >
      <flow-form
        v-if="index === currentIndex"
        ref="flowform"
        :questions="questions"
        :language="language"
        :standalone="true"
        @complete="onComplete"
        @submit="onSubmit"
      >
        <!-- Custom content for the Complete/Submit screen slots in the FlowForm component -->
        <!-- We've overriden the default "complete" slot content -->
        <template #complete>
          <div
            class="f-section-wrap"
            style="margin-bottom: 120px;"
          >
            <p>
              <span
                v-if="!submitted"
                class="f-section-text"
              >
                Use the arrows if you want change anything.
              </span>
            </p>
          </div>
        </template>
        <!-- We've overriden the default "completeButton" slot content -->
        <template #completeButton>
          <div
            v-if="!submitted"
            class="f-submit"
          >
            <button
              ref="button"
              class="o-btn-action"
              type="submit"
              aria-label="Send your feedback"
              @click.prevent="onSendData()"
            >
              <span>{{ language.submitText }}</span>
            </button>
          </div>
          <p
            v-if="submitted"
            class="text-success"
          >
            Submitted successfully.
          </p>
          <span
            v-if="submitted"
            class="fh2"
          >Thank you. 🙏 </span>
          <button
            v-if="submitted"
            class="o-btn-action"
            @click="resetSurvey()"
          >
            Send new answers
          </button>
        </template>
      </flow-form>
    </div>
  </v-overlay>
</template>

<script setup>
import { FlowForm, LanguageModel } from "@ditdot-dev/vue-flow-form"
import questionModel from "@/helpers/mappy-feedback"
import axios from "axios"
import lz from "lz-string"
import {onMounted, onUnmounted, ref, watch} from "vue"
import {getSessionId, log} from "@/plugin/logger"
import {authService} from "@/services/AuthService"

const props = defineProps({
  visible: {
    type: Boolean,
    required: true
  }
})

const flowform = ref()
const submitted = ref(false)
const completed = ref(false)
const language = ref(new LanguageModel())
const timer = ref(null)
const visible = ref(false)
const questions = ref(questionModel.QUESTIONS)
const currentIndex = ref(1)
const asBearerToken = (token) => {
  return `Bearer ${token}`
}
const resetSurvey = () => {
  questions.value.forEach(question => question.answer = null)
  currentIndex.value++
}

const onKeyListener = (event) => {
  if (completed.value && !submitted.value) {
    onSendData()
  }
}

const onComplete = (completedStatus, questionList) => {
  completed.value = completedStatus
}

const onSubmit = (questionList) => {
  onSendData()
}

const getData = () => {
  const data = []
  questions.value.forEach(question => {
    if (question.title) {
      let answer = question.answer
      if (Array.isArray(answer)) {
        answer = answer.join(", ")
      }
      data.push({ "question": question.title, "answer": answer })
    }
  })
  return data
}

const onSendData = () => {
  const data = getData()
  const feedbackObj = {
    feedback: data,
    userName: authService.getUserEmail(),
    sessionId: getSessionId(),
    timeForResponse: new Date().getTime()
  }
  const lambdaPath = "/Prod/PostFeedback"
  let endpoint = process.env.VUE_APP_UX_FEEDBACK_ENDPOINT

  const url = endpoint + lambdaPath
  const request = axios.create()

  request.post(url, lz.compressToEncodedURIComponent(JSON.stringify(feedbackObj)), {
    headers: {
      Authorization: asBearerToken(authService.keycloak.token)
    }
  }).then(res => {
    flowform.value.submitted = true
    log("info", `{survey:{"file":"Survey.vue"},{"method":"onSendData"},{"feedbackObj":"${JSON.stringify(feedbackObj)}"}}`, "info")
    submitted.value = true
  })
}

onMounted(() => {
  document.addEventListener("keyup", onKeyListener)
})

onUnmounted(() => {
  document.removeEventListener("keyup", onKeyListener)
})

watch(() => props.visible, (newVal) => {
  visible.value = props.visible
}, { immediate: true })
</script>

<style>
@import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.css';
@import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-purple.css';
.v-overlay__content {
  background-color: white !important;
}
</style>

<style scoped>
.surveyIcon {
  height: 12px;
  width: 12px;
  position: absolute;
  z-index: 1004;
  left: 0;
  bottom: 0;
  margin-bottom: 60px;
  margin-left: 22px;
  color: white;
}

.survey-sidebar {
  left: 0;
}

.v-overlay__content {
  background-color: white !important;
}

</style>

<script setup>
import SideMenu from "@/components/SideMenu"
import SideMenuItems from "@/components/SideMenuItems"
import MenuItem from "@/components/MenuItem"
import {computed, inject, ref} from "vue"
import MapLayerSettings from "@/components/MapLayerSettings"
import MapLegend from "@/components/MapLegend"
import MapDataSettings from "@/components/MapDataSettings.vue"
import {useMap} from "@/composables"
import FleetFilter from "@/components/FleetFilter.vue"
import FleetMapDataChart from "@/components/FleetMapDataChart.vue"

const mapSettings = {
  layerSettings: ref(),
  legend: ref(),
  dataSettings: ref(),
  dataFilter: ref(),
  chartSettings: ref()
}

const props = defineProps({
  layers: {
    type: Array,
    required: true
  },
  hexagonLayers: {
    type: Array,
    required: true
  },
  flowLayers: {
    type: Array,
    required: true
  },
  namedModule: {
    type: String,
    required: true
  },
  dataSetsGetter : {
    type: String,
    default: "getDataSetOptionsForMap"
  }
})

const useCustomStore = inject(props.namedModule)
const { map } = useMap()

const clickedMenuItem = ref("")
const openMenuItemATCoord = ref({top: "0px", right: "0px", left: "0px"})
const mapViewName = ref(props.namedModule)
const containsNumber = computed(() =>{
  return /\d/.test(mapViewName.value)
})

const openMenuFor = (menuItemClicked, event) => {
  clickedMenuItem.value === menuItemClicked ?
      clickedMenuItem.value = "" :
      clickedMenuItem.value = menuItemClicked
  event.preventDefault()

  const target = mapSettings[menuItemClicked]?.value.$el.getBoundingClientRect()
  const targetPane = mapSettings[menuItemClicked]?.value.$el.closest(".splitpanes__pane").getBoundingClientRect()
  openMenuItemATCoord.value.top = (target.y - targetPane.y) + "px"
  openMenuItemATCoord.value.right = (target.width*1.8) + "px"

  if(clickedMenuItem.value === "layerSettings"){
    let createArrayFilterObjects = {}
    let arrLayers= props.layers
    arrLayers.map(layer => {
      const layerStringName = props.namedModule + "_" + layer.layerName
      createArrayFilterObjects[layerStringName] = []
    })
    useCustomStore.setFilterChildObject(mergeObjectsArrays(useCustomStore.getFilterChildObject, createArrayFilterObjects))
    }
}
function mergeObjectsArrays(a, b) {
  const result = { ...a }
  for (let key in b) {
    if (a.hasOwnProperty(key)) {
      result[key] = result[key].concat(b[key])
    } else {
      result[key] = b[key]
    }
  }
  return result
}

function viewNameChanged(e) {
  mapViewName.value=e.target.value
}

</script>

<template>
  <tds-text-field
    id="map-view-name"
    class="map-view-name-text-field"
    name="map-view-name"
    no-min-width="true"
    type="text"
    size="sm"
    :value="mapViewName"
    @input="viewNameChanged"
  />
  <SideMenu
    v-show="clickedMenuItem === 'dataFilter'"
    :style-top="openMenuItemATCoord.top"
    :style-right="openMenuItemATCoord.right"
  >
    <template #main>
      <FleetFilter
        :named-module="props.namedModule"
      />
    </template>
  </SideMenu>
  <SideMenu
    v-show="clickedMenuItem === 'layerSettings'"
    :style-top="openMenuItemATCoord.top"
    :style-right="openMenuItemATCoord.right"
  >
    <template #main>
      <MapLayerSettings
        v-for="(layer, index) in layers"
        v-show="clickedMenuItem==='layerSettings'"
        :key="index+'_'+layer.layerName"
        :layer-name="layer.layerName"
        :layer-type="layer.canBe"
        :layer-data-for-domain="layer.accessorInState"
        :accessor-key-for-data-function="layer.accessorKey"
        :named-module="props.namedModule"
      />
      <tds-block
        v-if="layers.length < 1"
        style="max-width: 150px"
      >
        <h6
          style="font-size: 12px; color: black"
        >
          There is no map layer added. <br>Click  <font-awesome-icon
            icon="file-import"
            style="cursor: default;color: deeppink;height: 15px;"
            @click="openMenuFor('dataSettings', $event)"
          /> to add data to the map.
        </h6>
      </tds-block>
    </template>
  </SideMenu>
  <SideMenu
    v-show="clickedMenuItem === 'legend'"
    :style-top="openMenuItemATCoord.top"
    :style-right="openMenuItemATCoord.right"
  >
    <template #main>
      <MapLegend
        v-for="(layer, index) in hexagonLayers"
        v-show="clickedMenuItem==='legend'"
        :key="index+'_'+layer"
        :layer-name="layer.layerName"
        :layer-data-for-domain="layer.accessorInState"
        :accessor-key-for-data-function="layer.accessorKey"
        :key-as-string="layer.colourKey"
        :elev-key-as-string="layer.elevationKey"
        :elev-scale-as-string="layer.elevationScale"
        :color-schema="layer.colourSchema"
        :color-count="layer.colourCount"
        :color-scheme-name="layer.colorSchemeName"
        :named-module="props.namedModule"
        :sweete="true"
      />
      <tds-block
        v-if="layers.length < 1"
        style="max-width: 150px"
      >
        <h6
          style="font-size: 12px;  color: black"
        >
          There is no map layer added. <br>Click  <font-awesome-icon
            icon="file-import"
            style="cursor: default;color: deeppink;height: 15px;"
            @click="openMenuFor('dataSettings', $event)"
          /> to add data to the map.
        </h6>
      </tds-block>
    </template>
  </SideMenu>
  <SideMenu
    v-show="clickedMenuItem === 'chartSettings'"
    :style-top="openMenuItemATCoord.top"
    :style-right="openMenuItemATCoord.right"
  >
    <template #main>
      <FleetMapDataChart />
    </template>
  </SideMenu>
  <SideMenu
    v-if="containsNumber"
    v-show="clickedMenuItem === 'dataSettings'"
    :style-top="openMenuItemATCoord.top"
    :style-right="openMenuItemATCoord.right"
  >
    <template #main>
      <MapDataSettings
        :named-module="props.namedModule"
        :data-sets-getter="props.dataSetsGetter"
      />
    </template>
  </SideMenu>

  <SideMenuItems>
    <template #menu-item-list>
      <MenuItem
        :ref="mapSettings.dataFilter"
        icon-name="filter"
        :clicked-styling="clickedMenuItem === 'dataFilter'"
        @click="openMenuFor('dataFilter', $event)"
      />
      <MenuItem
        :ref="mapSettings.layerSettings"
        icon-name="layer-group"
        :clicked-styling="clickedMenuItem === 'layerSettings'"
        @click="openMenuFor('layerSettings', $event)"
      />
      <MenuItem
        :ref="mapSettings.legend"
        icon-name="palette"
        :clicked-styling="clickedMenuItem === 'legend'"
        @click="openMenuFor('legend', $event)"
      />
      <MenuItem
        :ref="mapSettings.chartSettings"
        icon-name="line-chart"
        :clicked-styling="clickedMenuItem === 'chartSettings'"
        @click="openMenuFor('chartSettings', $event)"
      />
      <MenuItem
        v-if="containsNumber"
        :ref="mapSettings.dataSettings"
        icon-name="file-import"
        :clicked-styling="clickedMenuItem === 'dataSettings'"
        @click="openMenuFor('dataSettings', $event)"
      />
    </template>
  </SideMenuItems>
</template>
<style scoped>

.map-view-name-text-field {
  opacity:60%;--tds-text-field-data-color: white;
  --tds-text-field-background: #0d0f13;
  --tds-text-field-border-bottom:#0d0f13;
  --tds-text-field-border-bottom-hover:deeppink;
  border-radius: 4px;
  top: 15px;
  right: 60px;
  position: absolute;
  text-align: center;
}

</style>
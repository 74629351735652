<template>
  <transition name="modal-fade">
    <div
      v-show="isVisible"
      class="chassis-selection-modal-backdrop"
    >
      <div class="chassis-selection-modal">
        <section class="modal-mid">
          <slot name="body">
            <div>
              <v-btn
                id="closeModal"
                variant="outlined"
                icon
                @click="closeModal"
              >
                <tds-icon
                  id="closeModalIcon"
                  name="cross"
                />
              </v-btn>
            </div>
            <v-tabs
              v-model="tab"
              :grow="true"
              color="pink-accent-2"
              align-tabs="center"
            >
              <selection-tab-section
                tab-value="chassisSelection"
                tab-text="Choose chassis"
              />
              <selection-tab-section
                v-if="marketAccessAllowed"
                tab-value="marketChassisSelection"
                tab-text="New fleet"
              />
            </v-tabs>
            <v-alert
              v-if="errorMessageHistorical.length > 0"
              variant="plain"
              dismissible
            >
              {{ errorMessageHistorical }}
            </v-alert>
            <v-card-text class="modal-content">
              <v-window v-model="tab">
                <chassis-selection
                  v-if="chassisAccessAllowed"
                  tab-value="chassisSelection"
                  :received-stops="receivedStops"
                  :stop-count="stopCount"
                  :min-time="minTime"
                  :max-time="maxTime"
                  :number-of-chassis-requested="numberOfChassisRequested"
                  :number-of-chassis-in-set="numberOfChassisInSet"
                  :set-name="setName"
                  tab-name="Choose chassis"
                  post-endpoint="/Prod/PostQuery"
                  @handle-button="setButton"
                  @handle-on-data-response="setInfo"
                  @predict-query-time="predictQueryTime"
                  @handle-on-response-error="setErrorMessage"
                  @handle-chassis-selection-reset="handleReset"
                  @handle-data-ready="handleDataReady"
                  @handle-on-view-selection="setUserSelection('chassis')"
                  @handle-on-data-loaded="dataDoneLoading"
                />
                <chassis-selection
                  v-if="marketAccessAllowed"
                  tab-value="marketChassisSelection"
                  :received-stops="receivedStops"
                  :stop-count="stopCount"
                  :min-time="minTime"
                  :max-time="maxTime"
                  :number-of-chassis-requested="numberOfChassisRequested"
                  :number-of-chassis-in-set="numberOfChassisInSet"
                  :set-name="setName"
                  post-endpoint="/Prod/PostMarketQuery"
                  lambda-path="/Prod/PostSnowflakeMarketQueryCache"
                  tab-name="New fleet"
                  @handle-button="setButton"
                  @handle-on-data-response="setInfo"
                  @handle-data-ready="handleDataReady"
                  @predict-query-time="predictQueryTime"
                  @handle-on-response-error="setErrorMessage"
                  @handle-chassis-selection-reset="handleReset"
                  @handle-on-view-selection="setUserSelection('chassis-bu')"
                  @handle-on-data-loaded="dataDoneLoading"
                />
              </v-window>
            </v-card-text>
          </slot>
        </section>
      </div>
    </div>
  </transition>
</template>

<script setup>
import axios from "axios"
import ChassisSelection from "@/components/ChassisSelection.vue"
import {ref, computed, watch, defineEmits, onUnmounted} from "vue"
import { authService } from "@/services/AuthService"
import { getSessionId, log } from "@/plugin/logger"
import SelectionTabSection from "@/components/SelectionTabSection.vue"
import {useMainStore} from "@/store/mainStore"
import {useFleetStore} from "@/store/fleet/fleetStore"

const mainStore = useMainStore()
const fleetStore = useFleetStore()

const emit = defineEmits(["handle-on-data-loaded"])

const tab = ref(null)
const readyForReset = ref([])
const receivedStops = ref(false)
const chassis = ref("")
const requestedChassis = ref("")
const returnedChassis = ref([])
const numberOfChassisRequested = ref(0)
const numberOfChassisInSet = ref(0)
const info = ref(null)
const showInfo = ref()
const stopCount = ref(null)
const minTime = ref(null)
const maxTime = ref(null)
const setName = ref(null)
const message = ref(null)
const type = ref("")
const isVisible = ref(false)
const button = ref({ info: "Get data", isFetchingDataOnRequest: false, state: "btn-success" })
const errorMessageHistorical = ref("")

const locationAccessAllowed = computed(() => authService.hasADRoles(["charging-infra"]))
const marketAccessAllowed = computed(() => authService.hasADRoles(["mappy-user"]))
const chassisAccessAllowed = computed(() => authService.hasADRoles(["mappy-user"]))
const token = mainStore.getBearerToken

function createWorker() {
  return new Worker(
      new URL("../web-worker/snowflakeWorker.js", import.meta.url),
      { type: "module" }
  )
}

const tilesApi = createWorker()
const postMessageToTilesAPI = (token, logMessage) => {
  log("info", logMessage)
  const endpoint = mainStore.getAppConfigFlag("useStagingStack")
      ? process.env.VUE_APP_CF_DIST_ONE_ENDPOINT_B : process.env.VUE_APP_CF_DIST_ONE_ENDPOINT
  const url = endpoint + "/" + setName.value + "/.stop_done"

  const delay = fleetStore.getQueryTimeInSeconds

  tilesApi.postMessage({
    method: "getStatusForSweetETiles",
    args: [ token, url, delay ]
  })
}

tilesApi.onmessage = (event) => {
  if (event.data.key === "tilesReady") {
    log("info", "Tiles data ready")
    fleetStore.setStopLayerLoaded(true)
    emit("handle-on-data-loaded", "STOPS")
    tilesApi.terminate()
  }
}

// Watch for changes in Vuex signedIn state
watch(
    chassisAccessAllowed,
    (newValue) => {
      isVisible.value = !isVisible.value
    },
    { immediate: true }
)

// Watch for changes in readyForReset
watch(
    readyForReset,
    (newValue) => {
      if (newValue.length === 2) {
        chassis.value = ""
        info.value = null
        stopCount.value = null
        minTime.value = null
        maxTime.value = null
        setName.value = null
        message.value = null
        receivedStops.value = false
        showInfo.value = false
        button.value = { info: "Get data", isFetchingDataOnRequest: false, state: "btn-success" }
        readyForReset.value = []
      }
    },
    { immediate: true }
)

const handleReset = (selectionComponentNameAsString) => {
  readyForReset.value.push(selectionComponentNameAsString)
}

const handleDataReady = (dataReady) => {
  emit("handle-on-data-loaded", dataReady)
  if (dataReady === "HEXAGONS") {
    isVisible.value = !isVisible.value
  }
}
const setButton = (buttonObject) => {
  button.value = buttonObject
}

const setErrorMessage = (errorMessage) => {
  errorMessageHistorical.value = errorMessage
}

const closeModal = () => {
  isVisible.value = !isVisible.value
}

const dataDoneLoading = () => {
  emit("handle-on-data-loaded")
}

const setInfo = (info) => {
  receivedStops.value = true
  stopCount.value = info["item_count"]
  minTime.value = info["min_time"]
  maxTime.value = info["max_time"]
  setName.value = info["name"]
  requestedChassis.value = info["chassis"]
  returnedChassis.value = info["returned_chassis"]
  chassis.value = info["returned_chassis"]
  fleetStore.setRequestTime(info["request_time"])
  numberOfChassisRequested.value = requestedChassis.value.split(",").length
  numberOfChassisInSet.value = returnedChassis.value.split(",").length

  log("info", `{"setInfo":"Requested chassis count: ${numberOfChassisRequested.value}"}`)
  log("info", `{"setInfo":"Returned chassis count: ${numberOfChassisInSet.value}"}`)
  log("info", `{"setInfo":"Stop count: ${stopCount.value}"}`)

  fleetStore.setTilesMinTime(minTime.value)
  fleetStore.setTilesMaxTime(maxTime.value)
  fleetStore.setStopCount(stopCount.value)
  fleetStore.setTilesConstantMaxTime(maxTime.value)
  fleetStore.setTilesChassis(returnedChassis.value)
  fleetStore.setRequestedTilesName(setName.value)

  message.value = null
  button.value.info = "Data is being created"
  postMessageToTilesAPI(token, "Tiles ready? was requested")
}
const predictQueryTime = () => {
  const useStagingStack = mainStore.getAppConfigFlag("useStagingStack")
  const lambdaPath = useStagingStack ? process.env.VUE_APP_PREDICTION_ENDPOINT_B : process.env.VUE_APP_PREDICTION_ENDPOINT
  const predictionUrl = `${lambdaPath}/Prod/GetQueryTimeInference`
  const request = axios.create()

  request.get(predictionUrl, {
    headers: {
      "Authorization": mainStore.getBearerToken
    },
    params: {
      "num_stops": stopCount.value,
      "num_chassis": numberOfChassisInSet.value,
      "inference_id": getSessionId() + "_" + fleetStore.getRequestTime
    }
  }).then(response => {
    fleetStore.setTimePrediction(Math.max(0, response.data))
  })
}

const setUserSelection = (userSelected) => {
  console.log(userSelected)
}

onUnmounted(() => {
  tilesApi.terminate()
})

</script>

<style scoped>
.chassis-selection-modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.chassis-selection-modal {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  width: 40vw;
}

.modal-top,
.modal-bot {
  padding: 15px;
  display: flex;
}

.modal-top {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  color: #4AAE9B;
  justify-content: space-between;
}

.modal-bot {
  border-top: 1px solid #eeeeee;
  flex-direction: column;
  justify-content: flex-end;
}

.modal-mid {
  position: relative;
  padding: 20px 10px;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-leave-active {
  transition: opacity 2s ease;
}

.spinner-grow-sm {
  vertical-align: text-top;
}

.btn-green {
  color: white;
  background: #4AAE9B;
  border: 1px solid #4AAE9B;
  border-radius: 2px;
}

#closeModal {
  background-color: transparent;
  border: none;
  float: right;
}

#closeModalIcon {
  height: 20px;
  width: 20px;
}
.modal-content {
  padding: 30px;
}

</style>

import * as d3 from "d3"
import { schemePurples } from "d3"
import { defineStore } from "pinia"

export const useFleetStore = defineStore({
  id: "fleetStore",
  state: () => ({
    minDate: new Date("2018-01-01"),
    maxDate: new Date(),
    stopCount: 0,
    isMarketUser: false,
    hexagonResolution: "H3_CELL_INDEX_7",
    minMaxDuration: [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER],
    selectedMinMaxDuration: [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER],
    selectedWeekdays: [],
    tilesMinTime: new Date("2018-01-01"),
    tilesMaxTime: new Date(),
    tilesConstantMaxTime: new Date(),
    tilesChassis: [],
    minMaxOdometer: [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER],
    minMaxFuel: [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER],
    stopFilteredDataObj: [],
    selectedMinMaxOdometer: [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER],
    selectedMinMaxFuel: [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER],
    selectedMinTime: false,
    selectedMaxTime: false,
    selectedChassis: [],
    requestedTilesName: "",
    hexagonLayer: null,
    edgeLayer: null,
    stopLayerLoaded: false,
    hexagonLayerLoaded: false,
    edgeLayerLoaded: false,
    h3CellIndexOptions: [],
    selectedH3CellIndex: [],
    graphDataStructure: {
      odometerBoxplot: [],
      fuelBoxplot: [],
      dailyFuelBoxplot: [],
      dailyOdometerBoxplot: [],
      dailyAggregates: [],
      hourlyBoxplot: [],
      dailyHourlyAggregates: []
    },
    resolutionToRequest: 7,
    graphDataLoaded: false,
    queryTimeInSeconds: 0,
    requestTime: "noRequestTimeInMock",
    mapData: {},
    graphData: {},
    dataSetOptionsForMap: [
      { setName: "locations",
        accessorInState: "getAggregate",
        accessorKey: "HEXAGONS",
        canBe: ["Hexagon"],
        visualisedAs: "",
        colourKey: "median_stop_duration",
        colourSchema: schemePurples[6],
        colorSchemeName: "schemePurples",
        colourCount: 6,
        elevationKey: "stop_count",
        elevationScale: 2
      },
      { setName: "Trips",
        accessorInState: "getAggregate",
        accessorKey: "EDGES",
        canBe: ["Trip"],
        visualisedAs: "",
        colourKey: "median_trip_weight",
        colourSchemeName: "Magenta"
      },
      { setName: "Stops",
        canBe: ["Circle"],
        visualisedAs: "",
        colourKey: "stop_duration"
      }
    ]
  }),

  getters: {
    getAggregate: (state) => (aggregateName) => {
      return state.mapData[aggregateName]
    },
    getSelectedParams: (state) => {
      const minDate = state.selectedMinTime instanceof Date ? state.selectedMinTime.toLocaleDateString("sv-SE") : state.tilesMinTime.toLocaleDateString("sv-SE")
      const maxDate = state.selectedMaxTime instanceof Date ? state.selectedMaxTime.toLocaleDateString("sv-SE") : state.tilesMaxTime.toLocaleDateString("sv-SE")

      return {
        minodometer: state.selectedMinMaxOdometer[0] ?? this.getAggregate("MIN_MAX_RANGES")[0]["min_odometer_in_set"],
        maxodometer: state.selectedMinMaxOdometer[1] ?? this.getAggregate("MIN_MAX_RANGES")[0]["max_odometer_in_set"],
        minfuel: state.selectedMinMaxFuel[0] ?? this.getAggregate("MIN_MAX_RANGES")[0]["min_fuel_in_set"],
        maxfuel: state.selectedMinMaxFuel[1] ?? this.getAggregate("MIN_MAX_RANGES")[0]["max_fuel_in_set"],
        maxduration: state.selectedMinMaxDuration[1] ?? this.getAggregate("MIN_MAX_RANGES")[0]["min_duration_in_set"] * 60,
        minduration: state.selectedMinMaxDuration[0] ?? this.getAggregate("MIN_MAX_RANGES")[0]["max_duration_in_set"] * 60,
        mindate: minDate,
        maxdate: maxDate,
        constantmaxdate: state.tilesConstantMaxTime.toLocaleString("sv-SE"),
        hexresolution: "H3_CELL_INDEX_" + state.resolutionToRequest,
        chassis: state.selectedChassis.length > 0 ? state.selectedChassis.join(",") : state.tilesChassis.join(","),
        hexagonhexes: state.selectedH3CellIndex.length > 0 ? state.selectedH3CellIndex.join(",") : "none",
        selectedweekdays: state.selectedWeekdays.length > 0 ? state.selectedWeekdays.join(",") : "none"
      }
    },
    getSelectedWeekdays: (state) => state.selectedWeekdays,
    getHexagonResolution: (state) => state.hexagonResolution,
    getUserType: (state) => state.isMarketUser,
    getMinMaxDuration: (state) => {
      try {
        return [this.getAggregate("MIN_MAX_RANGES")[0]["min_duration_in_set"], this.getAggregate("MIN_MAX_RANGES")[0]["max_duration_in_set"]]
      } catch {
        return state.minMaxDuration
      }
    },
    getMinMaxOdometer: (state) => {
      try {
        return [this.getAggregate("MIN_MAX_RANGES")[0]["min_odometer_in_set"], this.getAggregate("MIN_MAX_RANGES")[0]["max_odometer_in_set"]]
      } catch {
        return state.minMaxOdometer
      }
    },
    getMinMaxFuel: (state) => {
      try {
        return [this.getAggregate("MIN_MAX_RANGES")[0]["min_fuel_in_set"], this.getAggregate("MIN_MAX_RANGES")[0]["max_fuel_in_set"]]
      } catch {
        return state.minMaxFuel
      }
    },
    getSelectedMinMaxDuration: (state) => state.selectedMinMaxDuration,
    getRequestedTilesName: (state) => state.requestedTilesName,
    getTilesTimeWindow: (state) => [state.tilesMinTime, state.tilesMaxTime],
    getTilesConstantMaxTime: (state) => state.tilesConstantMaxTime,
    getTilesChassis: (state) => state.tilesChassis,
    getSelectedMinMaxOdometer: (state) => state.selectedMinMaxOdometer,
    getSelectedMinMaxFuel: (state) => state.selectedMinMaxFuel,
    getSelectedMinTime: (state) => state.selectedMinTime ?? state.tilesMinTime,
    getSelectedMaxTime: (state) => state.selectedMaxTime ?? state.tilesMaxTime,
    getSelectedChassis: (state) => state.selectedChassis.length > 0 ? state.selectedChassis : state.tilesChassis,
    getGraphDataStructure: (state) => (aggregateType) => state.graphDataStructure[aggregateType],
    getGraphColorStructure: (state) => (chassisNumber) => state.chassisWithGraphColours.find(item => item.chassisnumber === chassisNumber).graphColour,
    getGraphColorScaleForDayOfWeekNumber: (state) => {
      const weekdayOrder = { "Mon": 0, "Tue": 1, "Wed": 2, "Thu": 3, "Fri": 4, "Sat": 5, "Sun": 6 }
      return d3.scaleOrdinal().domain(Object.keys(weekdayOrder)).range(d3.schemeAccent)
    },
    getPolygonColourScale: (state) => d3.scaleThreshold().domain([0, 1, 8, 24, 168, 504]).range([[224, 170, 255], [199, 125, 255], [157, 78, 221], [123, 44, 191], [90, 24, 154], [60, 9, 108]]),
    getH3CellIndexOptions: (state) => state.h3CellIndexOptions,
    getSelectedH3CellIndex: (state) => state.selectedH3CellIndex,
    getQueryTimeInSeconds: (state) => state.queryTimeInSeconds,
    getRequestTime: (state) => state.requestTime,
    getGraphDataLoaded: (state) => state.graphDataLoaded,
    getEdgeLayerLoaded: (state) => state.edgeLayerLoaded,
    getHexagonLayerLoaded: (state) => state.hexagonLayerLoaded,
    getStopLayerLoaded: (state) => state.stopLayerLoaded,
    getDataSetOptionsForMap: (state) => state.dataSetOptionsForMap,
    getResolutionToRequest: (state) => state.resolutionToRequest
},

actions: {
  setResolutionToRequest(resolutionToRequest) {
    this.resolutionToRequest = resolutionToRequest
  },
  setStopCount(count) {
    this.stopCount = count
  },
  setAggregate(aggregateName, aggValue) {
    this.mapData[aggregateName] = Object.freeze(aggValue)
  },
  setGraphDataFinishedLoading(graphDataLoaded) {
    this.graphDataLoaded = graphDataLoaded
  },
  setRequestedTilesName(requestedTilesName) {
    this.requestedTilesName = requestedTilesName
  },
  setTilesMinTime(minTime) {
    this.tilesMinTime = new Date(minTime)
  },
  setTilesMaxTime(maxTime) {
    this.tilesMaxTime = new Date(maxTime)
  },
  setTilesConstantMaxTime(maxTime) {
    this.tilesConstantMaxTime = new Date(maxTime)
  },
  setTilesChassis(chassis) {
    this.tilesChassis = chassis.split(",")
  },
  setSelectedChassis(chassisArray) {
    this.selectedChassis = chassisArray
  },
  setMinMaxOdometer(minMaxOdometerArray) {
    this.minMaxOdometer = minMaxOdometerArray
  },
  setMinMaxFuel(minMaxFuelArray) {
    this.minMaxFuel = minMaxFuelArray
  },
  setSelectedMinMaxOdometer(minMaxOdometerArray) {
    this.selectedMinMaxOdometer = minMaxOdometerArray
  },
  setSelectedMinMaxFuel(minMaxFuelArray) {
    this.selectedMinMaxFuel = minMaxFuelArray
  },
  setSelectedMinTime(minTime) {
    this.selectedMinTime = minTime
  },
  setSelectedMaxTime(maxTime) {
    this.selectedMaxTime = maxTime
  },
  setStopDataObj(stopDataObj) {
    let parseDate = d3.timeParse("%d/%m/%Y")
    stopDataObj.forEach((d) => {
      d.date = parseDate(d.date)
    })
    this.graphDataStructure.dailyAggregates = Object.freeze(stopDataObj)
  },
  setChartDataLoaded(chartDataLoaded) {
    this.chartDataLoaded = chartDataLoaded
  },
  setHexagonLayerLoaded(hexagonLayerLoaded) {
    this.hexagonLayerLoaded = hexagonLayerLoaded
  },
  setEdgeLayerLoaded(edgeLayerLoaded) {
    this.edgeLayerLoaded = edgeLayerLoaded
  },
  setStopLayerLoaded(stopLayerLoaded) {
    this.stopLayerLoaded = stopLayerLoaded
  },
  setGraphDataStructure(boxplot) {
    this.graphDataStructure.fuelBoxplot = Object.freeze(boxplot)
  },
  setOdometerGraphDataStructure(boxplot) {
    this.graphDataStructure.odometerBoxplot = Object.freeze(boxplot)
  },
  setDailyFuelGraphDataStructure(boxplot) {
    this.graphDataStructure.dailyFuelBoxplot = Object.freeze(boxplot)
  },
  setDailyOdometerGraphDataStructure(boxplot) {
    this.graphDataStructure.dailyOdometerBoxplot = Object.freeze(boxplot)
  },
  setHourlyLocationDistributionDataStructure(boxplot) {
    this.graphDataStructure.hourlyBoxplot = Object.freeze(boxplot)
  },
  setDailyHourlyLocationDistributionDataStructure(boxplot) {
    this.graphDataStructure.dailyHourlyAggregates = Object.freeze(boxplot)
  },
  setHexagonLayer(hexagonLayer) {
    this.hexagonLayer = Object.freeze(hexagonLayer)
  },
  setEdgeLayer(edgeLayer) {
    this.edgeLayer = Object.freeze(edgeLayer)
  },
  setGraphColours() {
    let odometerAggregate = this.graphDataStructure.odometerBoxplot
    let colourMap = odometerAggregate.map((chassisAgg, index) => ({
      chassisnumber: chassisAgg.chassisnumber,
      graphColour: d3.interpolateRainbow(index / odometerAggregate.length)
    }))
    this.chassisWithGraphColours = Object.freeze(colourMap)
  },
  setSelectedWeekdays(selectedWeekdays) {
    this.selectedWeekdays = selectedWeekdays
  },
  setMinDate(minDate) {
    this.minDate = new Date(minDate)
  },
  setMaxDate(maxDate) {
    this.maxDate = new Date(maxDate)
  },
  setHexagonResolution(hexagonResolution) {
    this.hexagonResolution = "H3_CELL_INDEX_" + hexagonResolution
  },
  setUserType(isMarketUserValue) {
    this.isMarketUser = isMarketUserValue
    this.hexagonResolution = "H3_CELL_INDEX_7"
  },
  setMinMaxDuration(minMaxDurationArray) {
    this.minMaxDuration = minMaxDurationArray
  },
  setSelectedMinMaxDuration(minMaxDurationArray) {
    this.selectedMinMaxDuration = minMaxDurationArray
  },
  setSelectedMinOdometer(minValue) {
    this.selectedMinMaxOdometer[0] = !isNaN(parseInt(minValue)) ? parseInt(minValue) : this.minMaxOdometer[0]
  },
  setSelectedMaxOdometer(maxValue) {
    this.selectedMinMaxOdometer[1] = !isNaN(parseInt(maxValue)) ? parseInt(maxValue) : this.minMaxOdometer[1]
  },
  setSelectedMinFuel(minValue) {
    this.selectedMinMaxFuel[0] = !isNaN(parseInt(minValue)) ? parseInt(minValue) : this.minMaxFuel[0]
  },
  setSelectedMaxFuel(maxValue) {
    this.selectedMinMaxFuel[1] = !isNaN(parseInt(maxValue)) ? parseInt(maxValue) : this.minMaxFuel[1]
  },
  setSelectedMinDuration(minValue) {
    this.selectedMinMaxDuration[0] = !isNaN(parseInt(minValue)) ? parseInt(minValue) : this.minMaxDuration[0]
  },
  setSelectedMaxDuration(maxValue) {
    this.selectedMinMaxDuration[1] = !isNaN(parseInt(maxValue)) ? parseInt(maxValue) : this.minMaxDuration[1]
  },
  setSelectedH3CellIndex(h3Cells) {
    this.selectedH3CellIndex = h3Cells
  },
  setH3CellIndexOptions(h3Cells) {
    this.h3CellIndexOptions = h3Cells
  },
  setH3CellIndexOption(h3CellIndex) {
    let index = this.h3CellIndexOptions.indexOf(h3CellIndex)
    if (index > -1) {
      this.h3CellIndexOptions.splice(index, 1)
    } else {
      this.h3CellIndexOptions.push(h3CellIndex)
    }
  },
  resetStateToDefault() {
    Object.assign(this.$state, getDefaultState())
  },
  setTimePrediction(queryTimeInSeconds) {
    this.queryTimeInSeconds = queryTimeInSeconds
  },
  setRequestTime(requestTimestamp) {
    this.requestTime = requestTimestamp
  }
}
})
import * as h3 from "h3-js"

export async function humanFriendlyH3Name(h3Index, namingMethod = "coordinates") {
  console.log(`h3Index: ${h3Index}`)
  const [lat, lon] = h3.cellToLatLng(h3Index)

  if (namingMethod === "coordinates") {
    return `Hex_${lat.toFixed(4)}_${lon.toFixed(4)}`
  } else if (namingMethod === "reverse_geocode") {
    try {
      const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}&zoom=16&addressdetails=1`)
      const data = await response.json()
      console.log(`h3_location: ${JSON.stringify(data)}`)
      let locationName
      if (data && data.address) {
        locationName = data.address.road || data.address.hamlet || data.address.city || "Unknown"
      } else {
        locationName = "Unknown"
      }
      return `${locationName} (${lat.toFixed(4)}, ${lon.toFixed(4)})`
    } catch (error) {
      console.error("Reverse geocoding failed:", error)
      return `Hex_${lat.toFixed(4)}_${lon.toFixed(4)}`
    }
  } else {
    console.error(`Invalid naming method: ${namingMethod}`)
    return `Hex_${lat.toFixed(4)}_${lon.toFixed(4)}`
  }
}

export async function mapBoxReverseGeocode(h3Index) {
  console.log(`h3Index: "${h3Index}"`)
  const [lat, lon] = h3.cellToLatLng(h3Index)
  
  try {
    const response = await fetch(`https://api.mapbox.com/search/geocode/v6/reverse?longitude=${lon}&latitude=${lat}&access_token=${process.env.VUE_APP_MAP_TOKEN}`)
    const data = await response.json()
    console.log(`h3_location: ${JSON.stringify(data)}`)

    if (data && data.features && data.features.length > 0) {
      const feature = data.features[0]
      const properties = feature.properties
      const context = properties.context || {}

      let locationName = "Unknown"

      // Check for the most granular data in order of specificity
      if (properties.name) {
        locationName = properties.name
      } else if (context.street?.name) {
        locationName = context.street.name
      } else if (context.postcode?.name) {
        locationName = context.postcode.name
      } else if (context.place?.name) {
        locationName = context.place.name
      } else if (context.region?.name) {
        locationName = context.region.name
      } else if (context.country?.name) {
        locationName = context.country.name
      }

      return `${locationName} (${lat.toFixed(4)}, ${lon.toFixed(4)})`
    } else {
      return `Unknown (${lat.toFixed(4)}, ${lon.toFixed(4)})`
    }
  } catch (error) {
    console.error("Reverse geocoding failed:", error)
    return `Hex_${lat.toFixed(4)}_${lon.toFixed(4)}`
  }
}

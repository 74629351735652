<template>
  <Transition name="slide">
    <div class="mappy-toast-wrapper">
      <!-- eslint-disable vue/v-on-event-hyphenation -->
      <tds-toast
        v-if="!isDismissed"
        class="mappy-notification"
        :variant="toastClass()"
        :subheader="notificationText()"
        @tdsClose="dismissANotification()"
      />
    </div>
  </Transition>
</template>

<script>

const propsConfig = {
  layerName: {
    type: String,
    default: "no-token"
  },
  loading: {
    type: Boolean,
    default: false
  }
}

</script>

<script setup>
import { defineProps, ref, onMounted } from "vue"
import {useMainStore} from "@/store/mainStore"
import {useI18nStore} from "@/store/localeStore"

const props = defineProps(propsConfig)
const isDismissed = ref(false)
const mainStore = useMainStore()
const i18nStore = useI18nStore()
const translate = (key) => {
  return i18nStore.getTranslation(key)
}
const toastClass = () => {
  if (props.loading) {
    return "success"
  }
  return "information"
}

const notificationText = () => {
  if (!props.loading) {
    return props.layerName + " " + translate("layerLoader->is loading") + "..."
  }
  return props.layerName + " " + translate("layerLoader->have been added!")
}
function dismissANotification () {
  isDismissed.value = true
  if(document.getElementsByClassName("mappy-notification").length === 1)
  {
    mainStore.setNotificationFlag(isDismissed.value)
  }
}

// Set a timer to automatically dismiss the notification
const autoDismissTime = 9800 // 5000 ms = 5 seconds

onMounted(() => {
  setTimeout(() => {
    dismissANotification()
  }, autoDismissTime)
})
</script>

<style>
.slide-enter {
  opacity: 0;
}

.slide-enter-active {
  transition: opacity .5s;
}

.slide-leave-active {
  opacity: 0;
  transition: opacity .2s;
}

.mappy-toast-wrapper {
  opacity: 0.7;
  margin: 3px;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {

}
/* Handle */
::-webkit-scrollbar-thumb {
  background: deeppink;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: pink;
}

</style>
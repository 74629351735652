<template>
  <HexagonLayer
    :id="props.layerId"
    :get-data-function="props.dataFunction"
    :named-module="props.namedModule"
    :key-for-scale="props.keyForScale"
    :accessor-key-for-data-function="props.accessorKeyForDataFunction"
    @hex-clicked="hexClicked"
    @mb-layer-click="openMBPopup"
    @hexes-selected="hexesSelected"
    @hex-deselect="hexDeselect"
  />
</template>

<script setup>
import HexagonLayer from "@/components/HexagonLayer.vue"
import {onMounted, onUnmounted, defineProps, inject, unref} from "vue"
import { log } from "@/plugin/logger"
import {authService} from "@/services/AuthService"
import {useMap} from "@/composables"
import {useSetStore} from "@/store/setStore"
import {workerManager} from "@/services/duckWorkerManager"

const props = defineProps({
  layerId: {
    type: String,
    required: true
  },
  dataFunction: {
    type: String,
    required: true
  },
  accessorKeyForDataFunction: {
    type: String,
    required: true
  },
  keyForScale: {
    type: String,
    required: true
  },
  namedModule: {
    type: String,
    required: true
  },
  selectedIds: {
    type: Array,
    default: () => []
  }
})

const { map } = useMap()
const useCustomStore = inject(props.namedModule)
const dataStore = useSetStore()

const emit = defineEmits(["hex-clicked", "hex-deselect", "mb-layer-click", "cycle-data-refreshed", "show-arcs", "mb-hexes-selected", "hexagons-selected"])

const apiConfigurations = [
  { workerName: "stopTimeDataAPI", setName: "standstill", queryFunction: "get_stop_start_time", layer: "stops", message: "Stop time Data is requested" },
  { workerName: "barChartDataAPI", setName: "loadChangeDistribution", queryFunction: "get_mappy_location_usage", layer: "locations", message: "Bar chart Data is requested" },
  { workerName: "weekdayChartDataAPI", setName: "weekdaychartongoingstops", queryFunction: "get_ongoing_stops", layer: "stops", message: "Weekday chart Data for ongoing stops is requested" },
  { workerName: "duration7DaysAPI", setName: "duration7DaysAggregates", queryFunction: "get_stop_duration_last_week", layer: "stops", message: "get_stop_duration_last_week is requested" },
  { workerName: "comparedDuration7DaysAPI", setName: "comparedDuration7DaysAggregates", queryFunction: "get_comparison_stop_duration_last_week", layer: "stops", message: "get_comparison_stop_duration_last_week is requested"},
  { workerName: "durationPredictionAPI", setName: "durationPrediction", queryFunction: "get_stop_duration_this_month_prediction", layer: "stops", message: "get_stop_duration_this_month_prediction is requested" },
  { workerName: "unloadedWeight7DaysAPI", setName: "weight7DaysAggregates", queryFunction: "get_weight_unloaded_last_week", layer: "stops", message: "get_stop_duration_last_week is requested"},
  { workerName: "comparedWeight7DaysAPI", setName: "comparedWeight7DaysAggregates", queryFunction: "get_comparison_weight_unloaded_last_week", layer: "stops", message: "get_comparison_stop_duration_last_week is requested"},
  { workerName: "predictedWeightUnloadAPI", setName: "weightPrediction", queryFunction: "get_weight_unloaded_this_month_prediction", layer: "stops", message: "get_stop_duration_this_month prediction is requested"}
]

const registerListeners = () => {
  apiConfigurations.forEach(config => {
    workerManager.registerListener(config.workerName, (event) => handleWorkerResponse(event))
  })
}

const deregisterListeners = () => {
  apiConfigurations.forEach(config => {
    workerManager.deregisterListener(config.workerName)
  })
}

const asBearerToken = (token) => {
  return `Bearer ${token}`
}

const resetDataBeforeRequest = () => {
  useCustomStore.resetAggregate("standstill")
  useCustomStore.resetAggregate("loadChangeDistribution")
  useCustomStore.resetAggregate("weekdaychartongoingstops")
}

const postMessageToDuckAPI = (worker, workerName, query_func, filename, logMessage, selectionDetails, hexesToProcess) => {
  const token = asBearerToken(authService.keycloak.token)
  const duckEndpoint = process.env.VUE_APP_DUCK_API_B
  const customer = authService.keycloak.tokenParsed.customer.external_customer_reference
  log("info", logMessage)
  worker.postMessage({
    method: "getResultsFromDuckDB",
    workerName: workerName,
    args: [token, duckEndpoint,
      {
        min_date: selectionDetails.minDate,
        origin: hexesToProcess
      },
      {
        query_func: query_func,
        layer: "trips"
      },
      [
        {
          filename: filename,
          file_suffix: "snappy.parquet"
        }
      ],
      {
        customer: customer
      }
    ]
  })
}


const fetchData = (selectionDetails, hexesToProcess) => {
  apiConfigurations.forEach(apiConfig => {
    const worker = workerManager.getWorker(apiConfig.workerName)
    postMessageToDuckAPI(
      worker,
      apiConfig.workerName,
      apiConfig.queryFunction,
      apiConfig.layer,
      apiConfig.message,
      selectionDetails,
      hexesToProcess
    )
  })
}
const handleHexagonSelection = (selectionDetails) => {
  resetDataBeforeRequest()
  authService.keycloak.updateToken(90)

  // Determine if it's a single hexagon or multiple hexagons
  const hexesToProcess = Array.isArray(selectionDetails.selectedHexes)
    ? selectionDetails.selectedHexes.join(",")
    : selectionDetails.selectedHexes

  fetchData(selectionDetails, hexesToProcess)

  const arcData = highlightOutgoingArcs({layerName: selectionDetails.layerName, hexIds: selectionDetails.selectedHexes})

  const toThePopup = {
    coordinates: selectionDetails.coordinates,
    properties: {hexagons: selectionDetails.selectedHexes}
  }
  emit("mb-layer-click", toThePopup)
  emit("show-arcs", arcData)
  emit("hexagons-selected", hexesToProcess)
}

const handleHexagonDeselection = (layer) => {
  const arcData = highlightOutgoingArcs({layerName: layer, hexIds: []})
  emit("show-arcs", arcData)
  emit("hexagons-selected", null)
}

const hexClicked = (clickedHexagon) => {
  if (clickedHexagon) {
    handleHexagonSelection({
      selectedHexes: clickedHexagon.hexId,
      minDate: clickedHexagon.minDate,
      layerName: clickedHexagon.layerName,
      coordinates: [] // or any specific coordinates if available
    })
  } else {
    handleHexagonDeselection(clickedHexagon.layerName)
  }
}

const hexDeselect = (clickedHexagon) => {
  handleHexagonDeselection(clickedHexagon.layerName)
}

const hexesSelected = (hexesSelected) => {
  handleHexagonSelection({
    selectedHexes: hexesSelected.selectedHexes,
    minDate: hexesSelected.minDate,
    layerName: hexesSelected.layerName,
    coordinates: hexesSelected.coordinates
  })
}

const highlightOutgoingArcs = (hexagonSelected) => {
  const setNameMap = {
    "7days": "trip7days",
    "locations": "trip",
    "trends": "tripTrends"
  }

  const hexagonLayerClicked = hexagonSelected.layerName.split("_")[0]
  const filterHexagons = hexagonSelected.hexIds
  const mappedSetName = setNameMap[hexagonLayerClicked]
  const dataObjects = dataStore.getDataSetOptionsForMap
  const matchedObject = dataObjects.find(obj => obj.setName === mappedSetName).accessorKey

  const filteredData = dataStore.getAggregate(matchedObject).filter(item =>
    filterHexagons.includes(item.hexagons_origin) ||
    filterHexagons.includes(item.hexagon_destination)
  )

  unref(map).fire("layerDataChanged", { layerName: "clickedHexes_0", edgeData: filteredData })
  return filteredData
}

const openMBPopup = (hexagon) => {
  emit("mb-layer-click", hexagon)
}

const findSetNameByWorkerName = (workerName) => {
  const config = apiConfigurations.find(config => config.workerName === workerName)
  return config ? config.setName : null
}
const handleDataReady = (event, workerName, logMessage) => {
  log("info", logMessage)
  const setNames = [].concat(findSetNameByWorkerName(workerName))
  setNames.map(dataSetName => useCustomStore.setAggregate(dataSetName, event.data.value.result))
}

const handleWorkerResponse = (event) => {
  if (event.data.key === "done") {
    handleDataReady(event, event.data.value.workerName, `${event.data.value.workerName } is fetched!`)
  }
}

onMounted(() => {
  registerListeners()
})

onUnmounted(() => {
  deregisterListeners()
})


</script>

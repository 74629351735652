<script setup>
import {computed, defineEmits, ref} from "vue"

const props = defineProps({
  iconType: {
    type: String,
    required: true
  },
  isOpen: {
    type: Boolean,
    default: false
  },
  marginRightTranslate: {
    type: String,
    default: "-280px"
  }
})

const emit = defineEmits(["opened"])

const isOpen = computed(() => {
  return props.isOpen
})

const iconName = ref(props.iconType)

const toggle = () => {
  const panelName = iconName.value
  emit("opened",panelName)
}

</script>

<template>
  <v-btn
    variant="outlined"
    class="aggregate-panel-opener"
    :class="{ isOpen: isOpen }"
    icon
    size="small"
    @click="toggle"
  >
    <slot name="prepend">
      <font-awesome-icon
        :icon="iconName"
      />
    </slot>
  </v-btn>
  <transition name="panel-slide">
    <div
      v-if="isOpen"
      class="location-aggregate-panel"
      :class="{ isOpen: isOpen }"
    >
      <slot name="mainContent" />
    </div>
  </transition>
</template>

<style scoped>
.location-aggregate-panel {
  position: absolute;
  right: 0;
  top: 45px;
}
.aggregate-panel-opener {
  color: white;
  cursor: pointer;
  z-index: 8;
  position: relative;
  transition: transform 0.6s ease;
  margin-right: 20px;
  transform: translate(v-bind(marginRightTranslate), 65px);
  background-color: var(--tds-blue-800);
}

.aggregate-panel-opener.isOpen {
  background-color: rgba(255, 255, 255, 0.5);
  border: solid 2px rgb(255, 0, 128);
}

/* Initial state for entering */
.panel-slide-enter-from, .panel-slide-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}

/* Transition state */
.panel-slide-enter-active, .panel-slide-leave-active {
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

/* Final state for entering */
.panel-slide-enter-to, .panel-slide-leave-from {
  transform: translateY(0);
  opacity: 1;
}

</style>

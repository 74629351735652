<script setup>
import {
  useMap
} from "@/composables/index.js"
import {unref, computed, defineEmits, ref, onMounted, nextTick, inject} from "vue"
import FilterRange from "@/components/FilterRange"
import HexResolutionSetup from "@/components/HexResolutionSetup.vue"
import { authService } from "@/services/AuthService"
import {useSetStore} from "@/store/setStore"

const events = [
  "filterDescription"
]
const props = defineProps({
  layerName: {
    type: String,
    required: true
  },
  layerType: {
    type: Array,
    required: true
  },
  layerSettings: {
    type: Object,
    default: () => {}
  },
  layerDataForDomain: {
    type: String,
    required: true
  },
  namedModule: {
    type: String,
    required: true
  },
  accessorKeyForDataFunction: {
    type: String,
    required: true
  }
})


const useCustomStore = inject(props.namedModule)
const dataStore = useSetStore()
const { map } = useMap()

// eslint-disable-next-line vue/valid-define-emits
const emit = defineEmits()
const domain = ref(dataStore.getAggregate(props.accessorKeyForDataFunction))

const minValue = ref()
const maxValue = ref()
const selectedValueType = ref()
const selectedAttribute = ref()
const childObject = ref()
const descriptor= ref()

const layerIsVisible = computed(() => {
  if (unref(map)) {
    if (typeof unref(map).getLayer(props.layerName) !== "undefined") {
      return unref(map).getLayoutProperty(props.layerName, "visibility") === "visible"
    }
  }
  return false
})

const toggleLayerVisibility = (layerName) => {
  if (unref(map)) {
    if (typeof unref(map).getLayer(layerName) !== "undefined") {
      const visibility = unref(map).getLayoutProperty(layerName, "visibility")
      if (visibility === "visible") {
        unref(map).fire("decklayervisible", { layerName: layerName, visibility: false })
        unref(map).setLayoutProperty(layerName, "visibility", "none")
      } else {
        unref(map).fire("decklayervisible", { layerName: layerName, visibility: true })
        unref(map).setLayoutProperty(layerName, "visibility", "visible")
      }
    }
  }
}
const selectableFeaturesInLayer = () => {
    return Object.keys(domain.value[0]).map((feat) => {
    return feat
  })
}
function handleSelectOption(event) {
  selectedAttribute.value = event.detail.value
  const dataDomainForKey = domain.value.map((d) => d)
  selectedValueType.value = determineType(dataDomainForKey.find(obj => obj[event.detail.value] !== null)[event.detail.value])
  childObject.value = [...useCustomStore.getFilterChildObject, {
    name: selectedAttribute.value,
    type: selectedValueType.value,
    min: findMinMax(dataDomainForKey, event.detail.value)[0] !== null ? findMinMax(dataDomainForKey, event.detail.value)[0].toString() :"null",
    max: findMinMax(dataDomainForKey, event.detail.value)[1] !== null ? findMinMax(dataDomainForKey, event.detail.value)[1].toString() : "null",
    layerName: props.layerName,
    uniqueValue: selectedValueType.value === "string" ? [] : []
  }]
  useCustomStore.setFilterChildArrayToObject(childObject.value,props.namedModule+"_"+props.layerName)
}

// Function to return array of the min max of selected key in data
function findMinMax( arrayOfObjects, selectedKey ) {
  minValue.value = arrayOfObjects.reduce((min, obj) => {
    return obj[selectedKey] < min ? obj[selectedKey] : min
  }, arrayOfObjects[0][selectedKey])
  if(minValue.value === "undefined" || minValue.value=== null){
    minValue.value = Number.MIN_SAFE_INTEGER
  }
  maxValue.value = arrayOfObjects.reduce((max, obj) => {
    return obj[selectedKey] > max ? obj[selectedKey] : max
  }, arrayOfObjects[0][selectedKey])
  if(maxValue.value === "undefined" || maxValue.value=== null){
    maxValue.value = Number.MAX_SAFE_INTEGER
  }
  return [minValue.value, maxValue.value]
}

// Function to determine the type of each string
function determineType(str) {
  const numberRegex = /^-?\d*\.?\d+$/
  const dateRegex = /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2}\.\d{3}) ([\+\-]\d{4})$/
  if (numberRegex.test(str)) {
    return "number"
  } else if (dateRegex.test(str)) {
    return "date"
  } else {
    return "string"
  }
}
function handleFilterDesc (event) {
  descriptor.value=event.descFilter
}
onMounted(() => {
  childObject.value = useCustomStore.getFilterChildObject
  descriptor.value = useCustomStore.getFilterDesc
  map.value.on("filterDescription", handleFilterDesc)
})

</script>

<template>
  <tds-block class="mappy-layer-settings">
    <v-btn
      v-if="layerIsVisible"
      class="toggleLayerVis"
      @click="toggleLayerVisibility(layerName)"
    >
      <slot name="prepend">
        <tds-icon
          size="15px"
          name="eye"
        />
      </slot>
      <slot name="default">
        {{ layerName }}
      </slot>
    </v-btn>
    <v-btn
      v-else
      class="toggleLayerVis"
      @click="toggleLayerVisibility(layerName)"
    >
      <slot name="prepend">
        <tds-icon
          size="15px"
          name="eye_inactive"
        />
      </slot>
      <slot name="default">
        {{ layerName }}
      </slot>
    </v-btn>

    <!-- eslint-disable vue/v-on-event-hyphenation -->
    <tds-block v-if="props.layerType[0]==='Hexagon' && layerIsVisible && descriptor">
      <h6 style="font-size: 12px; color: black">
        {{ descriptor }}
      </h6>
      <hex-resolution-setup
        v-if="authService.getADUser()"
        :module-name="props.namedModule"
        :layer-name="props.layerName"
      />
    </tds-block>

    <!--    <tds-dropdown-->
    <!--      v-if="layerIsVisible"-->
    <!--      style="margin-top: 1px"-->
    <!--      placeholder="Filter on"-->
    <!--      size="sm"-->
    <!--      open-direction="down"-->
    <!--      @tdsChange="handleSelectOption"-->
    <!--    >-->
    <!--      <tds-dropdown-option-->
    <!--        v-for="(value, jindex) in selectableFeaturesInLayer()"-->
    <!--        :key="jindex + '_' + value"-->
    <!--        :value="value"-->
    <!--      >-->
    <!--        {{ value }}-->
    <!--      </tds-dropdown-option>-->
    <!--    </tds-dropdown>-->
    <div
      v-if="layerIsVisible"
      :id="props.namedModule+'_'+props.layerName"
    >
      <!--      <filter-range-->
      <!--        v-for="(child, index) in childObject"-->
      <!--        :key="props.namedModule+'_'+child.layerName"-->
      <!--        :min-value="child.min"-->
      <!--        :max-value="child.max"-->
      <!--        :input-type="child.type"-->
      <!--        :prop-name="child.name"-->
      <!--        :layer-name="child.layerName"-->
      <!--        :index-value="index"-->
      <!--        :string-unique-value="child.uniqueValue"-->
      <!--        :named-module="props.namedModule"-->
      <!--      />-->
    </div>
  </tds-block>
</template>

<style scoped>
.mappy-layer-settings {
  color: black;
  height: 250px;
}
.fa-icon {
  font-size: 20px;
  color: black;
}

</style>

// src/stores/useI18nStore.js
import { defineStore } from "pinia"

export const useI18nStore = defineStore("i18n", {
  state: () => ({
    locale: "en",
    translations: {}
  }),
  actions: {
    setLocale(newLocale) {
      this.locale = newLocale
      this.loadTranslations(newLocale)
    },
    async loadTranslations(locale) {
      const translations = await import(`../locales/${locale}.json`)
      this.translations = translations.default
    },
    getTranslation(key) {
      // Allow for nested structure so that we can run:
      // translations like translate("reel->Here's the sites with")
      const levels = key.split("->")
      let output = this.translations
      for (const level of levels) {
        if (output[level] === undefined) return key.split("->")[1]
        output = output[level]
      }
      return typeof output === "string" ? output : key // Ensure a string is returned, or the key if it's not found
    }

  }
})

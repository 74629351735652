<template>
  <v-btn
    v-if="isVisible"
    @click="toggleVisibility()"
  >
    <slot name="default">
      {{ toggleFor }}
    </slot>
    <slot name="append">
      <tds-icon
        size="13px"
        name="eye"
      />
    </slot>
  </v-btn>
  <v-btn
    v-else
    @click="toggleVisibility()"
  >
    <slot name="default">
      {{ toggleFor }}
    </slot>
    <slot name="append">
      <tds-icon
        size="13px"
        name="eye_inactive"
      />
    </slot>
  </v-btn>
</template>
<script setup>
import {ref,defineProps, defineEmits} from "vue"

const emit = defineEmits(["toggle-visibility"])
const props = defineProps({
  isVisible: {
    type: Boolean,
    required: true
  },
  toggleFor: {
    type: String,
    required: true
  }
})

const isVisible = ref(props.isVisible)
const toggleFor = ref(props.toggleFor)
const toggleVisibility = () => {
  isVisible.value = !isVisible.value
  emit("toggle-visibility", isVisible.value)
}
</script>


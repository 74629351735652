<script>
const propsConfig = {
  content: {
    type: Object,
    required: true
  }
}
</script>

<script setup>
import { defineProps, ref } from "vue"
const props = defineProps(propsConfig)
const content = ref(props.content)

</script>

<template>
  <div class="popup-table">
    <table style="position: relative">
      <tbody>
        <tr
          v-for="(value, key) in content"
          :key="key"
        >
          <td class="title">
            {{ key }}
          </td>
          <td v-if="typeof value === 'object'">
            <table>
              <tbody>
                <tr
                  v-for="(value1, key1) in value"
                  :key="key1"
                >
                  <td class="title">
                    {{ key1 }}
                  </td>
                  <td>{{ value1 }}</td>
                </tr>
              </tbody>
            </table>
          </td>
          <td v-else>
            {{ value }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>
.popup-table {
  max-height: 300px;
  height: fit-content;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 5px;
}

.title {
  font-weight: bold;
  width: 100px;
}

</style>
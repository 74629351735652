<script setup>
import {onMounted} from "vue"
import AdminPage from "./components/AdminPage"
import SideNav from "./components/SideNav"
import { log } from "./plugin/logger.js"
import flags from "@/services/featureFlags"
import { authService } from "@/services/AuthService"

const keycloak = authService.keycloak
const updateToken = () => {
  keycloak.updateToken(90).then(() => {
    flags.fetch()
    return true
  }).catch(() => {
    return false
  })
}

onMounted(() => {
  log("info", "Initiated the 'App' component.")
})

// Token lives for 5 minutes, refresh token lives for 1 hour, session lasts for 10 hours.
// Update token every 4 minutes to avoid any potential issues
setInterval(updateToken, 240000)
</script>

<template>
  <div
    class="mappy-main_container"
  >
    <side-nav />
    <admin-page />
    <router-view />
  </div>
</template>

<style>
#app {
  width: 100vw;
  height: 100vh;
}

.mappy-main_container {
  display: flex;
  width: 100%;
  height: 100%;
}

p, ul {
  font-size: 1.2em;
  line-height: 1.8em;
}

a {
  color: var(--tds-blue);
  font-weight: 500;
  letter-spacing: 0.02em;
  text-decoration: none;
  border-bottom: 2px solid;
}

em {
  font-style: inherit;
  color: var(--tds-red-500);
}

/* tds  */

:root, html {
  --tds-modal-bg: var(--tds-grey-50) !important;

  --tds-btn-primary-background: var(--tds-blue);
  --tds-btn-primary-color-hover: var(--tds-white) !important;
  --tds-btn-primary-border-color-hover: var(--tds-blue-600) !important;
  --tds-btn-primary-background-disabled: var(--tds-grey-100) !important;
  --tds-btn-primary-border-color-disabled: var(--tds-blue) !important;
  --tds-btn-primary-color-disabled: var(--tds-blue) !important;
}

.tds-btn {
  font-weight: 600;
}

tds-modal > div[slot="tds-modal-body"] {
  max-height: 70vh;
  overflow-x: auto;
}


tds-modal > h3[slot="tds-modal-headline"] {
  margin: 0;
}

tds-modal > button[slot="tds-modal-actions"] {
  margin-top: var(--tds-spacing-layout-16);
}

.tds-modal-overflow {
  padding-right: 16px;
}

.margin-top-16 { margin-top: var(--tds-spacing-layout-16); }
.margin-top-24 { margin-top: var(--tds-spacing-layout-24); }
.margin-top-32 { margin-top: var(--tds-spacing-layout-32); }
.margin-top-48 { margin-top: var(--tds-spacing-layout-48); }
.margin-top-64 { margin-top: var(--tds-spacing-layout-64); }
.margin-top-96 { margin-top: var(--tds-spacing-layout-96); }

.margin-bottom-16 { margin-bottom: var(--tds-spacing-layout-16); }
.margin-bottom-24 { margin-bottom: var(--tds-spacing-layout-24); }
.margin-bottom-32 { margin-bottom: var(--tds-spacing-layout-32); }
.margin-bottom-48 { margin-bottom: var(--tds-spacing-layout-48); }
.margin-bottom-64 { margin-bottom: var(--tds-spacing-layout-64); }
.margin-bottom-96 { margin-bottom: var(--tds-spacing-layout-96); }

.padding-top-16 { padding-top: var(--tds-spacing-layout-16); }
.padding-top-24 { padding-top: var(--tds-spacing-layout-24); }
.padding-top-32 { padding-top: var(--tds-spacing-layout-32); }
.padding-top-48 { padding-top: var(--tds-spacing-layout-48); }
.padding-top-64 { padding-top: var(--tds-spacing-layout-64); }
.padding-top-96 { padding-top: var(--tds-spacing-layout-96); }

.padding-left-16 { padding-left: var(--tds-spacing-layout-16); }
.padding-left-24 { padding-left: var(--tds-spacing-layout-24); }
.padding-left-32 { padding-left: var(--tds-spacing-layout-32); }
.padding-left-48 { padding-left: var(--tds-spacing-layout-48); }
.padding-left-64 { padding-left: var(--tds-spacing-layout-64); }
.padding-left-96 { padding-left: var(--tds-spacing-layout-96); }

.el-margin-left-8 { margin-left: var(--tds-spacing-element-8); }
.el-margin-left-16 { margin-left: var(--tds-spacing-element-16); }

.el-margin-right-8 { margin-right: var(--tds-spacing-element-8); }
.el-margin-right-16 { margin-right: var(--tds-spacing-element-16); }

.el-margin-top-8 { margin-top: var(--tds-spacing-element-8); }
.el-margin-top-16 { margin-top: var(--tds-spacing-element-16); }

.el-margin-bottom-8 { margin-bottom: var(--tds-spacing-element-8); }
.el-margin-bottom-16 { margin-bottom: var(--tds-spacing-element-16); }

</style>

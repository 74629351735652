<template>
  <div :id="id" />
</template>

<script setup>
import { useEventsBinding, useMap } from "@/composables/index.js"
import "mapbox-gl/dist/mapbox-gl.css"
import {computed, inject, onMounted, onUnmounted, ref, unref} from "vue"
import { log } from "@/plugin/logger.js"
import {useMainStore} from "@/store/mainStore"
import {useFleetStore} from "@/store/fleet/fleetStore"

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  source: {
    type: String,
    required: true,
    default: "stop"
  },
  beforeId: {
    type: String,
    default: undefined
  },
  options: {
    type: Object,
    default: () => {}
  },
  namedModule: {
    type: String,
    required: true
  }
})

const events = [
  "styleChanged"
]

const mainStore = useMainStore()
const fleetStore = useFleetStore()
const useCustomStore = inject(props.namedModule)

const { map } = useMap()
const isLoaded = ref(false)
// eslint-disable-next-line vue/valid-define-emits
const emit = defineEmits()

const paintObj = {
  "circle-opacity": 0.7,
  "circle-color": [
    "interpolate",
    ["linear"],
    ["get", "tippecanoe_feature_density"],
    0, ["to-color", "#D6001C"],
    260, ["to-color", "#EFBCD5"]
  ],
  "circle-radius": [
    "interpolate", ["linear"], ["zoom"],
    0, ["step",
      ["/", ["get", "stop_duration"], 3600],
      0.5, 1,
      1, 8,
      3, 24,
      4, 168,
      5, 504,
      6],
    18, ["step",
      ["/", ["get", "stop_duration"], 3600],
      8, 1,
      12, 8,
      17, 24,
      19, 168,
      23, 504,
      28]
  ]
}

const circleLayer = {
  "id": props.id,
  "type": "circle",
  "source": props.source,
  "source-layer": props.source,
  // "layout": {
  //   "visibility": visibleProp
  // },
  "paint": paintObj
}

const options = computed(() => {
  const opts = { ...props.options, id: props.id }
  if (opts.paint === null || opts.paint === undefined) {
    delete opts.paint
  }
  if (opts.layout === null || opts.layout === undefined) {
    delete opts.layout
  }
  return opts
})

const addHeaderToRequest = (url, resourceType) => {
  let endpointOne = mainStore.getAppConfigFlag("useStagingStack") ? process.env.VUE_APP_CF_DIST_ONE_ENDPOINT_B : process.env.VUE_APP_CF_DIST_ONE_ENDPOINT
  let endpointTwo = mainStore.getAppConfigFlag("useStagingStack") ? process.env.VUE_APP_CF_DIST_TWO_ENDPOINT_B : process.env.VUE_APP_CF_DIST_TWO_ENDPOINT
  if (resourceType === "Tile" && (url.indexOf(endpointOne) === 0 || url.indexOf(endpointTwo) === 0)) {
    return {
      url: url,
      headers: { "authorizationToken": mainStore.getBearerToken }
    }
  }
}

useEventsBinding(emit, map, events, props.id)

function removeLayer() {
  if (typeof unref(map).getLayer(props.id) !== "undefined") {
    unref(map).removeLayer(props.id)
  }
}
const updateMapWithSources = () => {
  const endpointOne = mainStore.getAppConfigFlag("useStagingStack")
      ? process.env.VUE_APP_CF_DIST_ONE_ENDPOINT_B : process.env.VUE_APP_CF_DIST_ONE_ENDPOINT
  const endpointTwo = mainStore.getAppConfigFlag("useStagingStack")
      ? process.env.VUE_APP_CF_DIST_TWO_ENDPOINT_B : process.env.VUE_APP_CF_DIST_TWO_ENDPOINT

  const sourceTiles = [
        `${endpointOne}/${fleetStore.getRequestedTilesName}/${props.source}/{z}/{x}/{y}.pbf`,
        `${endpointTwo}/${fleetStore.getRequestedTilesName}/${props.source}/{z}/{x}/{y}.pbf`
  ]

  unref(map).addSource(props.source, {
    type: "vector",
    tiles: sourceTiles
  })
}

const setupPopupData = (e) => {
  let coordinates = e.features[0].geometry.coordinates.slice()
  let clickedFeature = { ...e.features[0].properties }

  // Enforcing Swedish setting of timestamps
  clickedFeature.stop_start_time = new Date(e.features[0].properties.stop_start_time * 1000).toLocaleString("sv-SE")
  clickedFeature.stop_end_time = new Date(e.features[0].properties.stop_end_time * 1000).toLocaleString("sv-SE")
  clickedFeature.stop_duration = (e.features[0].properties.stop_duration / 3600).toFixed(2) + "h"

  // Ensure that if the map is zoomed out such that multiple
  // copies of the feature are visible, the popup appears
  // over the copy being pointed to.
  while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
  }

  emit("mb-layer-click", { coordinates: coordinates, properties: clickedFeature })
}

onMounted(() => {
  log("info", "Initiated an 'CircleLayer' with " + props.id + " name.")
  if (!unref(map).getSource(props.source)) {
    updateMapWithSources()
    unref(map)._requestManager._transformRequestFn = addHeaderToRequest
  }

  removeLayer()
  unref(map).addLayer(circleLayer)
  useCustomStore.setMapLayerProp("Circle", parseInt(props.id.split("_").pop()), "mbId", props.id)

  log("info", props.id + " is added as a circle layer to the map.")
  unref(map).setLayoutProperty(props.id, "visibility", "visible")
  unref(map).triggerRepaint()
  unref(map).on("click", props.id, setupPopupData)

  isLoaded.value = true
  emit("circle-layer-loaded")
})

onUnmounted(() => {
  unref(map)._requestManager._transformRequestFn = null
  unref(map).off("click", props.id, setupPopupData)
  removeLayer()
})

</script>

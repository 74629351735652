<template>
  <div
    ref="draggableContainer"
    class="draggable-container"
    @mousedown="dragMouseDown"
  >
    <slot
      name="main"
    />
  </div>
</template>

<script setup>
import {ref, getCurrentInstance} from "vue"

const instance = getCurrentInstance()
const draggableContainer = ref()

const props = defineProps({
  styleTop: {
    type: String,
    default: "0px"
  },
  styleRight: {
    type: String,
    default: "60px"
  }
})

const positions = ref({
  clientX: undefined,
  clientY: undefined,
  movementX: 0,
  movementY:0
})

const dragMouseDown = (event) => {
  if (event.ctrlKey) {
    event.preventDefault()
    // get the mouse cursor position at startup:
    positions.value.clientX = event.clientX
    positions.value.clientY = event.clientY
    document.onmousemove = elementDrag
    document.onmouseup = closeDragElement
    document.body.style.cursor =  "move"
  }
}

const elementDrag = (event) => {
  event.preventDefault()
  positions.value.movementX = positions.value.clientX - event.clientX
  positions.value.movementY = positions.value.clientY - event.clientY
  positions.value.clientX = event.clientX
  positions.value.clientY = event.clientY
  // set the element's new position:
  instance.refs.draggableContainer.style.top = (instance.refs.draggableContainer.offsetTop - positions.value.movementY) + "px"
  instance.refs.draggableContainer.style.left = (instance.refs.draggableContainer.offsetLeft - positions.value.movementX) + "px"
}

const closeDragElement = () => {
  document.body.style.cursor = "auto"
  document.onmouseup = null
  document.onmousemove = null
}

</script>

<style scoped>
.draggable-container {
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 4;
  min-width: 250px;
  display: flex;
  font-family: "Scania Sans Semi Condensed", "Scania Sans Condensed", Arial, Helvetica, sans-serif;
  color: white;
  top: v-bind(styleTop);
  right: v-bind(styleRight);
}

</style>

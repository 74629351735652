import { createApp } from "vue"
import App from "./App.vue"
import { createPinia } from "pinia"
import { library } from "@fortawesome/fontawesome-svg-core"
import { createRouter } from "./router"
/* import specific icons */
import { faLayerGroup, faEye, faEyeSlash,
  faFileImport, faPalette, faAdd, faBell,
  faFilter, faLineChart,faExpand, faCompress,
  faMagnifyingGlassChart,
  faThumbtack, faMinus,
  faMapLocation, faTable, faComment,
  faClock, faScaleUnbalancedFlip , faDrawPolygon} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { log } from "./plugin/logger.js"
import flags from "./services/featureFlags"
import i18n from "./services/i18n"
import "@scania/tegel/dist/tegel/tegel.css"
import { authService } from "@/services/AuthService"
import "vuetify/styles"
import { createVuetify } from "vuetify"
import * as components from "vuetify/components"
import * as directives from "vuetify/directives"
import "vuetify/styles"

import { defineCustomElements } from "@scania/tegel/loader/index.es2017"
import { useMainStore } from "@/store/mainStore"
import {useI18nStore} from "@/store/localeStore"

library.add(faLayerGroup)
library.add(faPalette)
library.add(faEye)
library.add(faEyeSlash)
library.add(faFileImport)
library.add(faAdd)
library.add(faBell)
library.add(faExpand)
library.add(faCompress)
library.add(faFilter)
library.add(faLineChart)
library.add(faClock)
library.add(faScaleUnbalancedFlip)
library.add(faThumbtack)
library.add(faMapLocation)
library.add(faTable)
library.add(faMinus)
library.add(faMagnifyingGlassChart)
library.add(faComment)
library.add(faDrawPolygon)

defineCustomElements()

const app = createApp(App)
const router = createRouter(app)
app.component("FontAwesomeIcon", FontAwesomeIcon)

const pinia = createPinia()
app.use(pinia)

const mainStore = useMainStore()
const i18nStore = useI18nStore(pinia)
const getUserLocale = () => {
  const language = navigator.language || navigator.userLanguage
  // If `navigator.languages` is available, use the first preferred language
  const languages = navigator.languages && navigator.languages.length ? navigator.languages[0] : language
  // Only use first part of locale (sv-SE)
  log("info", "User's languages: " + languages)
  return languages.split("-")[0]
}

i18nStore.setLocale(getUserLocale())

app.use(i18n, { store: i18nStore })

const vuetify = createVuetify({
  components,
  directives
})

app.use(vuetify)
log("info", process.env.VUE_APP_LOG_API)

flags.fetch()

authService.init().then((isAuthenticated) => {
  if (isAuthenticated) {
    const userEmail = authService.keycloak.idTokenParsed ? authService.keycloak.idTokenParsed.email : JSON.stringify((authService.keycloak))
    log("info", "Succeeded to authenticate: " + userEmail)
    mainStore.setBearerToken(authService.keycloak.token)
  }
  else {
    mainStore.setBearerToken(null)
  }
}).catch((error) => {
  console.error("Error during authentication initialization:", error)
}).finally(()=>{
  app.use(router)
  app.mount("#app")
})
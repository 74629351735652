import { log } from "@/plugin/logger"
import {useMainStore} from "@/store/mainStore"

export default {
    // eslint-disable-next-line no-unused-vars
    fetch: () => {
      const mainStore = useMainStore()
      let url = process.env.VUE_APP_APP_CONFIG_ENDPOINT + "/Prod"
      let appConfigFeatureFlags
      fetch(url)
        .then(response => {
          if (response.status !== 200) {
            log("error", "There was an error. Status code: " + response.status)
            return false
          }
          response.json().then(data => {
            if (data.config.featureFlags.length) {
              appConfigFeatureFlags = data.config.featureFlags
              log("info", `setAppConfigFeatureFlags: ${JSON.stringify(appConfigFeatureFlags)}`)
              mainStore.setAppConfigFeatureFlags(appConfigFeatureFlags)
              return true
            }
            throw new Error("No feature flags found")
          })
      })
      .catch((e) => {
        log("error", "There was an error: " + e)
        return false
      })
      return true
    }
}
<script setup>
const props = defineProps({
  iconName: {
    type: String,
    default: ""
  },
  clickedStyling: {
    type: Boolean,
    default: false
  }
})

</script>

<template>
  <button
    class="mappy-btn"
    :class="props.clickedStyling ? 'mappy-fab-item-open': ''"
  >
    <font-awesome-icon
      :icon="iconName"
      class="menu-btn-icon"
    />
  </button>
</template>

<style>
.mappy-btn {
  padding: var(--tds-spacing-element-12);
  height: 10rem;
  width: 2rem;
  max-width: 50px; /* Adjust the width as needed */
  max-height: 30px; /* Adjust the height as needed */
  background-color: #e39cab; /* FAB background color */
  border-radius: 10px; /* Rounded corners */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: "Scania Sans Semi Condensed", "Scania Sans Condensed", arial, helvetica, sans-serif;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.01em;
  font-weight: 600;
  box-shadow: none;
  position: relative;
  text-decoration: none;
  border: 1px solid;
}

.mappy-btn:hover {
  background-color: #ea008b; /* Change the background color on hover */
}

.menu-btn-icon {
  font-size: 12px; /* Adjust the icon size as needed */
  color: #fff; /* Icon color */
}

.mappy-fab-item-open {
  background-color: #ea008b;
}
</style>
<script setup>
import { computed, ref, onMounted, onUnmounted } from "vue"
import { Vue3JsonEditor } from "vue3-json-editor"
import { log } from "@/plugin/logger.js"
import flags from "@/services/featureFlags"
import { useMainStore } from "@/store/mainStore"
import { authService } from "@/services/AuthService"

const store = useMainStore()
const isOpen = ref(false)
const inputQuery = ref("{\"method\":\"getAdminInsightsFromNeptune OR getDataFromNeptune OR clearDBInstance\",\"gremlin_function\":\"get_number_of_stops_per_vehicle_at_hexagon\", \"hexId\":\"8a08864edd1ffff\", \"profile\": \"True\"}")
const queryResponse = ref("")
const toggleKey = ref(0)
const loading = ref(false)
const startTime = ref(0)
let neptuneAPI

const jsonData = computed(() => {
  return store.getAppConfigFeatureFlags
})

const asBearerToken = (token) => {
  return `Bearer ${token}`
}

const toggle = () => {
  isOpen.value = !isOpen.value
}

const processInput = async () => {
  loading.value = true
  queryResponse.value = ""

  const token = asBearerToken(authService.keycloak.token)
  const endpoint = process.env.VUE_APP_NEPTUNE_API_B

  startTime.value = performance.now()

  neptuneAPI.postMessage({
    method: JSON.parse(inputQuery.value).method,
    args: [token, endpoint, JSON.parse(inputQuery.value)]
  })

}

const postSet = async (value) => {
  let endpoint = process.env.VUE_APP_APP_CONFIG_ENDPOINT + "/Prod/CreateConfig"
  let data = "{\"featureFlags\":" + JSON.stringify(value) + "}"
  log("info", `{"postSet":"start postset: ${endpoint}"}`)
  await fetch(endpoint, {
    method: "POST",
    mode: "no-cors",
    headers: {
      "Content-Type": "application/json",
      "Authorization": asBearerToken(authService.keycloak.token)
    },
    body: data
  })
  toggleKey.value += 1
  // allow for update in config layer before querying for the update
  setTimeout(() => {
    flags.fetch()
  },30000)
  return true
}

const isAdmin = computed(() => {
  return store.getAppConfigFlag("isAdmin", authService.getUserRoles(), authService.getUserEmail())
})

onMounted(() => {
  neptuneAPI = new Worker(
      new URL("../web-worker/neptuneWorker.js", import.meta.url),
      { type: "module" }
  )
  neptuneAPI.onmessage = (event) => {
    if (event.data.key === "done") {
      queryResponse.value = JSON.stringify(event.data.value, null, 4)
      loading.value = false
      const endTime = performance.now()
      queryResponse.value += " \ntotal time: " + Math.round(endTime - startTime.value) + "ms"
    } if (event.data.key === "adminInsightReady") {
      queryResponse.value = event.data.value
      loading.value = false
      const endTime = performance.now()
      queryResponse.value += " \ntotal time: " + Math.round(endTime - startTime.value) + "ms"
    } if (event.data.key === "error") {
      log("error", event.data.value)
      loading.value = false
    }
  }
})

onUnmounted(() => {
  neptuneAPI.terminate()
})

</script>

<template>
  <div
    v-if="isAdmin"
    id="adminpagepanel"
    class="adminpage-panel"
    :class="{ isOpen: isOpen }"
  >
    <v-btn
      id="adminpagepanelopener"
      variant="outlined"
      class="adminpage-panel-opener"
      :class="{ isOpen: isOpen }"
      icon
      size="x-small"
      @click="toggle"
    >
      <slot name="prepend">
        <tds-icon
          name="settings"
          size="12px"
        />
      </slot>
    </v-btn>
    <tds-accordion>
      <div class="tds-card">
        <tds-accordion-item
          header="Feature flags"
          affix="prefix"
          tabindex="1"
        >
          <Vue3JsonEditor
            id="jsonEditor"
            :key="toggleKey"
            v-model="jsonData"
            class="jsonEditor"
            :show-btns="true"
            :expanded-on-start="true"
            @json-save="postSet"
          />
        </tds-accordion-item>
        <tds-accordion-item
          header="Query lambda"
          affix="prefix"
          tabindex="2"
        >
          <div class="tds-card-body">
            The query
            <textarea
              v-model="inputQuery"
              nominwidth
              class="input-textarea"
            />
            The response
            <textarea
              v-model="queryResponse"
              class="response-textarea"
            />
            <v-btn
              variant="outlined"
              :disabled="loading"
              @click="processInput"
            >
              Submit
              <slot name="append">
                <tds-spinner
                  v-if="loading"
                  size="sm"
                />
              </slot>
            </v-btn>
          </div>
        </tds-accordion-item>
      </div>
    </tds-accordion>
  </div>
</template>

<style scoped>
.adminpage-panel {
  position: fixed;
  width: 600px;
  height: 100vh;
  padding: 30px;
  background-color: var(--tds-blue-800);
  transform: translateX(-100%);
  transition: transform 0.6s ease;
  z-index: 5;
  border-left: 6px solid transparent;
  left: 65px;
}
#adminpagepanel.isOpen {
  transform: translateX(0);
}

.adminpage-panel-opener {
  position: fixed;
  top: 50px;
  left: 600px;
  font-weight: 600;
  letter-spacing: 0.08em;
  color: white;
  cursor: pointer;
  z-index: 5;
  transform: rotate(0deg);
  transition: transform 0.6s ease;
  border: 1px solid white;
}

#adminpagepanelopener.isOpen {
  transform: rotate(-360deg);
}

.tds-card {
  background-color: var(--tds-white);
}

.jsonEditor {
  background-color: rgb(239, 239, 239);
  height: 100%;
}
.input-textarea,
.submit-button,
.response-textarea {
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
  font-size: 14px;
}
</style>

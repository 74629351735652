<script setup>
import { ref } from "vue"

const openedMenu = ref(true)

</script>

<template>
  <div
    class="side-menu-items"
    :class="openedMenu ? 'side-menu-items-open' : '' "
  >
    <div
      :class="openedMenu ? 'mappy-fab-open' : '' "
      class="mappy-fab"
      @click="openedMenu =! openedMenu"
    >
      +
    </div>
    <div class="map-fabs">
      <slot
        v-if="openedMenu"
        name="menu-item-list"
      />
    </div>
  </div>
</template>

<style scoped>

.map-fabs {
  position: absolute;
  top: 60px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-self: center;
}

.mappy-fab {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 20px;
  border-radius: 50%;
  background: #ea008b;
  color: white;
  line-height: 30px;
  text-align: center;
  font-size: 23px;
  z-index: 4;
  overflow: hidden;
  box-shadow: 0px 0px 0px 0px rgba(44, 179, 240, 0.6);
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  align-self: center;
}
.mappy-fab-open {
  transform: scale(1.1) rotate(45deg) translateZ(0) translateY(0);
}

.side-menu-items {
  width: 40px;
  z-index: 4;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 85px;
  overflow: hidden;
  position: absolute;
  right: 0;
  margin: 0 10px 0 0;
}

.side-menu-items:hover {
  height: 85%;
}

.side-menu-items-open {
  height: 55%;
}

.mappy-fab:hover {
  box-shadow: 0px 0px 10px 0px rgba(44, 179, 240, 0.6);
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

</style>

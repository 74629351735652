<template>
  <div class="mappy-filter-data-block">
    <tds-block class="mappy-fleet-filter-options">
      <Multiselect
        v-model="chosenChassis"
        :placeholder="translate('data->Filter vehicles')"
        mode="tags"
        :options="chassisOptions"
        :close-on-select="false"
        :searchable="true"
        :clear-on-select="false"
        :limit="5"
        @change="updateSelectedChassis"
      />
      <date-filter @date-update="updateDateRange" />
    </tds-block>
    <v-btn
      class="mappy-filter-data"
      :disabled="disableFilterButton"
      @click="fetchFilteredData"
    >
      <font-awesome-icon icon="filter" />
    </v-btn>
  </div>
</template>

<script setup>
import DateFilter from "@/components/DateFilter.vue"
import Multiselect from "@vueform/multiselect"
import {defineEmits, computed, ref, nextTick, unref, onMounted} from "vue"
import "@vueform/multiselect/themes/default.css"
import {log} from "@/plugin/logger"
import {useMap} from "@/composables"
import {useMainStore} from "@/store/mainStore"
import {useFleetStore} from "@/store/fleet/fleetStore"
import {useSetStore} from "@/store/setStore"
import {useDataFetcher} from "@/composables/useCustomerDataFetcher"
import {getActivePinia} from "pinia"
import {workerManager} from "@/services/duckWorkerManager"
import {useI18nStore} from "@/store/localeStore"


const mainStore = useMainStore()
const fleetStore = useFleetStore()
const dataStore = useSetStore()

const i18nStore = useI18nStore()
const translate = (key) => {
  return i18nStore.getTranslation(key)
}
const emit = defineEmits(["update-size", "handle-data-ready"])
const { map } = useMap()
const chosenChassis = ref([])
const dateRange = ref({})

const getFilter = () => {
  return {
    ...dateRange.value,
    ...(chosenChassis.value.length > 0 ? {reg_no: chosenChassis.value.join(",")} : {})
  }
}

const apiConfigurations = [
  { workerName: "hexesAPI", filename: "stops", setName: ["locationAggregates", "locationAggregatesUnfiltered"], queryFunction: "get_location_aggregates", message: "Hexes is requested", requiresFilter: true},
  { workerName: "edgesAPI", filename: "stops", setName: ["edgeAggregates", "edgeAggregatesUnfiltered"], queryFunction: "edges", message: "Edges is requested", requiresFilter: true},
  { workerName: "stopDuration7DaysAPI",filename: "stops", setName: "duration7DaysAggregates", queryFunction: "get_stop_duration_last_week", message: "get_stop_duration_last_week is requested", requiresFilter: true},
  { workerName: "comparedStopDuration7DaysAPI", filename: "stops", setName: "comparedDuration7DaysAggregates", queryFunction: "get_comparison_stop_duration_last_week", message: "get_comparison_stop_duration_last_week is requested", requiresFilter: true},
  { workerName: "stopDurationPredictionAPI", filename: "stops", setName: "durationPrediction", queryFunction: "get_stop_duration_this_month_prediction", message: "get_stop_duration_this_month_prediction is requested", requiresFilter: true},
  { workerName: "weightUnloaded7DaysAPI",filename: "stops", setName: "weight7DaysAggregates", queryFunction: "get_weight_unloaded_last_week", message: "get_stop_duration_last_week is requested", requiresFilter: true},
  { workerName: "comparedWeightUnloaded7DaysAPI", filename: "stops", setName: "comparedWeight7DaysAggregates", queryFunction: "get_comparison_weight_unloaded_last_week", message: "get_comparison_stop_duration_last_week is requested", requiresFilter: true},
  { workerName: "weightUnloadedPredictionAPI", filename: "stops", setName: "weightPrediction", queryFunction: "get_weight_unloaded_this_month_prediction", message: "get_stop_duration_this_month prediction is requested", requiresFilter: true},
  { workerName: "weightUnloadedPredictionAPI", filename: "stops", setName: "weightPrediction", queryFunction: "get_weight_unloaded_this_month_prediction", message: "get_stop_duration_this_month prediction is requested", requiresFilter: true},
  { workerName: "dataForStoryComponent", filename: "stops", setName: "storyData", queryFunction: "get_status", message: "get_status is requested", requiresFilter: true},
  { workerName: "topLocations", filename: "stops", setName: "topLocations", queryFunction: "get_location_top", message: "get_location_top is requested", requiresFilter: true},
  { workerName: "kpiLocation", filename: "kpi", setName: "kpiLocation", queryFunction: "get_fleet_kpi", message: "get_fleet_kpi is requested", requiresFilter: false}
]
const apiConfigurationsRef = ref(apiConfigurations)

const findSetNameByWorkerName = (workerName) => {
  const config = apiConfigurations.find(config => config.workerName === workerName)
  return config ? config.setName : null
}
const workerCount = ref(0)
const handleDataReady = (data, workerName) => {
  log("info", `${workerName} is filtered and fetched!`)
  workerCount.value++

  const setNames = [].concat(findSetNameByWorkerName(workerName))
  const pinia = getActivePinia()
  if (!pinia) return []
  setNames.map(dataSetName => {
    dataStore.setAggregate(dataSetName, data.result)
  })

  const storeNames = Object.keys(pinia.state.value).filter(key => key.toLowerCase().includes("map"))

  storeNames.forEach((storeId) => {
    const store = pinia._s.get(storeId)
    if (store && typeof store.setAggregate === "function") {
      setNames.map(dataSetName => {
        store.setAggregate(dataSetName, data.result)
      })
    }
  })

//  emit("handle-data-ready", dataSetName)
  triggerMapDataChangeOnCoupledLayers()
}

const handleError = (error) => {
  log("error", `Error occurred: ${error}`)
}

const { postMessageToDuckAPI, registerListeners } = useDataFetcher(apiConfigurationsRef, {
  onDataReceived: handleDataReady,
  onError: handleError
})

const fetchFilteredData = () => {
  apiConfigurationsRef.value.forEach(apiConfig => {
    const worker = workerManager.getWorker(apiConfig.workerName)
    const configWithOptionalFilters = apiConfig.requiresFilter ? {...apiConfig, filters: getFilter()} : apiConfig
    postMessageToDuckAPI(worker, configWithOptionalFilters)
  })
}

const chassisOptions = computed(() => {
  return dataStore.getAggregate("vehicleRegNos") ? dataStore.getAggregate("vehicleRegNos").flatMap(vehicle => { return vehicle.reg_no}) : []//.flatMap(vehicle => { return vehicle.reg_no})
})

function extractMbIds(mapLayers) {
  const mbIds = []
  for (const layerType in mapLayers) {
    const layers = mapLayers[layerType]
    layers.forEach(layer => {
      if (layer.mbId) {
        mbIds.push(layer.mbId)
      }
    })
  }
  return mbIds
}

const allMapLayers = computed(() => {
  const pinia = getActivePinia()
  if (!pinia) return []

  const storeNames = Object.keys(pinia.state.value).filter(key => key.toLowerCase().includes("map"))
  const allMbIds = []

  storeNames.forEach((storeId) => {
    const storeState = pinia.state.value[storeId]
    if (storeState.mapLayers) {
      const mbIds = extractMbIds(storeState.mapLayers)
      allMbIds.push(...mbIds)
    }
  })

  return allMbIds
})

const disableFilterButton = computed(() => workerCount.value > 0 && workerCount.value < apiConfigurations.length)
const updateSelectedChassis = () => {
  fleetStore.setSelectedChassis(chosenChassis.value)
}

const updateDateRange = ([minDate, maxDate]) => {
  dateRange.value = {min_date: minDate, max_date: maxDate}
}

const triggerMapDataChangeOnCoupledLayers = () => {
  nextTick(() => {
    allMapLayers.value.forEach(l => unref(map).fire("layerDataChanged", { layerName: l }) )
  })
}

onMounted(() => {
  registerListeners()
})

</script>

<style scoped>
.mappy-filter-data-block {
  display: flex;
  flex-direction: row;
}

.mappy-fleet-filter-options {
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 120px;
  color: black;
}

.mappy-filter-data {
  background-color: deeppink;
  border: none;
  border-radius:5px;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 12px;
  cursor: pointer;
  margin-left: 2px;
  min-width: 5px;
  max-width: 15px;
  height: 100% !important;
}

</style>
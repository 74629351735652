<script setup>
import { computed, ref, onMounted } from "vue"
import {useMainStore} from "@/store/mainStore"

const props = defineProps({
  styles: {
    type: Array,
    required: true
  }
})

const mainStore = useMainStore()
const mapboxToken = process.env.VUE_APP_MAP_TOKEN
const allVisible = ref(false)
const selectedItem = ref(null)
const isSelected = (style) => {
  return style === mainStore.getSelectedBaseMap
}

function setBaseMapStyle (style) {
  mainStore.setSelectedBaseMap(style)
  selectedItem.value = style
}

function showAll () {
  allVisible.value = true
}
function hideAll () {
  allVisible.value = false
}

const orderedStyles = computed(() => {
    if (!selectedItem.value) return props.styles

    return [
      selectedItem.value,
      ...props.styles.filter(item => item !== selectedItem.value)
    ]
})

onMounted(() => {
  selectedItem.value = props.styles[0]
})
</script>

<template>
  <div
    class="base-layer-selection"
    @mouseover="showAll"
    @mouseleave="hideAll"
  >
    <img
      v-for="style in orderedStyles"
      :key="style"
      class="mini-map"
      :class="[isSelected(style) ? 'selected-base-style' : '', { 'visible': allVisible || isSelected(style) }]"
      :src="'https://api.mapbox.com/styles/v1/'+style+'/static/-10.4241,55.78,1,0,0/600x600?access_token='+mapboxToken"
      @click="setBaseMapStyle(style)"
    >
  </div>
</template>

<style scoped>
.base-layer-selection {
  position: fixed;
  bottom: 32px;
  left: 66px;
  font-weight: 600;
  letter-spacing: 0.08em;
  color: white;
  cursor: pointer;
  z-index: 5;
  display: flex;
  overflow: hidden;
}

.mini-map {
  margin-left: 3px;
  border: 1px solid white;
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  transition: opacity 400ms;
  transition-delay: 300ms;
  opacity: 0;
  border-radius: 10px;
}

.selected-base-style {
  border: 4px solid #1DAB8B;
}

.visible {
  opacity: 1;
}

</style>
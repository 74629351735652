import { scaleQuantile, scaleThreshold, scaleSequential } from "d3-scale"
import { min, max } from "d3-array"
import { color } from "d3-color"
import scaleCluster from "d3-scale-cluster"
import chroma from "chroma-js"

import {
    schemeBlues,
    schemeBrBG,
    schemeGreens,
    schemeOranges,
    schemePiYG,
    schemePuRd,
    schemeRdYlGn,
    schemeYlGn
} from "d3-scale-chromatic"


export function generateGreenYellowRedColorScheme(colorCount) {
  return chroma.scale(["#1DAB8B", "#66CC66", "#FFFF00", "#FF2340"]).mode("lch").colors(colorCount)
}

export const defaultColour = generateGreenYellowRedColorScheme(6)

export function getHourBucketsColourScale() {
  return scaleThreshold()
    .domain([0, 1, 8, 24, 168, 504])
    .range([
      [224, 170, 255],
      [199, 125, 255],
      [157, 78, 221],
      [123, 44, 191],
      [90, 24, 154],
      [60, 9, 108]
    ])
}

export function getHeatmapColourScale (dataDomain, keyAsString) {
  const colours = ["#ffe5f2", "#ffcce5", "#ffb2d8", "#ff66b2", "#ff4ca6", "#FF0080", "#E50073"]
  const dataDomainForKey = dataDomain.map(d => d[keyAsString])
  return scaleQuantile()
    .domain([0, max(dataDomainForKey)])
    .range(colours)
}

export function getColourScaleForDomain (dataDomain, keyAsString, colours) {
  const dataDomainForKey = dataDomain.map(d => d[keyAsString])

  return scaleQuantile()
    .domain([min(dataDomainForKey), max(dataDomainForKey)])
    .range(colours)
}

export function getAutomaticBinsForColourScaleInDomain (dataDomain, keyAsString, colours) {
    const dataDomainForKey = dataDomain.map(d => d[keyAsString]).filter(d => d != null).sort(function(a, b) { return a - b })
    return scaleCluster()
        .domain(dataDomainForKey)
        .range(colours)
}

export function getColorArrayForSchema (schema) {
    const colorToRGB = (cAsHex) => {
        let col = color(cAsHex)
        return [col.r, col.g, col.b]
    }
    return schema.map(colorToRGB)
}

export function getColorRamp (colorCount) {
    const schemeGnYlRd = generateGreenYellowRedColorScheme(colorCount)
    const colourRamp = {
          colors: [
            schemeGnYlRd,
            schemeGreens[colorCount],
            schemeBrBG[colorCount],
            schemePiYG[colorCount],
            schemeRdYlGn[colorCount],
            schemeBlues[colorCount],
            schemeOranges[colorCount],
            schemePuRd[colorCount],
            schemeYlGn[colorCount]
          ],
          name: ["schemeGnYlRd", "schemeGreens", "schemeBrBG", "schemePiYG", "schemeRdYlGn", "schemeBlues",
              "schemeOranges","schemePuRd","schemeYlGn" ]
      }
      return colourRamp
}

export function getColorRampDiv (colourRamp, schemeName) {
    const divColourRamp= {
        colors: [colourRamp.colors[colourRamp.name.indexOf(schemeName)]],
        name: [schemeName]
    }
    return divColourRamp
}
export function getInvertedColourObj (colourScale) {
    return colourScale.range().map((range) => {
        const red = range[0]
        const green = range[1]
        const blue = range[2]
        const rgbString = `rgb(${red},${green},${blue})`
        return { colour: rgbString, domain: colourScale.invertExtent(range) }
    })
}
<template>
  <div class="aggregate-card-container">
    <slot name="content" />
  </div>
</template>


<style scoped>
.aggregate-card-container {
  overflow-y: hidden;
  overflow-x: hidden;
  z-index: 8;
  display: flex;
  font-family: "Scania Sans Semi Condensed", "Scania Sans Condensed", Arial, Helvetica, sans-serif;
  background-color: white;
  color: var(--tds-blue-800);
  border-radius: 15px;
  padding: 10px;
  width: 100%;
  height: fit-content;
  margin-top: 4px;
  text-align: center;
  align-items: center;
  flex-direction: column;
}

</style>

<template>
  <div class="hexagonResolutionElements">
    <number-input
      v-model="resolutionToRequest"
      :min="2"
      :max="13"
      inline
      center
      controls
    />
    <label class="hexResLabel">Current zoom is {{ hexagonResolution }}</label>
  </div>
</template>

<script setup>
import {computed, nextTick, onUnmounted, ref, unref, watch, defineProps, inject} from "vue"
import {log} from "@/plugin/logger"
import NumberInput from "@chenfengyuan/vue-number-input"
import {authService} from "@/services/AuthService"
import {useMap} from "@/composables"
import {useMainStore} from "@/store/mainStore"
import {useFleetStore} from "@/store/fleet/fleetStore"
import {useSetStore} from "@/store/setStore"

const { map } = useMap()

const props = defineProps({
  moduleName: {
    type: String,
    required: true
  },
  layerName: {
    type: String,
    required: true
  }
})

const mainStore = useMainStore()
const fleetStore = useFleetStore()
const dataStore = useSetStore()
const useCustomStore = inject(props.moduleName)

const workerCount = ref(0)

const dataFetchUrl = mainStore.getAppConfigFlag("useStagingStack")
    ? process.env.VUE_APP_GRAPH_ENDPOINT_B
    : process.env.VUE_APP_GRAPH_ENDPOINT

const postMessageToAPI = (API, token, endpoint, queryParams, logMessage) => {
  log("info", logMessage)

  API.postMessage({
    method: "getSweetEDataFromSnowflake",
    args: [ token, endpoint, dataFetchUrl, queryParams ]
  })
}

const isMarketUser = () => {
  return mainStore.getAppConfigFlag("isAdmin", authService.getUserRoles(), authService.getUserEmail())
}

const resolutionToRequest = ref(fleetStore.getResolutionToRequest)

const hexagonResolution = computed(() => { return fleetStore.getHexagonResolution })

function createWorker() {
  return new Worker(
      new URL("../web-worker/snowflakeWorker.js", import.meta.url),
      { type: "module" }
  )
}

const workerNames = ["edgesAPI", "hexesAPI"]

const workers = {}

workerNames.forEach(name => {
  workers[name] = createWorker()
})

const fetchData = () => {
  workerCount.value = 0
  const token = mainStore.getBearerToken
  const queryParams = fleetStore.getSelectedParams
  let endpoint = dataFetchUrl

  const apiConfigurations = [
    { worker: workers.hexesAPI, sqlFilename: "HEXAGONS", message: "Hexagons was requested" },
    { worker: workers.edgesAPI, sqlFilename: "EDGES", message: "Edges was requested" }
  ]

  const path = isMarketUser()
      ? "/Prod/PostSnowflakeQueryCache"
      : "/Prod/PostSnowflakeMarketQueryCache"

  endpoint += path

  apiConfigurations.forEach(api => {
    postMessageToAPI(
        api.worker,
        token, endpoint,
        buildRequestParams(api.sqlFilename, queryParams),
        api.message
    )
  })
}

const buildRequestParams = (sqlFilename, queryParams) => {
  return {
    sqlfilename: sqlFilename,
    stopqueryid: dataStore.getQueryIdFor("rawStops"),
    ...queryParams,
    mindate: fleetStore.getSelectedMinTime.toLocaleDateString("sv-SE"),
    maxdate: fleetStore.getSelectedMaxTime.toLocaleDateString("sv-SE")
  }
}

Object.values(workers).forEach(worker => {
  worker.onmessage = (event) => {
    if (event.data.key === "dataReady") {
      handleDataReady(event, event.data.value.requested, `${event.data.value.requested} is fetched!`)
      handleWorkerCompletion()
    }
  }
})

const triggerMapDataChangeOnCoupledLayers = () => {
  nextTick(() => {
    const mapLayers = useCustomStore.getStateForKey("mapLayers")
    const hexLayerIndex = props.layerName.split("_").pop()
    const layers = [mapLayers["Trip"][hexLayerIndex]["mbId"], props.layerName]

    layers.forEach(l => unref(map).fire("layerDataChanged", { layerName: l }) )
  })
}
const handleWorkerCompletion = () => {
  // Increment the counter when a worker completes its task
  workerCount.value++

  // Check if both workers have completed
  if (workerCount.value === workerNames.length) {
    // Trigger only when both workers are done
    triggerMapDataChangeOnCoupledLayers()
    fleetStore.setHexagonResolution(fleetStore.getResolutionToRequest)
  }
}
const handleDataReady = (event, aggregateName, logMessage) => {
  log("info", logMessage)
  fleetStore.setAggregate(aggregateName,event.data.value.result)
  dataStore.setAggregate(aggregateName,event.data.value.result)
}

onUnmounted(() => {
  Object.values(workers).forEach(worker => {
    worker.terminate()
  })
})

watch(
    resolutionToRequest,
    (newValue, oldValue) => {
      if (oldValue !== newValue && oldValue) {
        fleetStore.setResolutionToRequest(parseInt(newValue))
        fetchData()
      }
    },
    { immediate: true }
)

</script>

<style>
.hexagonResolutionElements {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
}

.hexagonResolutionElements .vue-number-input__button--plus::after,.hexagonResolutionElements .vue-number-input__button--plus::before,
.hexagonResolutionElements .vue-number-input__button--minus::after,.hexagonResolutionElements .vue-number-input__button--minus::before {
  display: none;
}

.hexagonResolutionElements .vue-number-input__button--plus {
  background-image: url('@/assets/zoom_in.svg');
  background-repeat: no-repeat;
  top: 20%;
  height: 20px;
  width: 20px;
  border: none;
}

.hexagonResolutionElements .vue-number-input__button--minus {
  background-image: url('@/assets/zoom_out.svg');
  background-repeat: no-repeat;
  top: 20%;
  height: 20px;
  width: 20px;
  border: none;
}
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.vue-number-input {
  display: flex;
  align-items: center;
}

.hexResLabel {
  width:100%;
  font-size:small;
  color:#9BADBF;
  margin-left: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 8px;
}
</style>
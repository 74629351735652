<template>
  <div>
    <section
      v-show="props.receivedStops"
      class="infoContainer"
    >
      <p class="infoField">
        Chassis count / Chassis requested:
        <span>{{ props.numberOfChassisInSet }} / {{ props.numberOfChassisRequested }}</span>
      </p>
      <p class="infoField">
        Stop count:
        <span id="noChassis">{{ props.stopCount }}</span>
      </p>
      <p class="infoField">
        Earliest stop:
        <span>{{ props.minTime }}</span>
      </p>
      <p class="infoField">
        Latest stop in set:
        <span>{{ props.maxTime }}</span>
      </p>
      <p class="infoField">
        Data set name:
        <span>{{ props.setName }}</span>
      </p>
      <p class="warningText">
        {{ props.errorMessage }}
      </p>
    </section>
    <div v-show="!receivedStops">
      <p>{{ props.message }}</p>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue"

const props = defineProps({
  receivedStops: {
    type: Boolean,
    required: true
  },
  stopCount: {
    type: Number,
    required: true
  },
  minTime: {
    type: String,
    required: true
  },
  maxTime: {
    type: String,
    required: true
  },
  numberOfChassisRequested: {
    type: Number,
    required: true
  },
  numberOfChassisInSet: {
    type: Number,
    required: true
  },
  setName: {
    type: String,
    required: true
  },
  errorMessage: {
    type: String,
    required: true
  },
  message: {
    type: String,
    required: true
  }
})

</script>

<style scoped>
.infoContainer {
  background-color: #f7f7f7;
  padding: 10px;
  border-radius: 5px;
  text-align: left;
}

.infoField {
  margin: 5px 0;
  font-size: 14px;
  font-weight: bold;
  color: #3366cc;
}

.warningText {
  color: darkred;
}
</style>

<template>
  <div class="mappy-filter-data-block">
    <tds-block class="mappy-fleet-filter-options">
      <Multiselect
        v-model="chosenChassis"
        placeholder="Filter on chassis"
        mode="tags"
        :options="chassisOptions"
        :close-on-select="false"
        :searchable="true"
        :clear-on-select="false"
        :limit="3"
        @change="updateSelectedChassis"
      />
      <date-range-filter />
      <RangeInput
        :min-range="minOdometerForRange"
        :max-range="maxOdometerForRange"
        range="getSelectedMinMaxOdometer"
        min-state-function-string="setSelectedMinOdometer"
        max-state-function-string="setSelectedMaxOdometer"
        set-state-function-string="setSelectedMinMaxOdometer"
        filter-key="odometer"
        filter-name="Stop ingoing delta odometer (km)"
        unit="km"
      />
      <RangeInput
        :min-range="minFuelForRange"
        :max-range="maxFuelForRange"
        range="getSelectedMinMaxFuel"
        min-state-function-string="setSelectedMinFuel"
        max-state-function-string="setSelectedMaxFuel"
        set-state-function-string="setSelectedMinMaxFuel"
        filter-key="totalfuel"
        filter-name="Stop ingoing delta fuel (l)"
        unit="l"
      />
      <RangeInput
        :min-range="minDurationForRange"
        :max-range="maxDurationForRange"
        range="getSelectedMinMaxDuration"
        min-state-function-string="setSelectedMinDuration"
        max-state-function-string="setSelectedMaxDuration"
        set-state-function-string="setSelectedMinMaxDuration"
        filter-key="stop_duration"
        filter-name="Stop duration (s)"
        unit="s"
      />
    </tds-block>
    <v-btn
      class="mappy-filter-data"
      :disabled="disableFilterButton"
      @click="filterData"
    >
      <font-awesome-icon icon="filter" />
    </v-btn>
  </div>
</template>

<script setup>
import DateRangeFilter from "@/components/DateRangeFilter.vue"
import RangeInput from "@/components/RangeInput.vue"
import Multiselect from "@vueform/multiselect"
import {defineEmits, computed, ref, nextTick, unref, inject} from "vue"
import "@vueform/multiselect/themes/default.css"
import {log} from "@/plugin/logger"
import {workerManager} from "@/services/workerManager"
import {useMap} from "@/composables"
import {useMainStore} from "@/store/mainStore"
import {useFleetStore} from "@/store/fleet/fleetStore"
import {useSetStore} from "@/store/setStore"
import {getActivePinia} from "pinia"

const props = defineProps({
  lambdaPath: {
    type: String,
    default: "/Prod/PostSnowflakeQueryCache"
  }
})

const mainStore = useMainStore()
const fleetStore = useFleetStore()
const dataStore = useSetStore()

const emit = defineEmits(["update-size", "handle-data-ready"])
const { map } = useMap()
const chosenChassis = ref([])
const lambdaPath = ref(props.lambdaPath)

const dataFetchUrl = mainStore.getAppConfigFlag("useStagingStack")
    ? process.env.VUE_APP_GRAPH_ENDPOINT_B
    : process.env.VUE_APP_GRAPH_ENDPOINT

// API configurations for workers
const apiConfigurations = [
  { workerName: "hexesAPI", sqlFilename: "HEXAGONS", message: "Hexagons was requested" },
  { workerName: "edgesAPI", sqlFilename: "EDGES", message: "edges was requested" },
  { workerName: "dailyGraphAggregateAPI", sqlFilename: "DAILY_AGGREGATES", message: "daily_aggregates was requested" },
  { workerName: "odometerBoxplotAPI", sqlFilename: "ODOMETER_BOXPLOT", message: "odometer_boxplot was requested" },
  { workerName: "fuelBoxplotAPI", sqlFilename: "FUEL_BOXPLOT", message: "fuel_boxplot was requested" },
  { workerName: "dailyFuelBoxplotAPI", sqlFilename: "DAILY_FUEL_BOXPLOT", message: "daily_fuel_boxplot was requested" },
  { workerName: "dailyOdometerBoxplotAPI", sqlFilename: "DAILY_ODOMETER_BOXPLOT", message: "daily_odometer_boxplot was requested" },
  { workerName: "hourlyLocationDistributionAPI", sqlFilename: "HOURLY_ONGOING_STOPS", message: "hourly_ongoing_stops was requested" },
  { workerName: "dailyHourlyLocationDistributionAPI", sqlFilename: "DAILY_HOURLY_ONGOING_STOPS", message: "daily_hourly_ongoing_stops was requested" }
]
const workerCount = ref(0)

// Functions for API communication and workers
const postMessageToAPI = (API, token, endpoint, queryParams, logMessage) => {
  log("info", logMessage)
  API.postMessage({
    method: "getSweetEDataFromSnowflake",
    args: [ token, endpoint, dataFetchUrl, queryParams ]
  })
}

// Function to build request parameters for API calls
const buildRequestParams = (sqlFilename, queryParams) => {
  return {
    sqlfilename: sqlFilename,
    stopqueryid: dataStore.getQueryIdFor("rawStops"),
    ...queryParams
  }
}

const chassisOptions = computed(() => {
  return fleetStore.getTilesChassis
})

const allMapLayers = computed(() => {
  const storeNames = ["sweete_mainMap", "sweete_1", "sweete_2", "sweete_3"] // Hardcoded store names, needs to be fixed.
  const allMapLayers = []
  const pinia = getActivePinia()
  if (pinia) {
    console.log(pinia.state)
    console.log(Object.values(pinia.state))
  }
  return allMapLayers
})

const maxOdometerForRange = computed(() => fleetStore.getMinMaxOdometer[1])
const minOdometerForRange = computed(() => fleetStore.getMinMaxOdometer[0])

const maxFuelForRange = computed(() => fleetStore.getMinMaxFuel[1])
const minFuelForRange = computed(() => fleetStore.getMinMaxFuel[0])

const maxDurationForRange = computed(() => fleetStore.getMinMaxDuration[1])
const minDurationForRange = computed(() => fleetStore.getMinMaxDuration[0])

const disableFilterButton = computed(() => workerCount.value > 0 && workerCount.value < apiConfigurations.length)

// Extracting mbId from each layer
const layers = computed(() => {
  // Flattening the layers from each mapLayer category
  const flattenedLayers = [].concat(...Object.values(allMapLayers.value))
  // Returning an array of mbIds
  return flattenedLayers.map(l => l.mbId)
})

// Extracting mbId from each circle layer
const circleLayers = computed(() => {
  // Flattening the layers from each mapLayer category
  const flattenedLayers = [].concat(...Object.values(allMapLayers.value))
  // Returning an array of mbIds
  return flattenedLayers.filter(l => l.visualisedAs === "Circle").map(l => l.mbId)
})

const updateSelectedChassis = () => {
  fleetStore.setSelectedChassis(chosenChassis.value)
}

const filterData = () => {
  workerCount.value = 0
  registerListeners()
  const token = mainStore.getBearerToken
  let endpoint = dataFetchUrl
  const queryParams = fleetStore.getSelectedParams
  createAndUpdateFilters(queryParams)
  endpoint += lambdaPath.value
  apiConfigurations.forEach(apiConfig => {
    const worker = workerManager.getWorker(apiConfig.workerName)
    postMessageToAPI(
        worker,
        token,
        endpoint,
        buildRequestParams(apiConfig.sqlFilename, queryParams),
        apiConfig.message
    )
  })
}

const createAndUpdateFilters = (queryParams) => {
  const filteredParams = {...queryParams}

  let startDateTimestamp = Date.parse(filteredParams.mindate) / 1000
  let endDateTimestamp = Date.parse(filteredParams.maxdate) / 1000 + 86399
  const activeMinTimeFilter = [">=", ["to-number", ["get", "stop_start_time"]], startDateTimestamp]
  const activeMaxTimeFilter = ["<=", ["to-number", ["get", "stop_start_time"]], endDateTimestamp]

  let odometerMinValue = filteredParams.minodometer
  let odometerMaxValue = filteredParams.maxodometer
  const activeMinOdometerFilter = [">=", ["to-number", ["get", "odometer"]], odometerMinValue]
  const activeMaxOdometerFilter = ["<=", ["to-number", ["get", "odometer"]], odometerMaxValue]

  let fuelMinValue = filteredParams.minfuel
  let fuelMaxValue = filteredParams.maxfuel
  const activeMinFuelFilter = [">=", ["to-number", ["get", "totalfuel"]], fuelMinValue]
  const activeMaxFuelFilter = ["<=", ["to-number", ["get", "totalfuel"]], fuelMaxValue]

  let durationMinValue = filteredParams.minduration
  let durationMaxValue = filteredParams.maxduration

  const activeMinDurationFilter = [">=", ["to-number", ["get", "stop_duration"]], durationMinValue]
  const activeMaxDurationFilter = ["<=", ["to-number", ["get", "stop_duration"]], durationMaxValue]

  let filteredChassis = filteredParams.chassis
  let activeChassisFilter
  if (filteredChassis.length > 0) {
    activeChassisFilter = ["match", ["get", "chassisnumber"], filteredChassis, true, false]
  } else { // Or remove filters
    activeChassisFilter = ["match", ["get", "chassisnumber"], [""], true, true]
  }

  const updatedFilter = ["all", activeChassisFilter, activeMinTimeFilter,
    activeMaxTimeFilter, activeMinOdometerFilter, activeMaxOdometerFilter,
    activeMinFuelFilter, activeMaxFuelFilter,
    activeMinDurationFilter, activeMaxDurationFilter]

  circleLayers.value.forEach(circleLayer => {
    unref(map).setFilter(circleLayer, updatedFilter)
  })
}

const triggerMapDataChangeOnCoupledLayers = () => {
  nextTick(() => {
    layers.value.forEach(l => unref(map).fire("layerDataChanged", { layerName: l }) )
  })
}

const handleDataReady = (event, aggregateName, logMessage) => {
  log("info", logMessage)
  fleetStore.setAggregate(aggregateName, event.data.value.result)
  dataStore.setAggregate(aggregateName,event.data.value.result)
  emit("handle-data-ready", aggregateName)
  triggerMapDataChangeOnCoupledLayers()
}

const handleWorkerResponse = (event) => {
  if (event.data.key === "dataReady") {
    handleDataReady(event, event.data.value.requested, `${event.data.value.requested} is fetched!`)
    workerCount.value++
  }
}

const registerListeners = () => {
  apiConfigurations.forEach(config => {
    workerManager.registerListener(config.workerName, (event) => handleWorkerResponse(event, lambdaPath.value))
  })
}
</script>

<style scoped>
.mappy-filter-data-block {
  display: flex;
  flex-direction: row;
}

.mappy-fleet-filter-options {
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 120px;
  color: black;
}

.mappy-filter-data {
  background-color: deeppink;
  border: none;
  border-radius:5px;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 12px;
  cursor: pointer;
  margin-left: 2px;
  min-width: 5px;
  max-width: 15px;
  height: 100% !important;
}

</style>
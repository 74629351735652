<script setup>
import BaseMap from "@/components/BaseMap"
import HexagonLayer from "@/components/HexagonLayer"
import ArcLayer from "@/components/ArcLayer"
import ContentTable from "@/components/ContentTable"
import {watch, ref, computed, onUnmounted, nextTick, onMounted, provide} from "vue"

import { log } from "@/plugin/logger.js"
import FleetMapControl from "@/components/FleetMapControl"
import MapboxPopup from "@/components/MapboxPopup"
import CircleLayer from "@/components/CircleLayer.vue"
import {useMainStore} from "@/store/mainStore"
import {useFleetStore} from "@/store/fleet/fleetStore"
import {createCustomMapStore} from "@/store/customMapStore"
import {useSetStore} from "@/store/setStore"

const props = defineProps({
  mapModuleStateName: {
    type: String,
    required: true
  },
  loadWithStops: {
    type: Boolean,
    default: false
  },
  loadWithLocations: {
    type: Boolean,
    default: false
  },
  loadWithArcs: {
    type: Boolean,
    default: false
  }
})

// eslint-disable-next-line vue/valid-define-emits
const emit = defineEmits()
const mainStore = useMainStore()
const dataStore = useSetStore()
const fleetStore = useFleetStore()

const stops = ref(props.loadWithStops)
const arcs = ref(props.loadWithArcs)
const locations = ref(props.loadWithLocations)

const mapboxToken = process.env.VUE_APP_MAP_TOKEN
const useMapStore = createCustomMapStore(props.mapModuleStateName)

const zoom = ref(1.62)
const isOpen = ref(false)
const position = ref([0, 0])
const content = ref()
async function openMBPopup({ coordinates, properties}) {
  await nextTick()
  closePopup()

  isOpen.value = true
  position.value = [...coordinates]

  content.value = Object.fromEntries(
      Object.entries(properties).map(([key, value]) => {
        try {
          return [key, JSON.parse(value)]
        } catch (err) {
          // Silence is golden.
        }
        return [key, value]
      })
  )
}

const createdHandler = () => log("info", "Map created!")

const arcLayerLoaded = () => {
  log("info", "Arc layer is done loading!")
  arcLayerLoaded.value = true
}

const hexagonsLoaded = computed(() => dataStore.getAggregate("HEXAGONS"))
const edgesLoaded = computed(() => dataStore.getAggregate("EDGES"))

const loadData = () => {
  if (props.mapModuleStateName === "sweete_mainMap") {
    const dataSetOptionsForMap = fleetStore.getDataSetOptionsForMap

    if (hexagonsLoaded.value) {
      const locations = {...dataSetOptionsForMap.find(item => item.accessorKey === "HEXAGONS")}
      locations["visualisedAs"] = "Hexagon"
      useMapStore.setMapLayer(locations)
    }
    if (edgesLoaded.value) {
      const trips = {...dataSetOptionsForMap.find(item => item.accessorKey === "EDGES")}
      trips["visualisedAs"] = "Trip"
      useMapStore.setMapLayer(trips)
    }
    if (stops.value) {
      const stops = {...dataSetOptionsForMap.find(item => item.setName === "Stops")}
      stops["visualisedAs"] = "Circle"
      useMapStore.setMapLayer(stops)
    }
  }
}

const hexagonLayers = computed(() => {
  const hexagonMapLayers = useMapStore.getStateForKey("mapLayers")["Hexagon"]

  // Check if hexagonMapLayers is falsy or empty
  if (!hexagonMapLayers || hexagonMapLayers.length < 1) {
    return []
  }

  // Filter and transform the layers
  return hexagonMapLayers
    .map((layer, index) => {
      const dataLoaded = dataStore.getAggregate(layer.accessorKey)
      if (dataLoaded) {
        return {
          ...layer,
          layerName: `${layer.setName}_${index}`
        }
      }
    })
    .filter(Boolean) // Remove falsy values from the array (e.g., null)
})

const arcLayers = computed(() => {
  const arcMapLayers = useMapStore.getStateForKey("mapLayers")["Trip"]

  // Check if hexagonMapLayers is falsy or empty
  if (!arcMapLayers || arcMapLayers.length < 1) {
    return []
  }

  // Filter and transform the layers
  return arcMapLayers
    .map((layer, index) => {
      const dataLoaded = dataStore.getAggregate(layer.accessorKey)
      if (dataLoaded) {
        return {
          ...layer,
          layerName: `${layer.setName}_${index}`
        }
      }
    })
    .filter(Boolean)
})

const circleLayers = computed(() => {
  const circleMapLayers = useMapStore.getStateForKey("mapLayers")["Circle"]

  if (!circleMapLayers || circleMapLayers.length < 1) {
    return []
  }

  return circleMapLayers
    .map((layer, index) => {
      const dataLoaded = dataStore.getAggregate(layer.accessorKey)
      if (dataLoaded) {
        return {
          ...layer,
          layerName: `${layer.setName}_${index}`
        }
      }
    })
    .filter(Boolean)
})

const edgeDataArray = (accessorKeyForDataFunction) => {
  return dataStore.getAggregate(accessorKeyForDataFunction) ? dataStore.getAggregate(accessorKeyForDataFunction) : []
}

const popupKey = computed(() => { return position.value.join("-") })

const layersInMap = computed(() => {
  return [...hexagonLayers.value, ...arcLayers.value]
})

const closePopup = () => {
  isOpen.value = false
}

const baseMap = ref()

const mapStyle = computed(() => {
  return "mapbox://styles/" + mainStore.getSelectedBaseMap
})

onMounted(() => {
  log("info", "Initiated a 'FleetMapView' with ", props.mapModuleStateName, " name.")
  emit("baseMapUpdated", baseMap.value?.map)
  loadData()
})

onUnmounted(() => {
  emit("baseMapRemoved")
})

watch(stops, (newVal, oldVal) => {
  loadData()
})

watch(hexagonsLoaded, (newVal, oldVal) => {
  loadData()
})

watch(edgesLoaded, (newVal, oldVal) => {
  loadData()
})

provide(props.mapModuleStateName, useMapStore)

</script>

<template>
  <BaseMap
    ref="baseMap"
    class="map"
    :access-token="mapboxToken"
    :map-style="mapStyle"
    :zoom="zoom"
    :max-zoom="18"
  >
    <MapboxPopup
      v-if="isOpen"
      :key="popupKey"
      :lng-lat="position"
      anchor="bottom"
      @mb-close="closePopup"
    >
      <ContentTable :content="content" />
    </MapboxPopup>

    <fleet-map-control
      :layers="layersInMap"
      :named-module="props.mapModuleStateName"
      :hexagon-layers="hexagonLayers"
      :flow-layers="arcLayers"
      data-sets-getter="getDataSetOptionsForMap"
    />
    <CircleLayer
      v-for="(layer, index) in circleLayers"
      :id="layer.setName + '_' + index"
      :key="layer.setName + '_' + index + '_tiles_layer'"
      :named-module="props.mapModuleStateName"
      source="stop"
      @mb-layer-click="openMBPopup"
    />
    <HexagonLayer
      v-for="(layer, index) in hexagonLayers"
      :id="layer.setName + '_' + index"
      :key="layer.setName + '_' + index + '_hexagon_layer'"
      :get-data-function="layer.accessorInState"
      :accessor-key-for-data-function="layer.accessorKey"
      :named-module="props.mapModuleStateName"
      :key-for-scale="layer.colourKey"
      :color-schema="layer.colourSchema"
      :key-for-elevation="layer.colourKey"
      h3-key="h3_cell_index"
      @mb-layer-click="openMBPopup"
    />
    <ArcLayer
      v-for="(layer, index) in arcLayers"
      :id="layer.setName + '_' + index"
      :key="layer.setName + '_' + index + '_arc_layer'"
      :named-module="props.mapModuleStateName"
      :edge-data="edgeDataArray(layer.accessorKey)"
      @mb-layer-click="openMBPopup"
    />
  </BaseMap>
</template>

<style>
.map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.mapboxgl-popup-content {
  word-wrap: break-word;
  width: 300px;
}
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  font-size: 10px;
  font-family: 'Scania Sans';
  word-break: break-word;

}

th, td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
  font-weight: bold;
}

tr:hover {
  background-color: pink;
}
</style>

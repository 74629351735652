<script setup>
import {ref, getCurrentInstance, inject} from "vue"
import { log } from "@/plugin/logger"
import {useSetStore} from "@/store/setStore"

const props = defineProps({
  namedModule: {
    type: String,
    required: true
  },
  dataSetsGetter : {
    type: String,
    required: true
  }
})

const dataStore = useSetStore()
const useCustomStore = inject(props.namedModule)

const instance = getCurrentInstance()
const dataLayerDropdown = ref([])
const selectedDataSets = ref([])
const existingDataSets = ref([...dataStore[props.dataSetsGetter]])
const enableAddButton = ref(false)
function addMapLayer () {
  log("info", "Going to add layer from settings.")
  selectedDataSets.value.map(set => {
    useCustomStore.setMapLayer(set)
  })
  enableAddButton.value = false
  resetAll()
}

function removeMapLayer () {
  log("info", "Going to remove layer from settings.")
  selectedDataSets.value.map(set => {
    useCustomStore.removeMapLayer(set)
  })
  enableAddButton.value = false
  resetAll()
}

function changedLayer (event, setIndex) {
  if (event.detail.value) {
    const layer = {...existingDataSets.value[setIndex]}
    layer["visualisedAs"] = event.detail.value
    selectedDataSets.value = [...selectedDataSets.value, layer]
    enableAddButton.value = true
  }
}

const setComponentRef = index => el => {
  dataLayerDropdown.value[index] = el
}

const resetAll = () => {
  dataLayerDropdown.value.forEach(compRef => {
    if (compRef && compRef.reset) {
      compRef.reset()
    }
  })
}

</script>

<template>
  <div class="mappy-layer-data-block">
    <tds-block class="mappy-layer-options">
      <div
        v-for="(set, index) in existingDataSets"
        :key="set.setName"
        class="mappy-layer-setting"
      >
        <h6 class="mappy-block-headline">
          {{ set.setName }}
        </h6>
        <!-- eslint-disable vue/v-on-event-hyphenation -->
        <tds-dropdown
          :ref="setComponentRef(index)"
          placeholder="Select map layer"
          size="sm"
          open-direction="auto"
          @tdsChange="changedLayer($event, index)"
        >
          <tds-dropdown-option
            v-for="(option, jindex) in set.canBe"
            :key="jindex + '_' + option"
            :value="option"
          >
            {{ option }}
          </tds-dropdown-option>
        </tds-dropdown>
      </div>
    </tds-block>
    <v-btn
      :disabled="!enableAddButton"
      class="mappy-add-layer"
      @click="addMapLayer()"
    >
      <font-awesome-icon icon="add" />
    </v-btn>
  </div>
</template>

<style scoped>
.mappy-layer-data-block {
  display: flex;
  flex-direction: row;
}
.mappy-layer-options {
  overflow-y: scroll;
  overflow-x: hidden;
  min-width: 120px;
}
.mappy-add-layer {
  background-color: deeppink;
  border: none;
  border-radius:5px;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 12px;
  cursor: pointer;
  margin-left: 2px;
  min-width: 5px;
  max-width: 15px;
  height: 100% !important;
}

.mappy-block-headline {
  font-size: 10px;
  font-weight: bold;
  color: black;
}

.mappy-layer-setting {
  margin-bottom: 12px;
}

</style>

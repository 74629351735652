<template>
  <div class="inputTitle">
    <span>{{ translate('data->'+filterName) }}</span>
  </div>
  <DatePicker
    v-model="date"
    range
    model-type="yyyy-MM-dd"
    :teleport="true"
    position="center"
    :enable-time-picker="false"
    @update:model-value="handleDate"
    @open="emit('update-size')"
    @closed="emit('update-size')"
  />
</template>

<script setup>
import {ref, defineProps, watch, defineEmits, onMounted} from "vue"
import DatePicker from "@vuepic/vue-datepicker"
import "@vuepic/vue-datepicker/dist/main.css"
import {useI18nStore} from "@/store/localeStore"

const props = defineProps({
  filterName: {
    type: String,
    required: false,
    default: "Stop start time"
  }
})
const emit = defineEmits(["update-size", "date-update"])
const i18nStore = useI18nStore()
const translate = (key) => {
  return i18nStore.getTranslation(key)
}
const date = ref()
const currentDate = new Date() // Today
const get9DaysAgoDate = () => {
  if (process.env.VUE_APP_API_CLIENT !== "prod") {
    return "2021-10-22"
  }
  currentDate.setDate(currentDate.getDate() - 9) // Subtract 8 days

  const year = currentDate.getFullYear()
  const month = String(currentDate.getMonth() + 1).padStart(2, "0") // Months are 0-indexed
  const day = String(currentDate.getDate()).padStart(2, "0")

  return `${year}-${month}-${day}`
}

const today = () => {
  if (process.env.VUE_APP_API_CLIENT !== "prod") {
    return "2021-10-30"
  }
  currentDate.setDate(currentDate.getDate())

  const year = currentDate.getFullYear()
  const month = String(currentDate.getMonth() + 1).padStart(2, "0") // Months are 0-indexed
  const day = String(currentDate.getDate()).padStart(2, "0")

  return `${year}-${month}-${day}`
}

onMounted(() => {
  date.value = [get9DaysAgoDate(), today()]
})

const handleDate = (dateVal) => {
  emit("date-update", dateVal)
}

</script>

<style scoped>
.inputTitle {
  font-size: small;
  justify-content: center;
  font-weight: bold;
  width: 100%;
  display: inline-block;
  margin-bottom: 4px;
}
</style>
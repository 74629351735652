<script setup>
import { defineProps, defineEmits, ref } from "vue"
import {onClickOutside} from "@vueuse/core"

const props = defineProps({
  isOpen: Boolean
})

const emit = defineEmits(["modal-close"])

const target = ref(null)
onClickOutside(target, ()=>emit("modal-close"))

</script>

<template>
  <div
    v-show="isOpen"
    class="modal-mask"
  >
    <div
      class="modal-wrapper"
    >
      <div
        ref="target"
        class="modal-container"
      >
        <div class="modal-header">
          <v-btn
            variant="outlined"
            class="close-modal"
            icon
            @click.stop="emit('modal-close')"
          >
            <tds-icon
              size="15px"
              name="cross"
            />
          </v-btn>
        </div>
        <div class="modal-body">
          <slot name="content" />
        </div>
        <div class="modal-footer">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    45deg,
    rgba(255, 0, 255, 0.8),
    rgba(102, 51, 153, 0.95),
    rgba(30, 144, 255, 0.95),
    rgba(102, 51, 153, 0.95),
    rgba(255, 0, 255, 0.8)
  );
}

.close-modal {
  float: right;
  background-color: white;
}
.modal-header {
  width: 100%;
  padding: 8px;
}
.modal-body {
  padding: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

</style>
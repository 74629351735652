<script setup>
import { useMap } from "@/composables/index.js"
import {computed, unref, ref, nextTick, inject} from "vue"
import * as fl_data from "@flowmap.gl/data"
import {useSetStore} from "@/store/setStore"
import {useMainStore} from "@/store/mainStore"

const props = defineProps({
  layerName: {
    type: String,
    required: true
  },
  keyAsString: {
    type: String,
    required: true
  },
  layerDataForDomain: {
    type: String,
    required: true
  },
  accessorKeyForDataFunction: {
    type: String,
    required: true
  },
  namedModule: {
    type: String,
    required: true
  },
  colourSchemeName: {
    type: String,
    required: true
  }
})


const useCustomStore = inject(props.namedModule)
const dataStore = useSetStore()
const mainStore = useMainStore()
const { map } = useMap()

const domain = ref(dataStore.getAggregate(props.accessorKeyForDataFunction))
const colourScheme = ref(props.colourSchemeName)
const selectedAttribute = ref(props.keyAsString)
const colourOptions = ref(fl_data.COLOR_SCHEME_KEYS)
const changeColorAttribute = ref(false)
const changeColorSchema = ref(false)

const layerIsVisible = computed(() => {
  if (unref(map)) {
    if (typeof unref(map).getLayer(props.layerName) !== "undefined") {
      return unref(map).getLayoutProperty(props.layerName, "visibility") === "visible"
    }
  }
  return false
})

const selectableFeaturesInLayer = () => {
  const numberRegex = /^-?\d+(\.\d+)?$/ // This regex matches integers and decimals

  return Object.keys(domain.value[0]).filter(feat => {
    // Check if the feature is not null and is numeric
    const value = domain.value[0][feat]
    return value !== null && (numberRegex.test(value.toString()) || !isNaN(value))
  })
}

function handleSelectOption (event) {
  const indexInArray = props.layerName.split("_").pop()
  useCustomStore.setMapLayerProp("Trip", indexInArray,  "colourKey", event.detail.value)

  selectedAttribute.value = event.detail.value
  triggerMap()
}

const defaultFeature = selectableFeaturesInLayer().filter((feat) => feat.startsWith(props.keyAsString))
const defaultColorScheme = colourOptions.value.filter((feat) => feat.startsWith(colourScheme.value))[0]

function handleSchemeOption (event) {
  const indexInArray = props.layerName.split("_").pop()
  useCustomStore.setMapLayerProp("Trip", indexInArray,  "colourSchemeName", event.detail.value)

  colourScheme.value = event.detail.value
  triggerMap()
}

const triggerMap = () => {
  nextTick(() => {
    unref(map).fire("decklayercolorstyle",
        {
          layerName: props.layerName,
          colorPropKey: selectedAttribute.value,
          colourScheme: colourScheme.value
        }
    )
  })
}
</script>

<template>
  <tds-block
    class="tds-block-card"
  >
    <h6 class="tds-headline-06">
      {{ layerName }}
    </h6>
    <div
      v-if="layerIsVisible"
      class="layerDescription"
    >
      <div class="inputWrapper">
        <i
          class="layerNameColorProp"
          @click="changeColorAttribute = !changeColorAttribute"
        >
          Colour based on:
        </i>
        <i
          class="layerNameColorPropValue"
          @click="changeColorAttribute = !changeColorAttribute"
        >
          <b>{{ selectedAttribute }}</b>
        </i>
        <!-- eslint-disable vue/v-on-event-hyphenation -->
        <tds-dropdown
          v-if="changeColorAttribute"
          size="sm"
          open-direction="auto"
          :placeholder="selectedAttribute"
          :default-option="defaultFeature"
          @tdsChange="handleSelectOption"
        >
          <tds-dropdown-option
            v-for="(option, index) in selectableFeaturesInLayer()"
            :key="index + '_' + option"
            :value="option"
          >
            {{ option }}
          </tds-dropdown-option>
        </tds-dropdown>
      </div>
      <div class="inputWrapper">
        <i
          class="layerNameColorProp"
          @click="changeColorSchema = !changeColorSchema"
        >
          Flow colour:
        </i>
        <i
          class="layerNameColorPropValue"
          @click="changeColorSchema = !changeColorSchema"
        >
          <b>{{ colourScheme }}</b>
        </i>
        <!-- eslint-disable vue/v-on-event-hyphenation -->
        <tds-dropdown
          v-if="changeColorSchema"
          size="sm"
          open-direction="auto"
          :placeholder="colourScheme"
          :default-option="defaultColorScheme"
          @tdsChange="handleSchemeOption"
        >
          <tds-dropdown-option
            v-for="(option, index) in colourOptions"
            :key="index + '_' + option"
            :value="option"
          >
            {{ option }}
          </tds-dropdown-option>
        </tds-dropdown>
      </div>
    </div>
    <div
      v-if="!layerIsVisible"
      class="layerDescription"
    >
      <h6>
        is not visible on the map.
      </h6>
    </div>
  </tds-block>
</template>

<style scoped>
.tds-block-card {
  color: black;
  padding-bottom: 1px;
}

.layerNameColorProp {
  font-style: italic;
  font-size: 10px;
  text-decoration: underline;
}
.layerNameColorPropValue {
  font-style: italic;
  font-size: 10px;
  text-decoration: none;
}


.layerDescription {
  align-self: flex-start;
  width: 100%;
}

.inputWrapper {
  cursor: pointer;
}
</style>

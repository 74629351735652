<template>
  <draggable-resizable-vue
    ref="draggableChart"
    v-model:x="element.x"
    v-model:y="element.y"
    v-model:h="element.height"
    v-model:w="element.width"
    v-model:active="element.isActive"
    handles-type="handles"
    class="draggable-chart"
  >
    <slot /> <!-- Slot for the chart content -->
  </draggable-resizable-vue>
</template>

<script setup>
import {ref, defineProps} from "vue"
import DraggableResizableVue from "draggable-resizable-vue3"

const props = defineProps({
  initialX: {
    type: Number,
    default: 0
  },
  initialY: {
    type: Number,
    default: 0
  },
  width: {
    type: Number,
    default: 300
  },
  height: {
    type: Number,
    default: 200
  }
})

const element = ref({
  x: props.initialX || 0,
  y: props.initialY || 0,
  width: props.width || 350, // Default width
  height: props.height || 180, // Default height
  isActive: false
})

</script>

<style scoped>
.draggable-chart {
  position: fixed;
  display: flex;
  flex-direction: row;
}
</style>

import * as d3 from "d3"

export const boxplotyaxis = {
  mounted (el, binding) {
    const { scale } = binding.value

    const yFormatter = (num, digits) => {
      const lookup = [
        { value: 0, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
      ]
      const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
      let item = lookup.slice().reverse().find(function(item) {
        return num >= item.value
      })
      return item ? (num).toFixed(digits).replace(rx, "$1") + item.symbol : "0"
    }

    d3.select(el).transition().duration(500)
      .call(d3.axisLeft()
        .scale(scale)
        .tickFormat((interval, i) => {
          return yFormatter(interval, 1)
        })
      )
  }
}

<script setup>
// Imports
import axios from "axios"
import axiosRetry from "axios-retry"
import { ref, computed, onUnmounted, defineEmits } from "vue"
import { useMainStore } from "@/store/mainStore"
import { getSessionId, log } from "@/plugin/logger.js"
import { authService } from "@/services/AuthService"
import ResponseInfo from "@/components/ResponseInfo.vue"
import { workerManager } from "@/services/workerManager"
import {useFleetStore} from "@/store/fleet/fleetStore"
import {useSetStore} from "@/store/setStore"

// Component emits definition
const emit = defineEmits([
  "handle-data-ready",
  "handle-on-data-loaded",
  "handle-on-view-selection",
  "handle-button",
  "handle-on-data-response",
  "predict-query-time"
])

// Pinia store access
const mainStore = useMainStore()
const fleetStore = useFleetStore()
const dataStore = useSetStore()

// Component props definition
const props = defineProps({
  receivedStops: {
    type: Boolean,
    required: false,
    default: false
  },
  stopCount: {
    type: Number,
    required: false,
    default: 0
  },
  minTime: {
    type: String,
    required: false,
    default: "0"
  },
  maxTime: {
    type: String,
    required: false,
    default: "0"
  },
  numberOfChassisRequested: {
    type: Number,
    required: false,
    default: 0
  },
  numberOfChassisInSet: {
    type: Number,
    required: false,
    default: 0
  },
  tabName: {
    type: String,
    required: false,
    default: "Choose chassis"
  },
  setName: {
    type: String,
    required: false,
    default: ""
  },
  postEndpoint: {
    type: String,
    required: true
  },
  lambdaPath: {
    type: String,
    default: "/Prod/PostSnowflakeQueryCache"
  },
  tabValue: {
    type: String,
    required: true
  }
})

// Constants for API endpoints
const baseEndpoint = mainStore.getAppConfigFlag("useStagingStack")
    ? process.env.VUE_APP_QPGW_ENDPOINT_B
    : process.env.VUE_APP_QPGW_ENDPOINT

const dataFetchUrl = mainStore.getAppConfigFlag("useStagingStack")
    ? process.env.VUE_APP_GRAPH_ENDPOINT_B
    : process.env.VUE_APP_GRAPH_ENDPOINT

// Refs and variables for component state
const showOptions = ref(true)
const chassis = ref("")
const customSetName = ref("")
const errorMessage = ref("")
const message = ref("")
const showInfo = ref(false)
const isGDPRChecked = ref(false)
const button = ref({
  info: "Fetch",
  isFetchingDataOnRequest: false,
  state: "btn-success"
})
const lambdaPath = ref(props.lambdaPath)

// Validation rules for input fields
const rules = ref({
  required: value => !!value || "Required.",
  setName: value => {
    const pattern = "(^[a-zA-Z0-9_\\-]{2,1024}$)"
    return pattern.test(value) || "Invalid file name."
  },
  email: value => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return pattern.test(value) || "Invalid e-mail."
  },
  chassis: value => {
    return allChassisValid() || "All chassis number should be 7 characters long & total number of chassis must be less than 1156."
  }
})

// Computed properties
const isTileSetNameValid = computed(() => {
  return isDataSetNameValid(customSetName.value)
})

// API configurations for workers
const apiConfigurations = [
  { workerName: "minMaxValuesAPI", sqlFilename: "MIN_MAX_RANGES", message: "min_max_ranges was requested" },
  { workerName: "hexesAPI", sqlFilename: "HEXAGONS", message: "Hexagons was requested" },
  { workerName: "edgesAPI", sqlFilename: "EDGES", message: "edges was requested" },
  { workerName: "dailyGraphAggregateAPI", sqlFilename: "DAILY_AGGREGATES", message: "daily_aggregates was requested" },
  { workerName: "odometerBoxplotAPI", sqlFilename: "ODOMETER_BOXPLOT", message: "odometer_boxplot was requested" },
  { workerName: "fuelBoxplotAPI", sqlFilename: "FUEL_BOXPLOT", message: "fuel_boxplot was requested" },
  { workerName: "dailyFuelBoxplotAPI", sqlFilename: "DAILY_FUEL_BOXPLOT", message: "daily_fuel_boxplot was requested" },
  { workerName: "dailyOdometerBoxplotAPI", sqlFilename: "DAILY_ODOMETER_BOXPLOT", message: "daily_odometer_boxplot was requested" },
  { workerName: "hourlyLocationDistributionAPI", sqlFilename: "HOURLY_ONGOING_STOPS", message: "hourly_ongoing_stops was requested" },
  { workerName: "dailyHourlyLocationDistributionAPI", sqlFilename: "DAILY_HOURLY_ONGOING_STOPS", message: "daily_hourly_ongoing_stops was requested" }
]

// Functions for API communication and workers
const postMessageToAPI = (API, token, endpoint, queryParams, logMessage) => {
  log("info", logMessage)
  API.postMessage({
    method: "getSweetEDataFromSnowflake",
    args: [ token, endpoint, dataFetchUrl, queryParams ]
  })
}

const postMessageToStopAPI = (workerName, token, queryParams, apiMethod, logMessage) => {
  log("info", logMessage)
  const worker = workerManager.getWorker(workerName)

  const params = {
    sqlfilename: "STOPS",
    ...queryParams,
    chassis: getChassisString()
  }

  worker.postMessage({
    method: "setSweetEFleetInSnowflake",
    args: [ token, dataFetchUrl, params, apiMethod ]
  })
}

// Function to build request parameters for API calls
const buildRequestParams = (sqlFilename, queryParams) => {
  return {
    sqlfilename: sqlFilename,
    stopqueryid: dataStore.getQueryIdFor("rawStops"),
    ...queryParams,
    chassis: getChassisString()
  }
}

// Function to initiate fetching data from various SweetE APIs
const fetchData = (path) => {
  const token = mainStore.getBearerToken
  const queryParams = fleetStore.getSelectedParams
  let endpoint = dataFetchUrl

  endpoint += path
  apiConfigurations.forEach(apiConfig => {
    const worker = workerManager.getWorker(apiConfig.workerName)
    postMessageToAPI(
        worker,
        token,
        endpoint,
        buildRequestParams(apiConfig.sqlFilename, queryParams),
        apiConfig.message
    )
  })
}

const handleDataReady = (event, aggregateName, logMessage) => {
  log("info", logMessage)
  fleetStore.setAggregate(aggregateName, event.data.value.result)
  dataStore.setAggregate(aggregateName,event.data.value.result)
  emit("handle-data-ready", aggregateName)
}

// Helper functions
// Function to get a comma-separated string of chassis
const getChassisString = () => {
  return chassis.value.split(/[\s,]+/).join(",")
}

// Function to check if all chassis are valid
const isChassisValid = () => {
  return allChassisValid()
}

const allChassisValid = () => {
  if (getChassisString().length > 0 && getChassisString().length <= 9248) {
    // Regular expression to match exactly 7 digits
    const regExp = new RegExp("^\\d{7}$")
    const result = chassis.value.trim().split(/[\s,]+/).map(chassi => {
      return regExp.test(chassi)
    })
    return result.every(isValid => isValid)
  } else {
    return false
  }
}

const isMarketUser = () => {
  return mainStore.getAppConfigFlag("isAdmin", authService.getUserRoles(), authService.getUserEmail())
}

const isDataSetNameValid = () => {
  if (customSetName.value.length > 0) {
    return /^[a-zA-Z0-9_\\-]{2,1024}$/.test(customSetName.value)
  }
  return true
}

const isChecked = () => {
  isGDPRChecked.value = !isGDPRChecked.value
  const logMessage = isGDPRChecked.value
      ? "{\"isGDPRchecked\":\"Non Disclosure Agreement accepted\"}"
      : "{\"isGDPRchecked\":\"Non Disclosure Agreement not accepted\"}"
  log("info", logMessage)
}

// Data fetching and processing functions
async function postSet() {
  const startTime = Date.now()
  log("info", "PostSet starting")
  emit("handle-on-view-selection")

  try {
    registerListeners()
    setTilesData()
    await postQueryWithChassisData()
    await createCacheInSnowflake()
    finalizePostSet(startTime)
  } catch (error) {
    logError(error)
  }
}

function setTilesData() {
  fleetStore.setTilesConstantMaxTime(new Date())
  fleetStore.setTilesChassis(chassis.value.split(/[\r\n]+/).join(","))
  log("info", `{"postSet":"Requested chassis: ${chassis.value}"}`)
}

function finalizePostSet(startTime) {
  const endTime = Date.now()
  log("info", `{"postSet":{"message":"requestChassisAxios POST & GET is done","time":"${endTime - startTime}"}}`)
  emit("handle-button", button.value)
  showInfo.value = true
  emit("handle-on-data-loaded")
}

async function requestDataSetAggregate (url, bucketPath) {
  const axiosClient = axios.create()
  const retryCondition = (error) => {
    return (
        axiosRetry.isNetworkOrIdempotentRequestError(error) || error.response.status === 404
    )
  }
  axiosRetry(axiosClient, { retries: 200, retryDelay: axiosRetry.exponentialDelay, retryCondition })

  return axiosClient
      .get(url, {
        headers: {
          "Authorization": mainStore.getBearerToken,
          "aggregatekey": bucketPath
        }
      })
}

async function handleGettingAggregatesForChassisResponse(data) {
  let bucketPath = data.bucket_path + "/meta_data.json"
  const url = baseEndpoint + "/Prod/GetAggregate"
  const res = await requestDataSetAggregate(url, bucketPath)

  let itemCount = res.data["item_count"]
  if (itemCount === 0) {
    receivedStops.value = false
    message.value = res.data["message"]
    button.value = { info: "Fetch", isFetchingDataOnRequest: false, state: "btn-success" }
    throw new Error("Empty result")
  } else {
    emit("handle-on-data-response", res.data)
    emit("predict-query-time")
  }
}

async function postQueryWithChassisData() {
  try {
    button.value.isFetchingDataOnRequest = true
    const url = baseEndpoint + props.postEndpoint
    const headers = {
      user: authService.getADUser(),
      setname: customSetName.value,
      Authorization: mainStore.getBearerToken
    }
    const params = {
      chassis: getChassisString(),
      sessionid: getSessionId()
    }

    const response = await axios.post(url, {}, { headers, params })
    log("info", "{\"postSet\":\"Post request completed\"}")

    await handleGettingAggregatesForChassisResponse(response.data)
    return response.data
  } catch (error) {
    logError(error)
    throw error
  }
}

async function createCacheInSnowflake() {
  const token = mainStore.getBearerToken
  const queryParams = fleetStore.getSelectedParams

  postMessageToStopAPI(
      "rawStopsAPI",
      token,
      queryParams,
      lambdaPath.value,
      "STOPS query initiated!"
  )
}

const handleWorkerResponse = (event, path) => {
  if (event.data.key === "dataReady") {
    handleDataReady(event, event.data.value.requested, `${event.data.value.requested} is fetched!`)
  }
  if (event.data.key === "queryStatusDone") {
    dataStore.setQueryIdFor({ queryFor: "rawStops", queryId: event.data.value })
    fetchData(path)
  }
}

const registerListeners = () => {
  workerManager.registerListener("rawStopsAPI", (event) => handleWorkerResponse(event, lambdaPath.value))
  apiConfigurations.forEach(config => {
    workerManager.registerListener(config.workerName, (event) => handleWorkerResponse(event, lambdaPath.value))
  })
}

const deregisterListeners = () => {
  workerManager.deregisterListener("rawStopsAPI")
  apiConfigurations.forEach(config => {
    workerManager.deregisterListener(config.workerName)
  })
}

onUnmounted(() => {
  deregisterListeners()
})
// Error handling
function logError(error) {
  log("error", `{"Error occurred: ${error.message}"}`)
}

</script>

<template>
  <v-window-item :value="tabValue">
    <div v-if="!isMarketUser">
      <v-alert
        :show="!isGDPRChecked"
        color="pink"
        variant="elevated"
      >
        <h5>Non-Disclosure Agreement</h5>
        <div class="form-check">
          <input
            id="isGDPRChecked"
            class="form-check-input"
            data-toggle="tooltip"
            data-placement="top"
            title="Click to agree"
            type="checkbox"
            @click="isChecked"
          >
          <label
            class="form-check-label"
            for="isGDPRChecked"
          >
            <p>Position information is GDPR/ISec sensitive, it is not allowed to share a customer positions with another.
              Interaction is being logged and will be reviewed in case of an incident.</p>
          </label>
        </div>
      </v-alert>
    </div>
    <div v-if="(isGDPRChecked || isMarketUser) && showOptions">
      <v-textarea
        v-show="!showInfo"
        id="chassisInput"
        v-model="chassis"
        placeholder="Input one chassis per line.."
        row="3"
        max-rows="12"
        :rules="[rules.required, rules.chassis]"
      />
      <v-text-field
        v-show="!showInfo"
        id="customSetNameInputField"
        v-model="customSetName"
        placeholder="Optionally give your dataset a name.."
        :rules="['', rules.setName]"
      />
      <v-card-actions>
        <v-spacer />
        <v-btn
          id="create-new-tile-set-for-chassis"
          :class="button.state"
          :disabled="!isChassisValid || !isTileSetNameValid ||
            button.isFetchingDataOnRequest || chassis.length <= 0"
          @click="postSet"
        >
          {{ button.info }}
        </v-btn>
        <v-progress-circular
          v-if="button.isFetchingDataOnRequest &&
            button.state === 'btn-success'"
          indeterminate
          color="green"
        />
      </v-card-actions>
      <div
        v-if="showInfo"
        id="responseInfo"
      >
        <ResponseInfo
          :received-stops="receivedStops"
          :stop-count="stopCount"
          :min-time="minTime"
          :max-time="maxTime"
          :number-of-chassis-requested="numberOfChassisRequested"
          :number-of-chassis-in-set="numberOfChassisInSet"
          :set-name="setName"
          :error-message="errorMessage"
          :message="message"
        />
        <div v-show="!receivedStops">
          <p> {{ message }} </p>
        </div>
      </div>
    </div>
  </v-window-item>
</template>

<style scoped>
#customSetNameInputField {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

#isGDPRChecked {
  height: 18px;
  width: 18px;
  margin-top: 0.1rem;
  margin-left: -1rem;
}

#responseInfo {
  padding-top: 40px;
}
</style>

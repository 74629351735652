<script setup>
import mapboxgl from "mapbox-gl"
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css"
import {ref, onMounted,  provide,  onBeforeUnmount} from "vue"
import {useEventsBinding, useMap} from "@/composables"
import MapboxDraw from "@mapbox/mapbox-gl-draw"
import {useI18nStore} from "@/store/localeStore"

if (!mapboxgl) {
  throw new Error("mapboxgl is not installed.")
}

// eslint-disable-next-line vue/valid-define-emits
const emit = defineEmits()

const { map } = useMap()
provide("mapbox-map", map)


useEventsBinding(emit, map)

const draw = ref(new MapboxDraw({
  controls: {
    polygon: true,
    trash: true
  },
  displayControlsDefault: false,
  boxSelect: false
}))
const i18nStore = useI18nStore()
const translate = (key) => {
  return i18nStore.getTranslation(key)
}

const updateArea = () => {
  const mode = draw.value.getMode()

  //if a user drew a polygon on the map
  if (mode === "draw_polygon") {
    const data = draw.value.getAll()
    map.value.fire("draw.updated", data)
  }

  //if a user added a marker on the map
  else if (mode === "draw_point") {
    const data = draw.value.getAll()
    console.log(data)
  }

  //if a user selected an item they already drew or added on the map
  else if (mode === "simple_select") {
    const data = draw.value.getAll()
    map.value.fire("draw.updated", data)
  }

  //if a user selected an item they drew on the map
  else if (mode === "direct_select") {
    const data = draw.value.getAll()
    map.value.fire("draw.updated", data)
  }

  else {
    const data = draw.value.getAll()
    console.log(data)
  }
}


onMounted(() => {
  map.value.on("draw.create", updateArea)
  map.value.on("draw.delete", updateArea)
  map.value.on("draw.update", updateArea)
  document.getElementById("multiSelectPolygon").appendChild(draw.value.onAdd(map.value))
})

 onBeforeUnmount(() => {
   map.value.removeControl(draw.value)
   map.value.off("draw.create", updateArea)
   map.value.off("draw.delete", updateArea)
   map.value.off("draw.update", updateArea)
 })


</script>

<template>
  <tds-block
    class="tds-block"
  >
    <h6 class="tds-headline-06">
      {{ translate("data->Select locations by drawing a polygon") }}
    </h6>
    <div
      id="multiSelectPolygon"
      class="multiSelectTool"
    />
  </tds-block>
</template>

<style scoped>
.tds-block {
  color: black;
  padding-bottom: 1px;
}

.multiSelectTool {
  align-self: flex-end;
  position: relative;
  display: flex;
  place-content: center;
  rotate:270deg!important;
}

</style>
<style>
.mapbox-gl-draw_trash{
  rotate:90deg!important;
}
</style>

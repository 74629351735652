<script setup>
import { log } from "@/plugin/logger"
</script>

<template>
  <div>
    <h1>Register New User</h1>
    <form @submit.prevent="registerUser">
      <div>
        <label for="email">Email : </label>
        <input
          v-model="userEmail"
          type="email"
          required
        >
      </div>
      <div>
        <label for="telephone">Phone : </label>
        <input
          v-model="userNumber"
          type="text"
        >
      </div>
      <div>
        <label for="name">Name : </label>
        <input
          v-model="userName"
          type="text"
          required
        >
      </div>
      <button
        type="submit"
        class="cta-button"
      >
        Register
      </button>
    </form>
    <p v-if="message">
      {{ message }}
    </p>
  </div>
</template>

<script>

export default {
  data() {
    return {
      userEmail: "",
      userNumber: "",
      userName: "",
      message: ""
    }
  },
  methods: {
    async registerUser() {
      log("info", "New user -> Email:" + this.userEmail + " Phonenumber:" + this.userNumber + " Name:" + this.userName)
      this.message = "Thank you for registering! We will contact you soon."
    }
  }
}
</script>

<style scoped>
.cta-button {
  background-color: deeppink;
  color: white;
  padding: 10px 20px;
  font-size: 18px;
  text-decoration: none;
  font-family: "Scania Sans", Arial, Helvetica, sans-serif;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin-bottom: 20px;
}
/* General styles for the form container */
div {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: transparent;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}


/* Form styles */
form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}


/* Input fields styling */
input[type="email"],
input[type="text"] {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease;
  color: white;
}

input[type="email"]:focus,
input[type="text"]:focus {
  border-color: deeppink;
}

/* Submit button styling */
button.cta-button {
  background-color: deeppink;
  color: white;
  font-size: 16px;
  padding: 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Scania Sans", Arial, Helvetica, sans-serif;
  transition: background-color 0.3s ease;
}

button.cta-button:hover {
  background-color: #46b8da;
}

/* Message paragraph styling */
p {
  color: deeppink;
  text-align: center;
  font-size: 16px;
  margin-top: 15px;
}

/* Responsive design for small screens */
@media (max-width: 600px) {
  div {
    padding: 15px;
    max-width: 100%;
  }

  h1 {
    font-size: 20px;
  }

  input[type="email"],
  input[type="text"],
  button.cta-button {
    font-size: 14px;
  }
}

</style>


<style scoped>

</style>
<script setup>
import {ref, watch, computed, onMounted, inject} from "vue"
import { Splitpanes, Pane } from "splitpanes"
import "splitpanes/dist/splitpanes.css"
import { log } from "@/plugin/logger"
import syncMaps from "@mapbox/mapbox-gl-sync-move"
import MapStyleChange from "@/components/MapStyleChange.vue"
import { useRouter } from "vue-router"
import { authService } from "@/services/AuthService"
import SelectionComponent from "@/components/SelectionComponent.vue"
import FleetMapView from "@/views/FleetMapView.vue"
import { useMainStore } from "@/store/mainStore"
import { useFleetStore } from "@/store/fleet/fleetStore"

const mainStore = useMainStore()
const fleetStore = useFleetStore()

const loadWithStops = ref(false)
const loadWithArcs = ref(false)
const loadWithLocations = ref(false)

const panesNumber = ref(1)
const syncedMaps = ref()
const baseMaps = ref([])
const router = useRouter()

const baseMapStyles = computed(() => {
  return mainStore.getBasemapStyles
})

function addNewBaseMap (baseMap) {
  baseMaps.value.push(baseMap)
}

function removeBaseMap () {
  baseMaps.value = baseMaps.value.slice(0, baseMaps.value.length - 1)
}

const HORequiredRole = [process.env.VUE_APP_AZUREAD_REQUIRED_ROLE]
const isAuthorized = !!(authService.hasADRoles(HORequiredRole) && authService.getADUser())

const dataLoaded = (dataReady) => {
  if (dataReady === "HEXAGONS") {
    loadWithLocations.value = true
  } else if (dataReady === "EDGES") {
    loadWithArcs.value = true
  } else if (dataReady === "STOPS") {
    loadWithStops.value = true
  }
}

onMounted(() => {
  log("info", "Initiated the 'FleetView' component.")
})

watch(
    () => baseMaps.value.length,
    () => {
      if (baseMaps.value.length > 1) {
        if (typeof syncedMaps.value === "function") {
          syncedMaps.value()
        }
        syncedMaps.value = syncMaps(baseMaps.value)
      }
    }
)

</script>

<template>
  <slot v-if="isAuthorized">
    <selection-component @handle-on-data-loaded="dataLoaded" />
    <map-style-change :styles="baseMapStyles" />
    <splitpanes
      vertical
      class="mappy-map-main default-theme"
    >
      <pane
        min-size="20"
        size="65"
      >
        <fleet-map-view
          map-module-state-name="sweete_mainMap"
          :load-with-stops="loadWithStops"
          :load-with-arcs="loadWithArcs"
          :load-with-locations="loadWithLocations"
          @base-map-updated="addNewBaseMap"
        />
      </pane>
      <pane>
        <splitpanes
          id="splitter"
          horizontal
        >
          <pane
            v-for="i in panesNumber"
            :key="i + '_map_app_pane'"
          >
            <fleet-map-view
              :map-module-state-name="'sweete_' + i"
              @base-map-updated="addNewBaseMap"
              @base-map-removed="removeBaseMap"
            />
          </pane>
        </splitpanes>
        <div class="sheets_container">
          <div class="sheets_border" />
          <div class="sheets">
            <button
              id="sheet-button-add"
              class="sheet-button"
              @click="panesNumber++"
            >
              +
            </button>
            <button
              v-if="panesNumber > 1"
              id="sheet-button-remove"
              class="sheet-button"
              @click="panesNumber--"
            >
              -
            </button>
          </div>
        </div>
      </pane>
    </splitpanes>
  </slot>
</template>

<style>
body {
  overflow: hidden;
}

body * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mappy-map-main {
  margin-left: 60px;
  width: 100%;
  height: 100%;
}

#splitter {
  height: 98vh;
}

.sheets_container {
  width: 100%;
  height: 2vh;
  background-color: #041e41;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
}

.sheets_border {
  z-index: 9;
  position: absolute;
  bottom: 1px;
  left: 0;
  right: 0;
  background-color: #e8008a;
  height: 220px;
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  clip-path: polygon(100% 100%, 100% 0, 100% 100%, 50% 90%, 0 100%);
  transition: transform 0.4s ease;
}

.sheets {
  z-index: 10;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #041e41;
  height: 200px;
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  clip-path: polygon(100% 100%, 100% 0, 100% 100%, 50% 90%, 0 100%);
  transition: transform 0.4s ease;
}

.sheets:hover {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); /* Adjust the scale factor as desired */
  height: 25px;
  transform: scale(1);
}

.sheet-button {
  background-color: #e8008a;
  color: #e39cab;
  border-radius: 50%;
  cursor: pointer;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border: none;
  transition: transform 0.3s ease;
  box-shadow: 0 8px 15px rgba(1, 1, 1, 0.1);
  font-size: 16px;
  text-align: center;
  outline: none;
  align-self: center;
  font-weight: bolder;
}

.sheet-button:hover {
  transform: scale(1.1);
}

.splitpanes__pane {
  display: flex;
  position: relative;
}

.splitpanes__splitter {
  border-left: 1px solid #e39cab;
  background-color: #e39cab;
}

.notifications {
  max-height: 15vh;
  overflow-x: hidden;
  overflow-y: scroll;
  position: absolute;
  left: 8px;
  top: 0;
  z-index: 6;
  padding-left: 0px;
  padding-top: 2px;
}

</style>

import { defineStore } from "pinia"

export const useMainStore = defineStore({
  id: "initStore",
  state: () => ({
    bearerToken: null,
    userRoles: [],
    keyCloak: null,
    appConfigFeatureFlags: null,
    isNotificationClosed: false,
    basemapStyles: [
      "connected-intelligence/clnlg04gh003a01qwbcylgame",
      "mapbox/satellite-streets-v12",
      "mapbox/light-v11",
      "mapbox/outdoors-v12",
      "mapbox/navigation-night-v1"
    ],
    selectedBaseMap: "connected-intelligence/clnlg04gh003a01qwbcylgame"
  }),
  getters: {
    getBearerToken: (state) => `Bearer ${state.bearerToken}`,
    getAppConfigFeatureFlags: (state) => state.appConfigFeatureFlags,
    getAppConfigFlag: (state) => (flagName, groupRoles = [], email = "") => {
      if (state.appConfigFeatureFlags === null || Object.keys(state.appConfigFeatureFlags).length === 0) {
        return false
      }

      try {
        let user = "none"
        let group = "none"
        let enabled = false

        for (const obj of state.appConfigFeatureFlags) {
          if (obj.hasOwnProperty(flagName)) {
            enabled = obj[flagName].isEnabled

            if (obj[flagName].hasOwnProperty("userGroups")) {
              // Only check groups if groupRoles is not empty
              group = groupRoles.length > 0 && obj[flagName].userGroups.split(",").some(r => groupRoles.includes(r))
            }

            if (obj[flagName].hasOwnProperty("userIDs")) {
              // Only check user if email is not empty
              user = email !== "" && obj[flagName].userIDs.includes(email)
            }
          }
        }

        // Check combinations of user and group
        if (user === "none" && group === "none") {
          return enabled
        }
        if (user === "none") {
          return enabled && group
        }
        if (group === "none") {
          return enabled && user
        }
        return enabled && (user || group)
      } catch(err) {
        return false
      }
    },
    getNotificationFlag: (state) => state.isNotificationClosed,
    getSelectedBaseMap: (state) => state.selectedBaseMap,
    getBasemapStyles: (state) => state.basemapStyles
  },
  actions: {
    setBearerToken(bearerToken) {
      this.bearerToken = bearerToken
    },
    setAppConfigFeatureFlags (appConfigFeatureFlags) {
      this.appConfigFeatureFlags = appConfigFeatureFlags
    },
    setNotificationFlag (flag) {
      this.isNotificationClosed = flag
    },
    setSelectedBaseMap (selectedBaseMap) {
      this.selectedBaseMap = selectedBaseMap
    }
  }
})

import * as d3 from "d3"

export const boxplotxaxis = {
  mounted (el, binding) {
    const { scale } = binding.value

    d3.select(el).transition().duration(500)
      .call(d3.axisBottom().scale(scale))
      .selectAll("text")
      .attr("transform", "rotate(90)")
      .attr("y", 0)
      .attr("x", 9)
      .attr("dy", ".35em")
      .style("text-anchor", "start")
  }
}

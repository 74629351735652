import * as d3 from "d3"
import {useI18nStore} from "@/store/localeStore"

const translations = {
  en: {
  "Sunday": "Sun",
  "Monday": "Mon",
  "Tuesday": "Tue",
  "Wednesday": "Wed",
  "Thursday": "Thu",
  "Friday": "Fri",
  "Saturday": "Sat"
  }
}

function translateDay(day, lang = "en") {
  return translations[lang][day] || day
}
export const barchartaxis = {
  mounted (el, binding) {
    const lang = "en"
    const i18nStore = useI18nStore()
    const { scale, tickLabels, direction, yTickFormat, subSampleXAxis } = binding.value

    // Adjust the tick format based on the new bandAxisTicks structure
    const tickFormat = direction === "vertical"
      ? d => {
        if (subSampleXAxis && d % 3 !== 0) return " "
        const day = tickLabels[scale.domain().indexOf(d)]
        return i18nStore.getTranslation("weekdays->"+day)
      }
      : yTickFormat

    if (direction === "vertical") {
      d3.select(el).transition().duration(500)
        .call(d3.axisBottom(scale).tickFormat(tickFormat))
        .selectAll("text")
        .attr("y", 10)
        .attr("x", 2)
        .style("text-anchor", "middle")
        .style("fill", "#4a4a4a") // Modern font color
        .style("font-family", "Arial, sans-serif") // Modern font family
        .style("font-size", "14px") // Font size
        .style("font-weight", "bold") // Bold text
    } else if (direction === "horizontal") {
      d3.select(el).transition().duration(500)
        .call(d3.axisLeft(scale).ticks().tickFormat(tickFormat))
    }
  }
}

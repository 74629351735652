<template>
  <HistogramSlider
    class="range-slider"
    :width="300"
    :drag-interval="true"
    :data="dataInMap"
    :min="dataMin"
    :max="dataMax"
    :prettify="prettifyData"
    :handle-size="18"
    primary-color="#ff0080"
    @finish="logDataOnFinish"
  />
</template>

<script setup>
import {computed, ref} from "vue"
import HistogramSlider from "vue3-histogram-slider"
import "vue3-histogram-slider/dist/histogram-slider.css"
import {useSetStore} from "@/store/setStore"

const props = defineProps({
  layer: {
    type: Object,
    required: true
  },
  rangeProp: {
    type: String,
    default: "sum_duration"
  },
  selectedIds: {
    type: Array,
    default: () => []
  }
})

const emit = defineEmits(["set-new-range"])
function logDataOnFinish (e) {
  emit("set-new-range", {layerName: layerName.value, min: Math.pow(e.from, 2), max: Math.pow(e.to, 2)})
}

const dataStore = useSetStore()
const layerName = ref(props.layer.accessorKey)

const dataInMap = computed(() => {
  const sortedData = dataStore.getAggregate(props.layer.accessorKey)

  return sortedData.map(d => Math.sqrt(parseFloat(d[props.rangeProp]))).sort((a, b) => a - b)
})

const dataMin = computed(() => {
  return dataInMap.value[0]
})

const dataMax = computed(() => {
  return dataInMap.value[dataInMap.value.length - 1]
})

const prettifyData = (d)  => {
  if (dataMax.value <= 60) return Math.floor(Math.pow(d, 2)/60)  + "min"
  else if (dataMax.value <= 86400) return Math.floor(Math.pow(d, 2)/60/60)  + "h"
  return Math.floor(Math.pow(d, 2)/60/60/24)  + "days"
}
</script>

<style scoped>
.range-slider {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 20px;
}
</style>
<style>
.overlay{
  cursor: none!important;
  pointer-events: none;
}
</style>
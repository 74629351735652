import * as d3 from "d3"

export const bandaxis = {
    mounted (el, binding) {
        const { scale, direction, yTickFormat, subSampleXAxis } = binding.value

        if (direction === "vertical") {
            d3.select(el).transition().duration(500)
                .call(
                    d3.axisBottom(scale)
                        .ticks()
                        .tickFormat((interval, i) => (subSampleXAxis && i % 3 !== 0 ? " " : interval))
                )
                .selectAll("text")
                .attr("transform", "rotate(-45)")
                .attr("y", 2)
                .attr("x", -9)
                .attr("class", "tickText")
                .style("text-anchor", "end")
        } else if (direction === "horizontal") {
            d3.select(el).transition().duration(500).call(d3.axisLeft(scale).ticks().tickFormat(yTickFormat))
        }
    }
}

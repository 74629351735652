import { defineStore } from "pinia"
import {generateGreenYellowRedColorScheme} from "@/composables"

const schemeGnYlRd = generateGreenYellowRedColorScheme(6)
export const useSetStore = defineStore("setStore", {
  state: () => ({
    mapData: {},
    graphData: {},
    queryIds: {
      edges: "",
      hexagons: "",
      rawStops: "",
      locationsCache: "",
      locations: "",
      locations7Days: "",
      neptuneDb: ""
    },
    queryStates: {
      edges: false,
      hexagons: false,
      rawStops: false,
      locationsCache: false,
      locations: false,
      locations7Days: false,
      neptuneDb: false
    },
    dataSetOptionsForMap: [
      { setName: "locations",
        accessorInState: "getAggregate",
        accessorKey: "locationAggregates",
        canBe: ["Hexagon"],
        visualisedAs: "",
        colourKey: "median_duration",
        colourSchema: schemeGnYlRd,
        colorSchemeName: "schemeGnYlRd",
        colourCount: 6,
        elevationKey: "no_stops",
        elevationScale: 1
      },
      { setName: "trip",
        accessorInState: "getAggregate",
        accessorKey: "edgeAggregates",
        canBe: ["Trip"],
        visualisedAs: "",
        colourKey: "median_trip_weight",
        colourSchemeName: "Magenta"
      }
    ]
  }),

  getters: {
    getAggregate: (state) => (aggregateName) => {
      // Check if state.mapData exists and is an object
      if (state.mapData && typeof state.mapData === "object") {
        if (aggregateName in state.mapData) {
          return state.mapData[aggregateName]
        } else {
          return false
        }
      } else {
        return false
      }
    },
    getDataSetOptionsForMap: (state) => {
      return state.dataSetOptionsForMap
    },
    getQueryIdFor: (state) => (queryIdFor) => {
      return state.queryIds[queryIdFor]
    },
    getQueryStateFor: (state) => (queryIdFor) => {
      return state.queryStates[queryIdFor]
    },
    getGraphData: (state) => (graphName) => {
      if (graphName in state.graphData) return state.graphData[graphName]
      return false
    }
  },

  actions: {
    setAggregate(aggregateName, aggValue) {
      this.mapData[aggregateName] = Object.freeze(aggValue)
    },
    resetAggregate(aggregateName) {
      // Reset the array to an empty, frozen array
      this.mapData[aggregateName] = Object.freeze([])
    },
    setQueryIdFor({ queryFor, queryId }) {
      this.queryIds[queryFor] = queryId
    },
    setQueryStatus({ queryFor, queryState }) {
      this.queryStates[queryFor] = queryState
    },
    setGraphData( { graphName, graphData }) {
      this.graphData[graphName] = graphData
    }
  }
})

import * as d3 from "d3"

export const linearaxis = {
    mounted (el, binding) {
        const scale = binding.value.scale
        const direction = binding.value.direction
        const barAxisLocation = binding.value.barAxisLocation
        const xTickFormat = binding.value.xTickFormat

        const yFormatter = (num, digits) => {
            const lookup = [
                { value: 1, symbol: "" },
                { value: 1e3, symbol: "k" },
                { value: 1e6, symbol: "M" },
                { value: 1e9, symbol: "G" },
                { value: 1e12, symbol: "T" },
                { value: 1e15, symbol: "P" },
                { value: 1e18, symbol: "E" }
            ]
            const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
            let item = lookup.slice().reverse().find(function (item) {
                return num >= item.value
            })
            return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0"
        }

        if (direction === "vertical") {
            d3.select(el)
                .transition()
                .duration(500)
                .call(d3.axisLeft(scale)
                    .ticks(5)
                    .tickFormat((interval) => yFormatter(interval, 1))
                )
              .selectAll("text")
              .attr("class", "tickText")
        } else if (direction === "horizontal") {
            const axisType = barAxisLocation === "bottom" ? d3.axisBottom : d3.axisTop
            d3.select(el)
                .transition()
                .duration(500)
                .call(axisType(scale)
                    .ticks(5)
                    .tickFormat(xTickFormat)
                )
        }
    }
}

import * as h3 from "h3-js"

export async function isoDistance(h3Index) {
  console.log(`Iso h3Index: "${h3Index}"`)
  const [lat, lon] = h3.cellToLatLng(h3Index)
  
  try {
    const response = await fetch(`https://api.mapbox.com/isochrone/v1/mapbox/driving/${lon}%2C${lat}?contours_meters=10000%2C20000%2C30000%2C40000&polygons=true&denoise=1&access_token=${process.env.VUE_APP_MAP_TOKEN}`)
    const data = await response.json()
    console.log(`${JSON.stringify(data)}`)

    if (data && data.features && data.features.length > 0) {
      return data
    } else {
      return null
    }
  } catch (error) {
    console.error("Iso distance failed:", error)
    return null
  }
}

// useDataFetcher.js
import { onUnmounted } from "vue"
import { workerManager } from "@/services/duckWorkerManager"
import { authService } from "@/services/AuthService"
import { log } from "@/plugin/logger"

export function useDataFetcher(apiConfigurationsRef, { onDataReceived, onError }) {
  const asBearerToken = (token) => `Bearer ${token}`
  const postMessageToDuckAPI = (worker, apiConfig) => {
    log("info", apiConfig.message)
    const token = asBearerToken(authService.keycloak.token)
    const customer = authService.keycloak.tokenParsed.customer.external_customer_reference
    const duckEndpoint = process.env.VUE_APP_DUCK_API_B

    worker.postMessage({
      method: "getResultsFromDuckDB",
      workerName: apiConfig.workerName,
      args: [token, duckEndpoint, {...apiConfig.filters}, { query_func: apiConfig.queryFunction, layer: "trips" }, [{ filename: apiConfig.filename, file_suffix: "snappy.parquet" }], { customer }]
    })
  }

  const handleWorkerResponse = (event) => {
    if (event.data.key === "done") {
      if (onDataReceived) {
        onDataReceived(event.data.value, event.data.value.workerName)
      }
    } else if (event.data.key === "error") {
      if (onError) {
        onError(event.data.value)
      }
    }
  }

  const fetchData = () => {
    apiConfigurationsRef.value.forEach(apiConfig => {
      const worker = workerManager.getWorker(apiConfig.workerName)
      postMessageToDuckAPI(worker, apiConfig)
    })
  }

  const registerListeners = () => {
    apiConfigurationsRef.value.forEach(config => {
      workerManager.registerListener(config.workerName, (event) => handleWorkerResponse(event))
    })
  }

  const deregisterListeners = () => {
    apiConfigurationsRef.value.forEach(config => {
      workerManager.deregisterListener(config.workerName)
    })
  }

  onUnmounted(deregisterListeners)

  return { postMessageToDuckAPI, fetchData, registerListeners, deregisterListeners }
}


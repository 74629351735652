<template>
  <div :id="id" />
</template>

<script setup>
import {
  getColorArrayForSchema,
  getAutomaticBinsForColourScaleInDomain,
  useEventsBinding,
  useMap, defaultColour
} from "@/composables/index.js"
import { H3HexagonLayer } from "@deck.gl/geo-layers"
import { MapboxLayer } from "@deck.gl/mapbox"
import { cellToLatLng } from "h3-js"
import "mapbox-gl/dist/mapbox-gl.css"
import {computed, inject, nextTick, onMounted, onUnmounted, ref, unref, watch} from "vue"

import { log } from "@/plugin/logger.js"
import * as h3 from "h3-js"
import * as turf from "turf"
import {useSetStore} from "@/store/setStore"
import {useI18nStore} from "@/store/localeStore"

const events = [
  "decklayervisible",
  "decklayercolorstyle",
  "decklayerelevationstyle",
  "decklayerelevationscalestyle",
  "decklayercolorrampstyle",
  "decklayerfilter",
  "deckfeaturesselected",
  "layerDataChanged",
  "styleChanged"
]

const { map } = useMap()
const isLoaded = ref(false)
// eslint-disable-next-line vue/valid-define-emits
const emit = defineEmits()

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  beforeId: {
    type: String,
    default: undefined
  },
  getDataFunction: {
    type: String,
    required: true
  },
  accessorKeyForDataFunction: {
    type: String,
    required: true
  },
  options: {
    type: Object,
    default: () => {}
  },
  keyForScale: {
    type: String,
    required: true
  },
  namedModule: {
    type: String,
    required: true
  },
  colorSchema: {
    type: Object,
    default: defaultColour
  },
  h3Key: {
    type: String,
    default: "hexagons"
  },
  keyForElevation: {
    type: String,
    default: "sum_duration"
  }
})

const dataStore = useSetStore()
const useCustomStore = inject(props.namedModule)
const hexagonSelected = ref(false)
const selectedHexagonId = ref("")
const i18nStore = useI18nStore()
const translate = (key) => {
  return i18nStore.getTranslation(key)
}

const dataInMap = computed(() => {
  return useCustomStore.getAggregate(props.accessorKeyForDataFunction)
})

const fetchLocationData = () => {
  return dataStore.getAggregate(props.accessorKeyForDataFunction)
}

const transposeCoordinates = h3CellIndex => {
  let invertedCoordinates = cellToLatLng(h3CellIndex)
  return [invertedCoordinates[1], invertedCoordinates[0]]
}

const defaultColorScheme = props.colorSchema.map((colours) => { return colours })
const colourArray = getColorArrayForSchema(defaultColorScheme)
const keyForColour = ref(props.keyForScale)
const initData = dataInMap.value ? dataInMap.value : fetchLocationData()
const forScale = getAutomaticBinsForColourScaleInDomain(initData, keyForColour.value, colourArray)
const colourScale = ref(forScale)
const filterDesc = ref()
const lastEvent = ref()

const hexagonLayer = new MapboxLayer({
  id: props.id,
  type: H3HexagonLayer,
  data: fetchLocationData(),
  autoHighlight: true,
  opacity: 0.8,
  getHexagon: hexagon => hexagon[props.h3Key],
  getFillColor: hexagon => colourScale.value(hexagon[keyForColour.value]),
  pickable: true,
  extruded: true,
  elevationScale: fetchLocationData() && fetchLocationData().length ? 1 : 0.5,
  getElevation: hexagon => hexagon[props.keyForElevation],
  onClick: (hexagon, event) => {
    event.preventDefault()
    event.stopPropagation()
    const hexagonId = hexagon.object[props.h3Key]

    if (selectedHexagonId.value === hexagonId) {
      // Deselect the hexagon if it's already selected
      hexagonSelected.value = false
      selectedHexagonId.value = null
      updateSelectedHexagons({ layerName: props.id, featureIds: "" })

    } else {
      hexagonSelected.value = true
      selectedHexagonId.value = hexagonId
      log("info", hexagonId)
      updateSelectedHexagons({ layerName: props.id, featureIds: hexagonId, minDate: hexagon.object["min_date"] })

      const toThePopup = {
        coordinates: transposeCoordinates(hexagonId),
        properties: hexagon.object
      }
      emit("mb-layer-click", toThePopup)
    }
  }
})

function handleDeckLayerDataUpdated (event) {
  if (event.layerName === props.id) {
    hexagonLayer.setProps({
      updateTriggers: {
        data: dataInMap.value
      },
      data: dataInMap.value
    })
  }
}

function handleDeckLayerVisibility (event) {
  if (event.layerName === props.id) {
    hexagonLayer.setProps({
      visible: event.visibility
    })
  }
}

function handleDeckLayerColorChange (event) {
  if (event.layerName === props.id) {
    keyForColour.value = event.colorPropKey
    colourScale.value = event.colourScale
    hexagonLayer.setProps({
      updateTriggers: {
        getFillColor: [keyForColour.value]
      },
      getFillColor: hexagon => event.colourScale(hexagon[keyForColour.value])
    })
  }
}

function handleDeckLayerElevationChange (event) {
  if (event.layerName === props.id) {
    hexagonLayer.setProps({
      updateTriggers: {
        getElevation: [event.elevationPropKey]
      },
      getElevation: hexagon => Math.round(hexagon[event.elevationPropKey])
    })
  }
}
function handleDeckLayerElevationScaleChange (event) {
  if (event.layerName === props.id) {
    hexagonLayer.setProps({
      updateTriggers: {
        elevationScale: event.elevationScalePropKey
      },
      elevationScale: event.elevationScalePropKey
    })
  }
}
function filterData(data, filters) {
  return data.filter(obj => {
    return filters.every(filter => {
      const fieldValue = obj[filter.selectedTexts[0]]
      if (filter.selectedInputType === "number") {
        return fieldValue >= parseFloat(filter.selectedRanges[0]) && fieldValue <= parseFloat(filter.selectedRanges[1])
      } else if (filter.selectedInputType === "date") {
        const startDate = new Date(filter.selectedDates[0])
        const endDate = new Date(filter.selectedDates[1])
        const objDate = new Date(fieldValue)
        return objDate >= startDate && objDate <= endDate
      } else {
        if (filter.selectedSplitStrings[0]!== ""&& filter.selectedSplitStrings.length>0 ) {
          return filter.selectedSplitStrings.some(function (element) {
            return fieldValue.includes(element)
          })
        } else {
          return true
        }
      }
    })
  })
}

function handleDeckLayerFilter (event) {
  if (event.layerName === props.id) {
    hexagonLayer.setProps({
      updateTriggers: {
        data:filterData(dataInMap.value, event.arrayFilter)
      },
      data:filterData(dataInMap.value, event.arrayFilter)
    })
  }
  filterDesc.value = translate("data->Total no. of locations/hexagons:") + " " + dataInMap.value.length + " ," + translate("data->Filtered no. of locations/hexagons:") + (filterData(dataInMap.value, event.arrayFilter).length)

  useCustomStore.setFilterDesc(filterDesc.value)
  nextTick(() => {
    unref(map).fire("filterDescription",
        {
          descFilter: filterDesc.value
        }
    )
  })
}

function handleDeckLayerColorRampChange (event) {
  if (event.layerName === props.id) {
    colourScale.value = event.colourScale
    keyForColour.value = event.colorPropKey
    hexagonLayer.setProps({
      updateTriggers: {
        getFillColor: [event.colourScale]
      },
      getFillColor: hexagon => event.colourScale(hexagon[event.colorPropKey])
    })
  }
}

const options = computed(() => {
  const opts = { ...props.options, id: props.id }
  if (opts.paint === null || opts.paint === undefined) {
    delete opts.paint
  }
  if (opts.layout === null || opts.layout === undefined) {
    delete opts.layout
  }
  return opts
})

useEventsBinding(emit, map, events, props.id)

/**
 * Remove the layer.
 */
function removeLayer() {
  if (typeof unref(map).getLayer(props.id) !== "undefined") {
    unref(map).removeLayer(props.id)
  }
}

const flyInOnLoad = () => {
  const locationAggregates = fetchLocationData()
  if (locationAggregates === null) return
  const maxObj = locationAggregates.reduce((prev, current) => (prev.no_stops > current.no_stops) ? prev : current)
  const maxHex = maxObj[props.h3Key]
  let [lat, lng] = transposeCoordinates(maxHex)

  // Adjust longitude to skew towards the right to fit the panel
  const latitudeOffset = -0.01
  lat -= latitudeOffset
  const target = {
    center: [lat, lng],
    zoom: 13
  }

  unref(map).flyTo({
    ...target,
    pitch: 60,
    duration: 6000,
    essential: true
  })
}

const updateSelectedHexagons = (event) => {
  if (event.layerName === props.id) {
    const selected = event.featureIds

    hexagonLayer.setProps({
      updateTriggers: {
        getFillColor: selected
      },
      getFillColor: hexagon => {
        const col = selected.includes(hexagon[props.h3Key])
          ? [255, 0, 128]
          : colourScale.value(hexagon[keyForColour.value])
        return col
      }
    })

    if (selected.length === 0) emit("hex-deselect", { layerName: props.id })
    else if (!Array.isArray(selected) && selected.length > 0) {
      emit("hex-clicked", { layerName: props.id, hexId: selected, minDate: event.minDate })
      if (event.skipTableUpdate) flyToOnSelection(selected)
    }
    else if (Array.isArray(selected) && selected.length > 0) {
      emit("hexes-selected", { layerName: props.id, coordinates: event.centroid, selectedHexes: selected, minDate: event.minDate})
      if (event.skipTableUpdate) flyToOnSelection(selected[0])
    }
    if (!event.skipTableUpdate || event.skipTableUpdate === false) {
      unref(map).fire("mapfeaturesselected", { layerName: props.id, featureIds: selected })
    }
  }
}

const flyToOnSelection = (hexId) => {
  let [lat, lng] = transposeCoordinates(hexId)

  // Adjust longitude to skew towards the right to fit the panel
  const latitudeOffset = -0.01
  lat -= latitudeOffset
  const target = {
    center: [lat, lng],
    zoom: 13
  }

  unref(map).flyTo({
    ...target,
    pitch: 60,
    duration: 6000,
    essential: true
  })
}

const updateArea = (e) => {
  let selectedHexes

  const polygonCoordinates = e
    .features
    .filter(feature => feature.geometry.type === "Polygon")
    .map(polygon => polygon.geometry.coordinates)

  if (polygonCoordinates.length > 0) {
    let centroid
    const allHexesWithin = polygonCoordinates.reduce((acc, coords) => {
      const bboxPolygon = turf.polygon(coords)
      const bboxAreaInSqM = turf.area(bboxPolygon)

      // The max size for any polygon drawn in the map
      // This is to ensure that we don't gett OOM issues when filling larger areas
      // with hexagons.
      if (bboxAreaInSqM < 100000000) {
        return acc.concat(h3.polygonToCells(coords, 10, true))
      } else {
        alert("Oops! Your bounding box is too large. Please keep it under 100 km²")
        return acc
      }
    }, [])

    if (allHexesWithin.length > 0) {
      const extractedValues = dataInMap.value.map(obj => obj[props.h3Key]).flat()
      selectedHexes = allHexesWithin.filter(str => extractedValues.includes(str))
      const lastPolygonCoordinates = polygonCoordinates[polygonCoordinates.length - 1]
      const features = turf.polygon(lastPolygonCoordinates)
      centroid = turf.center(features).geometry.coordinates

      emit("polygon-selected", polygonCoordinates) //emit the bbox somewhere else?!
      log("info", "A new bbox was drawn for: "+ JSON.stringify(polygonCoordinates))
      updateSelectedHexagons({ layerName: props.id, coordinates: centroid, featureIds: selectedHexes, minDate: dataInMap.value[0].min_date})
    }
  }
}

onMounted(() => {
  useCustomStore.setAggregate(props.accessorKeyForDataFunction, dataStore.getAggregate(props.accessorKeyForDataFunction))
  log("info", "Initiated a 'HexagonLayer' with " + props.id + " name.")
  removeLayer()
  unref(map).addLayer(hexagonLayer)
  useCustomStore.setMapLayerProp("Hexagon", parseInt(props.id.split("_").pop()), "mbId", props.id)

  log("info", "layer is added to map")
  unref(map).setLayoutProperty(props.id, "visibility", "visible")
  unref(map).triggerRepaint()

  isLoaded.value = true
  emit("hex-loaded")
  filterDesc.value = translate("data->Total no. of locations/hexagons:") + " " + dataInMap.value.length + " ," + translate("data->Filtered no. of locations/hexagons:") +  dataInMap.value.length

  useCustomStore.setFilterDesc(filterDesc.value)

  // if layer is the first layer added in the mappy app the make use of flyin feature
  if (props.namedModule.includes("mainMap") && props.id.split("_").pop() === "0") flyInOnLoad()

  // listen to custom event for deck
  map.value.on("decklayervisible", handleDeckLayerVisibility)
  map.value.on("decklayercolorstyle", handleDeckLayerColorChange)
  map.value.on("decklayerelevationstyle", handleDeckLayerElevationChange)
  map.value.on("decklayerelevationscalestyle", handleDeckLayerElevationScaleChange)
  map.value.on("decklayercolorrampstyle", handleDeckLayerColorRampChange)
  map.value.on("decklayerfilter", handleDeckLayerFilter)
  map.value.on("deckfeaturesselected", updateSelectedHexagons)
  map.value.on("layerDataChanged", handleDeckLayerDataUpdated)
  map.value.on("draw.updated", updateArea)

  map.value.on("styleChanged", function() {
    removeLayer()

    // Re-attaching the layer to the map when the custom event is heard
    unref(map).addLayer(hexagonLayer)
    unref(map).setLayoutProperty(props.id, "visibility", "visible")
  })
})

onUnmounted(() => {
  removeLayer()
})


watch(() => dataInMap.value, (newVal) => {
  unref(map).fire("layerDataChanged", { layerName: props.id })
}, { immediate: true })

</script>

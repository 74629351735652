import pino from "pino"

require("setimmediate")

let sessionId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
let service = "mappy-frontend"
let application = "mappy"

const sendToServer = (level, logEvent) => {
  switch (level) {
    case "error":
      console.error(`${logEvent.ts} [${level}]: ${JSON.stringify(logEvent.messages[1])} ${logEvent.messages[0].method}:${logEvent.messages[0].line}`)
      break
    case "warning":
      console.warn(`${logEvent.ts} [${level}]: ${JSON.stringify(logEvent.messages[1])} ${logEvent.messages[0].method}:${logEvent.messages[0].line}`)
      break
    default:
      console.log(`${logEvent.ts} [${level}]: ${JSON.stringify(logEvent.messages[1])} ${logEvent.messages[0].method}:${logEvent.messages[0].line}`)
  }
  if (process.env.VUE_APP_API_CLIENT === "mock") return
    try {
      fetch(process.env.VUE_APP_LOG_API + "/Prod/PostLogs", {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json"
      },
      body: JSON.stringify({
        message: logEvent.messages[1],
        level: logEvent.level,
        _timestamp: logEvent.ts,
        sessionId: sessionId,
        service: service,
        application: application
      })
    })
  } catch (e) {
    console.log("Trying to post logs but failed.")
  }
}

const logger = pino({
  prettyPrint: true,
  timestamp: () => `${new Date().toISOString()}`,
  sessionId: sessionId,
  service: service,
  application: application,
  level: "fatal",
  hooks: {
    logMethodCall (args, method) {
      args[0] = `${method}() - ${args[0]}`
    }
  },
  browser: {
    asObject: true,
    prettyPrint: true,
    serialize: true,
    translateTime: "SYS:mm-dd-yyyy hh:mm:ss TT",
    transmit: {
      level: "trace",
      send: sendToServer
    }
  }
})

// const logger = settings.child({
//   sessionId: sessionId,
//   service: service,
//   application: application
// })

export function log(level, message) {
  const error = new Error()
  const method = error.stack.split("\n")[2].trim().match(/\/([^\/:]+):/)[1]
  const line = error.stack.split("\n")[2].trim().match(/:.+:(.+):/)[1]
  try {
    logger[level]({ method, line }, JSON.stringify(message))
  } catch {
    console.log(level, message)
    logger[level]({ "method":"", "line":"" }, JSON.stringify(message))
  }
}

export function getSessionId() {
  return sessionId
}

export default logger

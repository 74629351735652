import * as d3 from "d3"

export const calendarxaxis = {
    mounted (el, binding) {
        const { scale } = binding.value

        d3.select(el).transition().duration(500)
          .call(d3.axisTop().scale(scale)
            .tickFormat(d3.format("02d"))
            .ticks(24))
          .selectAll(".tick line")
          .style("stroke-width", "0.3px")
          .selectAll("text")
          .attr("class", "tickText")
    }
}

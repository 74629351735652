import { inject } from "vue"

/**
 * Inject the provided map instance.
 * @returns {{ map: Ref<any> }}
 */
export function useMap() {
  const map = inject("mapbox-map")

  return {
    map
  }
}

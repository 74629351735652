<template>
  <figure class="svg-container">
    <svg
      v-show="computedChartValues.visible"
      ref="svgContainer"
      class="svg-content"
      :viewBox="viewBox"
      preserveAspectRatio="xMidYMid meet"
    >
      <g :transform="`translate(${chartProps.margin.left * 2}, ${chartProps.margin.right})`">
        <g>
          <text
            class="yAxisLabel"
            :x="-chartProps.margin.top"
            :dx="yaxis_dx"
            :y="0"
            :dy="yaxis_dy"
          >{{ chartProps.yAxisLabel }}</text>
          <text
            class="xAxisLabel"
            :x="computedChartValues.w - chartProps.margin.right"
            :y="computedChartValues.h"
            :dx="xaxis_dx"
            :dy="xaxis_dy"
          >{{ chartProps.xAxisLabel }}</text>
        </g>
        <g
          v-for="(hex, idx) in yValueKeys"
          :key="idx"
          :transform="`translate(${computedChartValues.boxWidth} 0)`"
          class="hex-group"
          @click="populateTooltip($event, hex)"
          @mouseleave="removeTooltip()"
        >
          <line
            :id="hex.key"
            :x1="xScaleCalc(hex.key)"
            :x2="xScaleCalc(hex.key)"
            :y1="yScaleCalc(hex.value.min)"
            :y2="yScaleCalc(hex.value.max)"
            class="hex-line"
          />
          <rect
            :x="xScaleCalc(hex.key)-(computedChartValues.boxWidth/2)"
            :y="yScaleCalc(hex.value.q3)"
            :height="yScaleCalc(hex.value.q1)-yScaleCalc(hex.value.q3)"
            :width="computedChartValues.boxWidth"
            class="hex-rect"
          />
          <line
            :x1="xScaleCalc(hex.key)-(computedChartValues.boxWidth/2)"
            :x2="xScaleCalc(hex.key)+(computedChartValues.boxWidth/2)"
            :y1="yScaleCalc(hex.value.median)"
            :y2="yScaleCalc(hex.value.median)"
            class="hex-median-line"
          />
        </g>
        <g
          v-boxplotxaxis="{ scale: xScale}"
          :transform="`translate(0 ${computedChartValues.h})`"
          class="boxplot-xaxis"
        />
        <g
          v-boxplotyaxis="{ scale: yScale}"
          :transform="`translate(0 0)`"
          class="boxplot-yaxis"
        />
      </g>
    </svg>
  </figure>
  <div
    v-if="showTooltip"
    class="tooltipContainer"
    :class="{ activeTooltip: showTooltip}"
    :style="{ top: tooltip.y, left: tooltip.x }"
  >
    <slot
      name="tooltip"
      :lines="tooltip.values"
    >
      <span
        v-for="(value, key) in tooltip.values"
        :key="key"
        class="tooltip-text"
      >{{ key }}: {{ value }}</span>
    </slot>
  </div>
</template>

<script setup>
import {ref, computed, defineProps, reactive, inject} from "vue"
import * as d3 from "d3"
import { log } from "@/plugin/logger"
import {useSetStore} from "@/store/setStore"

const props = defineProps({
  showThis: {
    type: Boolean,
    default: true
  },
  namedModule: {
    type: String,
    default: ""
  },
  graphTitle: {
    type: String,
    default: "this will be a graph title, that can be long"
  },
  width: {
    type: Number,
    default: 600
  },
  height: {
    type: Number,
    default: 300
  },
  xKey: {
    default: "",
    type: String
  },
  yKey: {
    default: "",
    type: String
  },
  xAxisLabel: {
    type: String,
    default: ""
  },
  yAxisLabel: {
    type: String,
    default: ""
  },
  xAxisValue: {
    default: "",
    type: String
  },
  structKey: { type: String, required: true }
})


const useCustomStore = props.namedModule.length > 0 ? inject(props.namedModule) : useSetStore()
const svgContainer = ref()
const padding = ref(60)
const showTooltip = ref(false)

// Group related properties
const chartProps = reactive({
  structKey: props.structKey,
  fontSize: "0.7rem",
  graphTitle: props.graphTitle,
  width: props.width,
  height: props.height,
  xKey: props.xKey,
  yKey: props.yKey,
  xAxisLabel: props.xAxisLabel,
  yAxisLabel: props.yAxisLabel,
  margin: { top: 0, right: 20, bottom: 40, left: 40 },
  xAxisLabelShift: { dx: 90, dy: 85 },
  yAxisLabelShift: { dx: -40, dy: -55 },
  xAxisTickLabelFontSize: "11px"
})

const tooltip = ref({
  x: 0,
  y: 0,
  values: {}
})

const computedChartValues = computed(() => {
  const strokeWidth = { w: 80, h: 40 }

  const w = chartProps.width - chartProps.margin.left - chartProps.margin.right - (strokeWidth.w * 2)
  const h = chartProps.height - chartProps.margin.bottom - (strokeWidth.h * 2)
  const boxWidth = (w / yValueKeysLength.value) * 0.5
  const visible = props.showThis

  return {
    visible,
    w,    // Computed width for the chart
    h,    // Computed height for the chart
    boxWidth  // Computed box width
  }
})
function getRollupData (flattenValues, groupByFunction) {
  return Array.from(
      d3.rollups(flattenValues,
          v => {
            let q1 = v.map(function(g) { return g.q1 })[0]
            let min = v.map(function(g) { return g.min })[0]
            let median = v.map(function(g) { return g.median })[0]
            let q3 = v.map(function(g) { return g.q3 })[0]
            let max = v.map(function(g) { return g.max })[0]
            let interQuantileRange = q3 - q1

            return {
              q1: q1,
              median: median,
              q3: q3,
              interQuantileRange: interQuantileRange,
              min: min,
              max: max
            }
          },
          groupByFunction
      ),
      ([key, value]) => {
        return {
          key: key,
          value: value
        }
      }
  )
}
function xScaleCalc (x) {
  return xScale.value(x)
}
function yScaleCalc (y) {
  return yScale.value(y)
}
function populateTooltip(evt, element) {
  const svgRect = svgContainer.value.getBoundingClientRect()

  // Calculate tooltip position relative to SVG container
  tooltip.value.x = `${evt.clientX - svgRect.left}px`
  tooltip.value.y = `${evt.clientY - svgRect.top}px`

  tooltip.value.values = {
    "95th percentile": element.value.max,
    "75th percentile": element.value.q3,
    "50th percentile": element.value.median,
    "25th percentile": element.value.q1,
    "5th percentile": element.value.min


  }

  showTooltip.value = true
  log("info", "{\"populateTooltip\":\"Clicked data in BoxplotChart\"}")
}
function removeTooltip() {
  showTooltip.value = false
}

const flattenValues = computed(() => {
  return useCustomStore.getAggregate(chartProps.structKey)
})

const nestedByGroup = computed(() => {
  return getRollupData(flattenValues.value, d => d[chartProps.xKey])
      .sort((a, b) => d3.ascending(a[0], b[0]))
})

const yValueKeys = computed(() => {
  return Object.values(nestedByGroup.value).filter(item => item !== chartProps.xKey).sort()
})

const yValueKeysLength = computed(() => {
  return yValueKeys.value.length
})

const xScale = computed(() => {
  return d3.scaleBand()
      .domain(yValueKeys.value.map(vals => vals.key))
      .range([0, computedChartValues.value.w])
})

const yScale = computed(() =>  {
  return d3.scaleLinear()
      .domain([
          d3.min(nestedByGroup.value, function (d) { return d.value.min }),
          d3.max(nestedByGroup.value, function (d) { return d.value.max })
      ])
      .range([computedChartValues.value.h, 0])
      .nice()
})

const viewBox = computed(() => {
  return `0 0 ${props.width} ${props.height}`
})

const xaxis_dx = computed(() => {
  return "dx" in chartProps.xAxisLabelShift ? chartProps.xAxisLabelShift.dx : undefined
})

const xaxis_dy = computed(() => {
  return "dy" in chartProps.xAxisLabelShift ? chartProps.xAxisLabelShift.dy : undefined
})

const yaxis_dx = computed(() => {
  return "dx" in chartProps.yAxisLabelShift ? chartProps.yAxisLabelShift.dx : undefined
})

const yaxis_dy = computed(() => {
  return "dy" in chartProps.yAxisLabelShift ? chartProps.yAxisLabelShift.dy : undefined
})
</script>
<script>
import { boxplotxaxis } from "@/directives/boxplotxaxis.js"
import { boxplotyaxis } from "@/directives/boxplotyaxis.js"
export default {
  directives: {
    boxplotxaxis,
    boxplotyaxis
  }
}
</script>

<style scoped>
.yAxisLabel {
  transform: rotate(-90deg);
  text-anchor: end;
  font-size: 0.90rem;
  fill: #2c3e50;
}
.xAxisLabel {
  text-anchor: end;
  font-size: 0.90rem;
  fill: #2c3e50;
}
.tooltipContainer {
  position: fixed;
  font-size: 0.8rem;
  font-family: "Scania Sans Semi Condensed", "Scania Sans Condensed", Arial, Helvetica, sans-serif;
  color: #2c3e50;
  background-color: #ffffff;
  opacity: 0;
  pointer-events: none;
  border: 1px solid #ddd;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  border-radius: 4px;
  padding: 8px;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
}
.activeTooltip {
  opacity: 0.95;
  z-index: 6;
}
.tooltipContainer span {
  display: block;
}
.svg-container {
  width: 100%;
  vertical-align: top;
  display: block;
  background-color: white;
  color: #2c3e50;
  font-weight: 300;
  font-family: "Scania Sans Semi Condensed", "Scania Sans Condensed", Arial, Helvetica, sans-serif;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
}

rect {
  transition: fill 0.3s ease-in-out; /* Smooth color transition for the heatmap squares */
}

rect:hover {
  stroke: #fff; /* Add a white stroke to highlight on hover */
  stroke-width: 1px;
}

.chart-visibility-btn:hover {
  background-color: #f2f2f2; /* Light background on hover for visibility toggle */
}

rect {
  fill-opacity: 0.8;
  stroke: #f5f5f5; /* Add a stroke to separate the rectangles slightly */
  stroke-width: 0.5;
}

.hex-line {
  stroke: black;
  width: 40px; /* This may need to be adjusted, as width is not a valid property for line elements */
}

.hex-rect {
  stroke: black;
  fill: rgb(110, 64, 170);
}

.hex-median-line {
  stroke: black;
  width: 40px; /* This may need to be adjusted as well */
}

.boxplot-xaxis {
  font-size: var(--x-axis-tick-label-font-size); /* Set this variable in your root or higher-level CSS */
}

.boxplot-yaxis {
  font-size: var(--font-size); /* Set this variable in your root or higher-level CSS */
}

</style>


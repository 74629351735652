<template>
  <!-- This component doesn't render any visible elements -->
  <div style="display: none;"></div>
</template>

<script>
import { watch, onMounted } from "vue"

export default {
  name: "IsochroneLayer",
  props: {
    map: {
      type: Object,
      required: true
    },
    isochroneData: {
      type: Object,
      default: null
    }
  },
  setup(props) {
    const addIsochroneLayer = () => {
      if (props.map && props.isochroneData) {
        // Remove existing isochrone layer if it exists
        if (props.map.getLayer("isochrone-layer")) {
          props.map.removeLayer("isochrone-layer")
        }
        if (props.map.getSource("isochrone-source")) {
          props.map.removeSource("isochrone-source")
        }

        // Add the isochrone source and layer
        props.map.addSource("isochrone-source", {
          type: "geojson",
          data: props.isochroneData
        })

        props.map.addLayer({
          id: "isochrone-layer",
          type: "fill",
          source: "isochrone-source",
          layout: {},
          paint: {
            "fill-color": [
              "interpolate",
              ["linear"],
              ["get", "contour"],
              10000, "#e5f5e0",
              20000, "#a1d99b",
              30000, "#31a354",
              40000, "#006d2c"
            ],
            "fill-opacity": 0.5
          }
        })
      }
    }

    onMounted(() => {
      if (props.map && props.map.loaded()) {
        addIsochroneLayer()
      } else if (props.map) {
        props.map.on("load", addIsochroneLayer)
      }
    })

    watch(() => props.isochroneData, () => {
      if (props.map && props.map.loaded()) {
        addIsochroneLayer()
      }
    })
  }
}
</script>

import * as d3 from "d3"

export const calendaryaxis = {
    mounted (el, binding) {
        const { scale } = binding.value

        d3.select(el).transition().duration(500)
          .call(d3.axisLeft().scale(scale))
          .selectAll("text")
          .attr("class", "tickText")
    }
}

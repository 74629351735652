import { createRouter as createVueRouter, createWebHistory } from "vue-router"
import EmptyMapView from "./views/EmptyMapView"
import CustomerMapView from "./views/CustomerMapView"
import FleetView from "@/views/FleetView.vue"
import { log } from "@/plugin/logger"
import { authService } from "@/services/AuthService"

const requiredRole = process.env.VUE_APP_KEYCLOAK_REQUIRED_ROLE
const HORequiredRole = [process.env.VUE_APP_AZUREAD_REQUIRED_ROLE]
const isAuthorised = (app) => {
  log("info", "Someone tried to get into Mappy.")
  const userEmail = authService.keycloak.idTokenParsed ? authService.keycloak.idTokenParsed.email : JSON.stringify((authService.keycloak))
  log("info", "Authenticated: " + userEmail)
  if (!authService.keycloak.hasResourceRole(requiredRole)) {
    log("info", userEmail + " tried to get into mappy via FG, but is lacking access.")
  }
  if (authService.keycloak.authenticated && authService.keycloak.hasResourceRole(requiredRole)) {
    log("info", userEmail + " logged in via FG.")
    return true
  }
  return false
}

const isHOAuthorised = (app) => {
  log("info", "Someone tried to get into Mappy.")
  const user = authService.getADUser() ? authService.getADUser() : JSON.stringify((authService.login))
  log("info", "Authenticated: " + user)
  if (!authService.hasADRoles(HORequiredRole)) {
    log("info", user + " tried to get into mappy via Azure, but is lacking access.")
  }
  else {
    log("info", user + " logged in via Azure.")
  }
  return !!(authService.hasADRoles(HORequiredRole) && authService.getADUser())
}

const createRouter = (app) => {
  const router = createVueRouter({
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition
      } else {
        return { top: 0 }
      }
    },
    routes: [
      {
        path: "/",
        name: "EmptyMapView",
        component: EmptyMapView,
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "/mymappy",
        name: "CustomerMapView",
        component: CustomerMapView,
        // eslint-disable-next-line no-unused-vars
        beforeEnter: (to, from) => {
          if(!isAuthorised(app)) {
            return ({ name: "EmptyMapView" })
          }
        },
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/sweete",
        name: "FleetView",
        component: FleetView,
        // eslint-disable-next-line no-unused-vars
        beforeEnter: (to, from) => {
          if(!isHOAuthorised(app)) {
            return ({ name: "EmptyMapView" })
          }
        },
        meta: {
          requiresAuth: true
        }
      }
    ]
  })
  // eslint-disable-next-line no-unused-vars
  router.beforeEach(async (to, from) => {
    if(!to.meta.requiresAuth) return true
    if(!isAuthorised(app) && !isHOAuthorised(app)) {
      return ({ name: "EmptyMapView" })
    }
  })

  return router
}

export {
  createRouter
}

class WorkerManager {
  constructor() {
    this.workers = {}
    this.initWorkers()
    this.listeners = {}
  }

  initWorkers() {
    // Creating worker instances for various APIs
    const workerNames = ["edgesAPI", "hexesAPI", "graphDataAPI", "rawStopsAPI",
      "dailyFuelBoxplotAPI", "dailyOdometerBoxplotAPI", "odometerBoxplotAPI",
      "fuelBoxplotAPI", "hourlyLocationDistributionAPI",
      "dailyGraphAggregateAPI", "dailyHourlyLocationDistributionAPI",
      "minMaxValuesAPI"]

    workerNames.forEach(name => {
      this.workers[name] = new Worker(
        new URL("../web-worker/snowflakeWorker.js", import.meta.url),
        { type: "module" }
      )
    })
  }

  getWorker(name) {
    return this.workers[name]
  }

  registerListener(name, callback) {
    this.listeners[name] = callback
    this.workers[name].onmessage = (event) => {
      if (this.listeners[name]) {
        this.listeners[name](event)
      }
    }
  }

  deregisterListener(name) {
    if (this.listeners[name]) {
      delete this.listeners[name]
    }
  }
}

export const workerManager = new WorkerManager()

<template>
  <div :id="id" />
</template>

<script setup>
import { getColorArrayForSchema,
  getAutomaticBinsForColourScaleInDomain,
  useEventsBinding,
  useMap
} from "@/composables/index.js"
import { ArcLayer } from "@deck.gl/layers"
import { MapboxLayer } from "@deck.gl/mapbox"
import { cellToLatLng } from "h3-js"
import "mapbox-gl/dist/mapbox-gl.css"
import {computed, onMounted, onUnmounted, ref, unref, defineEmits, inject} from "vue"

import { log } from "@/plugin/logger.js"
import {useFleetStore} from "@/store/fleet/fleetStore"
import {useSetStore} from "@/store/setStore"

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  beforeId: {
    type: String,
    default: undefined
  },
  options: {
    type: Object,
    default: () => {}
  },
  namedModule: {
    type: String,
    required: true
  },
  edgeData: {
    type: Array,
    required: true
  },
  originKey: {
    type: String,
    default: "origin_h3_cell_index"
  },
  destinationKey: {
    type: String,
    default: "destination_h3_cell_index"
  },
  widthKey: {
    type: String,
    default: "count_of_trips"
  },
  heightKey: {
    type: String,
    default: ""
  }
})


const events = [
  "decklayervisible",
  "decklayercolorstyle",
  "decklayercolorrampstyle",
  "decklayerfilter",
  "layerDataChanged",
  "styleChanged"
]

const useCustomStore = inject(props.namedModule)
const fleetStore = useFleetStore()
const dataStore = useSetStore()
const { map } = useMap()
const isLoaded = ref(false)
// eslint-disable-next-line vue/valid-define-emits
const emit = defineEmits()

const originKey = ref(props.originKey)
const destinationKey = ref(props.destinationKey)
const widthKey = ref(props.widthKey)
const heightKey = ref(props.heightKey)
const colourForSource = "#ff0080"
const colourForTarget = "#ffa200"

const transposeCoordinates = h3CellIndex => {
  let invertedCoordinates = cellToLatLng(h3CellIndex)
  return [invertedCoordinates[1], invertedCoordinates[0]]
}
const hexToRgb = (hex) => {
  let res = hex.match(/[a-f0-9]{2}/gi)
  return res && res.length === 3
      ? res.map(h => { return parseInt(h, 16) })
      : null
}

const arcLayer = new MapboxLayer({
  id: props.id,
  type: ArcLayer,
  data: props.edgeData,
  widthMaxPixels: 20,
  opacity: 0.6,
  getHeight: d => heightKey.value.length > 0 ? 1 + 1 / d[heightKey.value] : 1,
  getSourcePosition: d => transposeCoordinates(d[originKey.value]),
  getTargetPosition: d => transposeCoordinates(d[destinationKey.value]),
  getSourceColor: hexToRgb(colourForSource),
  getTargetColor: hexToRgb(colourForTarget),
  getWidth: d => Math.max(1, Math.log(d[widthKey.value])),
  getTilt: 10,
  autoHighlight: true,
  pickable: true,
  onClick: (edge, event) => {
    event.stopPropagation()
    emit("mb-layer-click", { coordinates: transposeCoordinates(edge.object[originKey.value]), properties: edge.object })
  }
})

function handleDeckLayerDataUpdated (event) {
  if (event.layerName === props.id) {
    arcLayer.setProps({
      updateTriggers: {
        data: event.edgeData
      },
      data: event.edgeData
    })
  }
}


function handleDeckLayerVisibility (event) {
  if (event.layerName === props.id) {
    arcLayer.setProps({
      visible: event.visibility
    })
  }
}


const options = computed(() => {
  const opts = { ...props.options, id: props.id }
  if (opts.paint === null || opts.paint === undefined) {
    delete opts.paint
  }
  if (opts.layout === null || opts.layout === undefined) {
    delete opts.layout
  }
  return opts
})

useEventsBinding(emit, map, events, props.id)

function removeLayer() {
  if (typeof unref(map).getLayer(props.id) !== "undefined") {
    unref(map).removeLayer(props.id)
  }
}

onMounted(() => {
  log("info", "Initiated an 'ArcLayer' with " + props.id + " name.")
  removeLayer()
  unref(map).addLayer(arcLayer)
  useCustomStore.setMapLayerProp("Trip", parseInt(props.id.split("_").pop()), "mbId", props.id)

  log("info", "layer is added to map")
  unref(map).setLayoutProperty(props.id, "visibility", "visible")
  unref(map).triggerRepaint()
  isLoaded.value = true
  emit("arc-layer-loaded")

  // listen to custom event for deck
  map.value.on("decklayervisible", handleDeckLayerVisibility)
  map.value.on("layerDataChanged", handleDeckLayerDataUpdated)
  map.value.on("styleChanged", function() {
    removeLayer()

    // Re-attaching the layer to the map when the custom event is heard
    unref(map).addLayer(arcLayer)
    unref(map).setLayoutProperty(props.id, "visibility", "visible")
  })
  unref(map).triggerRepaint()
})

onUnmounted(() => {
  removeLayer()
})

</script>

import Keycloak from "keycloak-js"
import * as Msal from "@azure/msal-browser"
import { log } from "@/plugin/logger"
import { jwtDecode } from "jwt-decode"

// Keycloak configuration
const keycloakConfig = {
  url: `${process.env.VUE_APP_KEYCLOAK_URL}/auth`,
  realm: "fg-ext",
  clientId: "gsi_mappy"
}

// MSAL configuration
const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_OIDC_CLIENTID,
    authority: process.env.VUE_APP_OIDC_AUTHORITY,
    redirectUri: `${window.location.origin}/sweete`
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false
  }
}

const loginRequest = {
  scopes: ["User.Read"]
}

class AuthService {
  constructor() {
    this.keycloak = new Keycloak(keycloakConfig)
    this.msal = new Msal.PublicClientApplication(msalConfig)
    this.signInType = "loginPopup"  // Or 'loginRedirect'
    this.decodedAndParsedMSALToken = ""
    this.login = null

    this.kcSSOOptions = {
      pkceMethod: "S256",
      onLoad: "check-sso",
      redirectUri: `${window.location.origin}/mymappy`
    }
  }

  async init() {
    if (await this.initKeycloak()) {
      return true
    }
  }

  async initKeycloak() {
    try {
      const authenticated = await this.keycloak.init(this.kcSSOOptions)
      if (authenticated) {
        log("info", "SSO authentication successful")
        return true
      }
      log("info", "SSO authentication not present, user needs to log in")
      return false
    } catch (error) {
      log("error", `Keycloak SSO initialization error: ${error}`)
      return false
    }
  }

  async signInWithKeycloak() {
    try {
      await this.keycloak.login()
      return true
    } catch (error) {
      log("error", `Keycloak login error: ${error}`)
      return false
    }
  }

  getUserEmail() {
    // Check if authenticated with MSAL
    const msalAccount = this.msal.getActiveAccount()
    if (msalAccount) {
      return msalAccount.username // MSAL stores email in the username field
    }

    // Check if authenticated with Keycloak
    if (this.keycloak.authenticated) {
      return this.keycloak.tokenParsed?.email
    }

    return null // Not authenticated or email not found
  }

  getUserRoles() {
    let roles = []

    // Check for roles from MSAL
    const msalAccount = this.msal.getActiveAccount()
    if (msalAccount) {
      const idTokenClaims = msalAccount.idTokenClaims
      roles = roles.concat(idTokenClaims.roles || [])
    }

    // Check for roles from Keycloak
    if (this.keycloak.authenticated) {
      roles = roles.concat(this.keycloak.tokenParsed.resource_access[process.env.VUE_APP_KEYCLOAK_CLIENT_NAME]?.roles || [])
    }

    return roles // Returns combined roles from MSAL and Keycloak
  }
  async signInWithMsal() {
    try {
      if (this.signInType === "loginRedirect") {
        this.login = await this.msal.loginRedirect(loginRequest)
      } else {
        this.login = await this.msal.loginPopup(loginRequest)
      }
      if (this.login) {
        log("info", "Azure AD authentication successful")
        this.decodedAndParsedMSALToken = jwtDecode(this.login.idToken)
        return true
      }
    }
    catch (error) {
      log("error", "Error decoding ID token: " + error)
      log("error", `{"signIn":"[AuthService.signIn] loginPopup error: ${JSON.stringify(error)}"}`, "error")
    }
    return false
  }

  signOut() {
    return this.msal.logoutPopup()
  }

  getADUser () {
    try {
      let email = this.decodedAndParsedMSALToken["preferred_username"]
      return email.slice(0, -11)
    } catch (error) {
      log("error", "No AD user")
    }
    return false
  }
  getADRoles () {
    return this.decodedAndParsedMSALToken["roles"]
  }

  hasADRoles(requiredRoles) {
    try {
      let userRoles = this.decodedAndParsedMSALToken["roles"]
      return userRoles.some(role => requiredRoles.includes(role))
    } catch(error) {
      return false
    }
  }
}

const authService = new AuthService()
export { authService }
<template>
  <div class="inputTitle">
    <span>{{ filterName }}</span>
  </div>
  <DatePicker
    v-model="date"
    range
    :teleport="true"
    position="center"
    :enable-time-picker="false"
    prevent-min-max-navigation
    @open="emit('update-size')"
    @closed="emit('update-size')"
  />
</template>

<script setup>
import {ref, defineProps, watch, defineEmits, onMounted} from "vue"
import DatePicker from "@vuepic/vue-datepicker"
import "@vuepic/vue-datepicker/dist/main.css"
import {useFleetStore} from "@/store/fleet/fleetStore"

const props = defineProps({
  filterName: {
    type: String,
    required: false,
    default: "Stop start time"
  },
  minDateForDataSet: {
    type: String,
    required: false,
    default: ""
  },
  maxDateForDataSet: {
    type: String,
    required: false,
    default: ""
  },
  minStateFunctionString: {
    required: false,
    type: String,
    default: "getSelectedMinTime"
  },
  maxStateFunctionString: {
    required: false,
    type: String,
    default: "getSelectedMaxTime"
  },
  setMinStateFunctionString: {
    required: false,
    type: String,
    default: "setSelectedMinTime"
  },
  setMaxStateFunctionString: {
    required: false,
    type: String,
    default: "setSelectedMaxTime"
  },
  timeWindowStateFunctionString: {
    required: false,
    type: String,
    default: "getTilesTimeWindow"
  },
  getConstantMaxTime: {
    required: false,
    type: String,
    default: "getTilesConstantMaxTime"
  }
})
const emit = defineEmits(["update-size"])

const fleetStore = useFleetStore()
const date = ref()

onMounted(() => {
  date.value = [fleetStore[props.minStateFunctionString], fleetStore[props.maxStateFunctionString]]
})

watch(date, (val) => {
  fleetStore[props.setMinStateFunctionString](val[0])
  fleetStore[props.setMaxStateFunctionString](val[1])
})

</script>

<style scoped>
.inputTitle {
  font-size: small;
  justify-content: center;
  font-weight: bold;
  width: 100%;
  display: inline-block;
  margin-bottom: 4px;
}
</style>
<template>
  <figure class="svg-container">
    <svg
      v-show="computedChartValues.visible"
      ref="weekdayChartContainer"
      class="svg-content"
      :viewBox="viewBox"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        :transform="`translate(${chartProps.margin.left * 1.8} ${chartProps.margin.right * 3})`"
      >
        <g v-if="showAxisLabels">
          <text
            class="yAxisLabel"
            :x="yaxis_dx"
            :y="10"
            :dy="yaxis_dy"
          >
            {{ chartProps.yAxisLabel }}
          </text>
          <text
            class="xAxisLabel"
            :x="chartProps.margin.left+30"
            :y="-25"
          >
            {{ chartProps.xAxisLabel }}
          </text>
        </g>
        <g
          v-for="(weekday, idx) in nestedByGroup"
          :key="idx"
          :class="weekday.key"
          :transform="`translate(${gridSize} ${idx * gridSize})`"
          @mouseleave="removeTooltip"
        >
          <rect
            v-for="key in weekday.values"
            :key="generateVueUID(key)"
            :x="xScaleCalc(key.date) - gridSize"
            :y="0"
            :rx="4"
            :ry="4"
            :height="gridSize"
            :width="gridSize"
            :fill="getColourForDuration(key.median)"
            @click="populateTooltip($event, key)"
          />
        </g>
        <g
          v-calendarxaxis="{ scale: xScale }"
          :transform="'translate(0 0)'"
          :style="{ 'font-size': chartProps.xAxisTickLabelFontSize }"
        />
        <g
          v-calendaryaxis="{ scale: yScale, width: computedChartValues.w }"
          :transform="'translate(0 0)'"
          :style="{ 'font-size': chartProps.fontSize }"
        />
      </g>
    </svg>
  </figure>
  <div
    v-if="showTooltip"
    class="tooltipContainer"
    :class="{ activeTooltip: showTooltip}"
    :style="{ top: tooltip.y, left: tooltip.x }"
  >
    <slot
      name="tooltip"
      :lines="tooltip.values"
    >
      <span
        v-for="(value, key) in tooltip.values"
        :key="key"
      >{{ key }}: {{ value }}</span>
    </slot>
  </div>
</template>

<script setup>
import {ref, computed, defineProps, reactive, inject} from "vue"
import * as d3 from "d3"
import { log } from "@/plugin/logger"
import {getHeatmapColourScale} from "@/composables"
import {useSetStore} from "@/store/setStore"

const props = defineProps({
  namedModule: { type: String, default: "" },
  legendAlignment: { type: String, default: "vertical" },
  showThis: { type: Boolean, required: true },
  structKey: { type: String, required: true },
  fontSize: { type: String, default: "0.7rem" },
  graphTitle: { type: String, default: "This will be a graph title, that can be long" },
  width: { type: Number, default: 600 },
  height: { type: Number, default: 300 },
  xKey: { type: String, required: true },
  yKey: { type: String, required: true },
  zKey: { type: String, required: true },
  xAxisLabel: { type: String, required: true },
  yAxisLabel: { type: String, required: true }
})

const useCustomStore = props.namedModule.length > 0 ? inject(props.namedModule) : useSetStore()
const weekdayChartContainer = ref()

const showAxisLabels = ref(false)
const padding = ref(60)
const showTooltip = ref(false)
const tooltip = ref({ x: 0, y: 0, values: {} })

// Group related properties
const chartProps = reactive({
  colourScaleFunction: getHeatmapColourScale,
  direction: "vertical",
  structKey: props.structKey,
  fontSize: props.fontSize,
  graphTitle: props.graphTitle,
  width: props.width,
  height: props.height,
  xKey: props.xKey,
  yKey: props.yKey,
  zKey: props.zKey,
  xAxisLabel: props.xAxisLabel,
  yAxisLabel: props.yAxisLabel,
  margin: { top: 50, right: 20, bottom: 10, left: 25 },
  weekdayOrder: {
    "Mon": 0, "Tue": 1, "Wed": 2, "Thu": 3, "Fri": 4, "Sat": 5, "Sun": 6
  },
  weekdayOrderName: {
    "Monday": 0, "Tuesday": 1, "Wednesday": 2, "Thursday": 3, "Friday": 4, "Saturday": 5, "Sunday": 6
  },
  xAxisLabelShift: { dx: 90, dy: 85 },
  yAxisLabelShift: { dx: -40, dy: -55 },
  xAxisTickLabelFontSize: "11px"
})

const computedChartValues = computed(() => {
  const strokeWidth = { w: 40, h: 40 }

  const w = chartProps.width - chartProps.margin.left - chartProps.margin.right - (strokeWidth.w * 2)
  const h = chartProps.height - chartProps.margin.bottom - (strokeWidth.h * 2)

  const gridSize = Math.floor(w / 21)
  const visible = props.showThis

  return {
    visible,
    w,    // Computed width for the chart
    h,    // Computed height for the chart
    gridSize  // Computed grid size
  }
})


const gridSize = computed(() => {
  return Math.floor(computedChartValues.value.w / 21)
})

const flattenValues = computed(() => {
  return useCustomStore.getAggregate(chartProps.structKey).map(dayTimeVal => {
    dayTimeVal["day_of_week"] = chartProps.weekdayOrder[dayTimeVal[chartProps.yKey]]?chartProps.weekdayOrder[dayTimeVal[chartProps.yKey]]:chartProps.weekdayOrderName[dayTimeVal[chartProps.yKey]]
    return dayTimeVal
  }).sort((a, b) => d3.descending(a.day_of_week, b.day_of_week) || d3.descending(+b.date, +a.date))
})

const w = computed(() => {
  const strokeWidth = 80
  return chartProps.width - chartProps.margin.left - chartProps.margin.right - (strokeWidth * 2)
})

const h = computed(() => {
  const strokeWidth = 40
  return chartProps.height - chartProps.margin.bottom - (strokeWidth * 2)
})

const nestedByGroup = computed(() => {
  const validValues = flattenValues.value.filter((d) => typeof d.day_of_week !== "undefined")

  return Array.from(
    d3.group(validValues, (d) => d.day_of_week),
    ([key, values]) => ({
      key: key.toString(),
      values: values
    })
  ).sort((a, b) => d3.ascending(a.key, b.key))
})

const colourScale = computed(() => {
  return chartProps.colourScaleFunction(flattenValues.value, chartProps.zKey)
})

const xScale = computed(() => {
  return d3.scaleLinear()
      .domain([0, 24])
      .range([0, gridSize.value * 24])
})

const yScale = computed(() => {
  return d3.scaleBand()
      .domain(Object.keys(chartProps.weekdayOrder))
      .range([0, gridSize.value * 7])
})

const viewBox = computed(() => {
  return `0 0 ${chartProps.width} ${chartProps.height}`
})

const xaxis_dx = computed(() => {
  return "dx" in chartProps.xAxisLabelShift ? chartProps.xAxisLabelShift.dx : undefined
})

const xaxis_dy = computed(() => {
  return "dy" in chartProps.xAxisLabelShift ? chartProps.xAxisLabelShift.dy : undefined
})

const yaxis_dx = computed(() => {
  return "dx" in chartProps.yAxisLabelShift ? chartProps.yAxisLabelShift.dx : undefined
})

const yaxis_dy = computed(() => {
  return "dy" in chartProps.yAxisLabelShift ? chartProps.yAxisLabelShift.dy : undefined
})

function generateVueUID(string) {
  return Math.random(Number.MAX_SAFE_INTEGER) + string
}

function getColourForDuration(timeBucket) {
  return colourScale.value(timeBucket)
}

function xScaleCalc(x) {
  return xScale.value(x)
}

function populateTooltip(evt, key) {
  const svgRect = weekdayChartContainer.value.getBoundingClientRect()

  // Calculate tooltip position relative to SVG container
  tooltip.value.x = `${evt.clientX - svgRect.left}px`
  tooltip.value.y = `${evt.clientY - svgRect.top}px`

  tooltip.value.values = {
    Median: key.median,
    Weekday: key.name_of_day,
    Min: key.min,
    Max: key.max
  }

  showTooltip.value = true
  log("info", "{\"populateTooltip\":\"Clicked data in WeekdayChart\"}")
}

function removeTooltip() {
  showTooltip.value = false
}
</script>

<script>
import { calendarxaxis } from "@/directives/calendarxaxis.js"
import { calendaryaxis } from "@/directives/calendaryaxis.js"
export default {
  directives: {
    calendarxaxis,
    calendaryaxis
  }
}
</script>
<style scoped>
.yAxisLabel {
  transform: rotate(-90deg);
  text-anchor: end;
  font-size: 0.90rem;
  fill: #2c3e50;
}

.xAxisLabel {
  text-anchor: end;
  font-size: 0.90rem;
  fill: #4a4a4a;
}

.tooltipContainer {
  position: fixed;
  font-size: 0.8rem;
  font-family: "Scania Sans Semi Condensed", "Scania Sans Condensed", Arial, Helvetica, sans-serif;
  color: #2c3e50;
  background-color: #ffffff;
  opacity: 0;
  pointer-events: none;
  border: 1px solid #ddd;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  border-radius: 4px;
  padding: 8px;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
}

.activeTooltip {
  opacity: 0.95;
  z-index: 6;
}

.tooltipContainer span {
  display: block;
}

.svg-container {
  width: 100%;
  vertical-align: top;
  display: block;
  background-color: white;
  color: #2c3e50;
  font-weight: 300;
  font-family: "Scania Sans Semi Condensed", "Scania Sans Condensed", Arial, Helvetica, sans-serif;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
}

rect {
  transition: fill 0.3s ease-in-out; /* Smooth color transition for the heatmap squares */
}

rect:hover {
  stroke: #fff; /* Add a white stroke to highlight on hover */
  stroke-width: 1px;
}

.chart-visibility-btn:hover {
  background-color: #f2f2f2; /* Light background on hover for visibility toggle */
}

rect {
  fill-opacity: 0.8;
  stroke: #f5f5f5; /* Add a stroke to separate the rectangles slightly */
  stroke-width: 0.5;
}
.draggable-resizable-chart {
  position: fixed;
}
</style>

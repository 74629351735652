<template>
  <div class="mappy-charts-block">
    <DraggableChart
      :initial-x="calculateXPosition('weekday')"
      :initial-y="calculateYPosition('weekday')"
      :width="350"
      :height="chartHeight"
    >
      <weekday-chart
        v-if="dataReadyForChart('DAILY_HOURLY_ONGOING_STOPS')"
        :show-this="showThis('weekday')"
        graph-title="Vehicle stopped"
        struct-key="DAILY_HOURLY_ONGOING_STOPS"
        y-key="name_of_day"
        z-key="median"
        x-key="date"
        y-axis-label="Weekday"
        x-axis-label="Hour of day"
      />
      <toggle-visibility-btn
        toggle-for="Vehicles stopped"
        :is-visible="showThis('weekday')"
        class="chart-visibility-btn"
        @toggle-visibility="charts.weekday.showThis = !charts.weekday.showThis"
      />
    </DraggableChart>
    <DraggableChart
      :initial-x="calculateXPosition('fuel')"
      :initial-y="calculateYPosition('fuel')"
      :width="350"
      :height="chartHeight"
    >
      <boxplot-chart
        v-if="dataReadyForChart('FUEL_BOXPLOT')"
        :show-this="showThis('fuel')"
        graph-title="Fuel between stops"
        struct-key="FUEL_BOXPLOT"
        y-axis-label="Fuel (km)"
        y-key="totalfuel"
        x-key="chassisnumber"
        x-axis-label="Chassisnumber"
        x-axis-value="chassis"
      />
      <toggle-visibility-btn
        toggle-for="Fuel between stops"
        :is-visible="showThis('fuel')"
        class="chart-visibility-btn"
        @toggle-visibility="charts.fuel.showThis = !charts.fuel.showThis"
      />
    </DraggableChart>
    <DraggableChart
      :initial-x="calculateXPosition('odometer')"
      :initial-y="calculateYPosition('odometer')"
      :width="350"
      :height="chartHeight"
    >
      <boxplot-chart
        v-if="dataReadyForChart('ODOMETER_BOXPLOT')"
        :show-this="showThis('odometer')"
        graph-title="Distance between stops"
        struct-key="ODOMETER_BOXPLOT"
        y-axis-label="Mileage (km)"
        y-key="odometer"
        x-key="chassisnumber"
        x-axis-label="Chassisnumber"
        x-axis-value="chassis"
      />
      <toggle-visibility-btn
        toggle-for="Distance between stops"
        :is-visible="showThis('odometer')"
        class="chart-visibility-btn"
        @toggle-visibility="charts.odometer.showThis = !charts.odometer.showThis"
      />
    </DraggableChart>
    <!--    <DraggableChart-->
    <!--      :initial-x="calculateXPosition('dailyFuel')"-->
    <!--      :initial-y="calculateYPosition('dailyFuel')"-->
    <!--      :width="350"-->
    <!--      :height="180"-->
    <!--    >-->
    <!--      <boxplot-chart-->
    <!--        v-if="dataReadyForChart('DAILY_FUEL_BOXPLOT')"-->
    <!--        :show-this="showThis('dailyFuel')"-->
    <!--        get-state-function="fleetStore/getAggregate"-->
    <!--        graph-title="Daily fuel"-->
    <!--        struct-key="DAILY_FUEL_BOXPLOT"-->
    <!--        y-axis-label="Fuel (km)"-->
    <!--        y-key="totalfuel"-->
    <!--        x-key="chassisnumber"-->
    <!--        x-axis-label="Chassisnumber"-->
    <!--        x-axis-value="chassis"-->
    <!--      />-->
    <!--      <toggle-visibility-btn-->
    <!--        toggle-for="Daily fuel"-->
    <!--        :is-visible="showThis('dailyFuel')"-->
    <!--        class="chart-visibility-btn"-->
    <!--        @toggle-visibility="charts.dailyFuel.showThis = !charts.dailyFuel.showThis"-->
    <!--      />-->
    <!--    </DraggableChart>-->
    <!--    <DraggableChart-->
    <!--      :initial-x="calculateXPosition('dailyOdometer')"-->
    <!--      :initial-y="calculateYPosition('dailyOdometer')"-->
    <!--      :width="350"-->
    <!--      :height="180"-->
    <!--    >-->
    <!--      <boxplot-chart-->
    <!--        v-if="dataReadyForChart('DAILY_ODOMETER_BOXPLOT')"-->
    <!--        :show-this="showThis('dailyOdometer')"-->
    <!--        get-state-function="fleetStore/getAggregate"-->
    <!--        graph-title="Daily odometer"-->
    <!--        struct-key="DAILY_ODOMETER_BOXPLOT"-->
    <!--        y-axis-label="Mileage (km)"-->
    <!--        y-key="odometer"-->
    <!--        x-key="chassisnumber"-->
    <!--        x-axis-label="Chassisnumber"-->
    <!--        x-axis-value="chassis"-->
    <!--      />-->
    <!--      <toggle-visibility-btn-->
    <!--        toggle-for="Daily odometer"-->
    <!--        :is-visible="showThis('dailyOdometer')"-->
    <!--        class="chart-visibility-btn"-->
    <!--        @toggle-visibility="charts.dailyOdometer.showThis = !charts.dailyOdometer.showThis"-->
    <!--      />-->
    <!--    </DraggableChart>-->
  </div>
</template>


<script setup>
import {ref} from "vue"
import WeekdayChart from "@/components/D3graphs/WeekdayChart.vue"
import BoxplotChart from "@/components/D3graphs/BoxplotChart.vue"
import DraggableChart from "@/components/D3graphs/DraggableChart.vue"
import ToggleVisibilityBtn from "@/components/ToggleVisibilityBtn.vue"
import {useFleetStore} from "@/store/fleet/fleetStore"
import {useSetStore} from "@/store/setStore"

const fleetStore = useFleetStore()
const dataStore = useSetStore()

const charts = ref({
  weekday: {index: 0, showThis: true},
  fuel: {index: 1, showThis: true},
  odometer: {index: 2, showThis: true},
  dailyOdometer: {index: 3, showThis: true},
  dailyFuel: {index: 4, showThis: true}
})

const chartHeight = 140 // Same as in DraggableChart
const chartWidth = 350 // Same as in DraggableChart
const chartSpacing = 5 // Space between charts

const showThis = (chartName) => {
  return charts.value[chartName].showThis
}

const calculateYPosition = (chartName) => {
  const newPosition = charts.value[chartName].index * (chartHeight + chartSpacing)
  const maxYPosition = window.innerHeight - chartHeight // Maximum Y position within the viewport
  return Math.min(newPosition, maxYPosition)
}

const calculateXPosition = (chartName) => {
  return 0
}

const dataReadyForChart = (aggregateName) => {
  return dataStore.getAggregate(aggregateName) ?? false
}

</script>

<style scoped>
.mappy-charts-block {
  min-width: 350px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.chart-visibility-btn {
  writing-mode: vertical-rl;
  height: 100% !important;
  background-color: deeppink;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 12px;
  cursor: pointer;
  margin-left: 2px;
  min-width: 15px;
  max-width: 85px;
}

</style>

<style>
.chart-visibility-btn span.v-btn__content {
  white-space: normal;  /* Overwrite nowrap */
}

</style>
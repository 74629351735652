<template>
  <figure class="svg-container">
    <svg
      v-show="computedChartValues.visible"
      ref="svgContainer"
      class="svg-content"
      :viewBox="viewBox"
      preserveAspectRatio="xMidYMid meet"
      @mouseleave="removeTooltip()"
    >

      <g :transform="`translate(${margin.left/2}, ${margin.top})`">
        <text class="yAxisLabel">
          {{ yAxisLabel }}
        </text>
      </g>
      <g :transform="`translate(${chartProps.width - margin.left + 20 }, ${(chartProps.height+100)-margin.bottom})`">
        <text class="xAxisLabel">
          {{ xAxisLabel }}
        </text>
      </g>
      <template v-if="direction === 'vertical'">
        <transition-group tag="g">
          <g
            v-for="(bargroup, id) in groups"
            :key="generateVueUID(id)"
            :transform="`translate(${bandScale(bandAxisTicks[id])}, 0)`"
          >
            <rect
              v-for="(value, subgroup, index) in bargroup"
              :key="generateVueUID(index)"
              :x="bandSubgroupScale(subgroup)"
              :width="bandSubgroupScale.bandwidth()"
              :y="linearScale(value)"
              :height="linearScale(0) - linearScale(value)"
              :fill="color(subgroup)"
              class="animate"
              @click="populateTooltip($event, subgroup, value, id)"
              @mouseout="removeTooltip()"
            />
          </g>
        </transition-group>
        <g
          v-bandaxis="{scale: bandScale, direction: 'vertical', yTickFormat: yTickFormat, subSampleXAxis: shouldSubSampleAxis}"
          class="axis"
          :transform="`translate(0, ${chartProps.height - margin.bottom})`"
        />
        <g
          v-linearaxis="{scale: linearScale, direction: 'vertical', subSampleXAxis: shouldSubSampleAxis}"
          class="axis"
          :transform="`translate(${margin.left}, 0)`"
        />
      </template>
      <template v-else>
        <transition-group tag="g">
          <g
            v-for="(bargroup, id) in groups"
            :key="generateVueUID(id)"
            :transform="`translate(0 ,${bandScale(bandAxisTicks[id])})`"
          >
            <rect
              v-for="(value, subgroup) in bargroup"
              :key="generateVueUID(subgroup)"
              :x="linearScale(0)"
              :width="linearScale(value) - linearScale(0)"
              :y="bandSubgroupScale(subgroup)"
              :height="bandSubgroupScale.bandwidth()"
              :fill="color(subgroup)"
              @click="populateTooltip($event, subgroup, value, id)"
            />
          </g>
        </transition-group>
        <g
          v-linearaxis="{scale: linearScale, direction: 'horizontal', subSampleXAxis: shouldSubSampleAxis}"
          :transform="`translate(0, ${barAxisLocation === 'top' ? margin.top : chartProps.height - margin.bottom})`"
          class="axis"
        />
        <g
          v-bandaxis="{scale: bandScale, direction: 'horizontal', yTickFormat: yTickFormat, subSampleXAxis: shouldSubSampleAxis}"
          class="axis"
          :transform="`translate(${margin.left}, 0)`"
        />
      </template>
    </svg>
  </figure>
  <div
    v-if="showTooltip"
    class="tooltipContainer"
    :class="{ activeTooltip: showTooltip}"
    :style="{top: tooltip.y, left: tooltip.x}"
  >
    <slot
      name="tooltip"
      :lines="tooltip.values"
    >
      <span
        v-for="(value, key) in tooltip.values"
        :key="key"
        class="tooltip-text"
      >{{ key }}: {{ value }}</span>
    </slot>
  </div>
</template>

<script setup>
import * as d3 from "d3"
import {computed, inject, reactive, ref} from "vue"
import {log} from "@/plugin/logger"
import {useSetStore} from "@/store/setStore"


const props = defineProps({
  namedModule: {
    type: String,
    default: ""
  },
  showThis: {
    type: Boolean,
    default: true
  },
  structKey: {
    type: String,
    required: true
  },
  graphTitle: {
    type: String,
    default: "This will be a graph title, that can be long"
  },
  width: {
    type: Number,
    default: 600
  },
  height: {
    type: Number,
    default: 220
  },
  xKey: {
    type: String,
    default: ""
  },
  yKey: {
    type: String,
    default: ""
  },
  xAxisLabel: {
    type: String,
    default: ""
  },
  yAxisLabel: {
    type: String,
    default: ""
  },
  legend: {
    type: Boolean,
    default: false
  },
  direction: {
    type: String,
    default: "vertical",
    validator: function (value) {
      return ["vertical", "horizontal"].indexOf(value) !== -1
    }
  },
  barAxisLocation: {
    type: String,
    default: "bottom",
    validator: function (value) {
      return ["top", "bottom"].indexOf(value) !== -1
    }
  },
  paddingBetweenBars: {
    type: Number,
    default: 0.15,
    validator: function (value) {
      return value >= 0 && value <= 1
    }
  },
  paddingBetweenGroups: {
    type: Number,
    default: 0.15,
    validator: function (value) {
      return value >= 0 && value <= 1
    }
  },
  xTickFormat: {
    type: Function,
    default: null
  },
  yTickFormat: {
    type: Function,
    default: null
  },
  xMin: {
    type: Number,
    default: null
  },
  xMax: {
    type: Number,
    default: null
  },
  yMin: {
    type: Number,
    default: null
  },
  yMax: {
    type: Number,
    default: null
  },
  useOnlyXKey: {
    type: Boolean,
    default: false
  }
})

const useCustomStore = props.namedModule.length > 0 ? inject(props.namedModule) : useSetStore()
const padding = ref(60)
const showTooltip = ref(false)
const colours = ref(["#440154FF", "#404788FF", "#287D8EFF", "#20A387FF", "#73D055FF", "#FDE725FF"])
const svgContainer = ref()

// Group related properties
const chartProps = reactive({
  structKey: props.structKey,
  fontSize: "0.7rem",
  graphTitle: props.graphTitle,
  width: props.width,
  height: props.height,
  xKey: props.xKey,
  yKey: props.yKey,
  xAxisLabel: props.xAxisLabel,
  yAxisLabel: props.yAxisLabel,
  margin: { top: 20, right: 1, bottom: 60, left: 100 },
  xAxisLabelShift: { dx: 90, dy: 85 },
  yAxisLabelShift: { dx: -40, dy: -55 },
  xAxisTickLabelFontSize: "11px",
  direction: props.direction,
  paddingBetweenBars: props.paddingBetweenBars,
  paddingBetweenGroups: props.paddingBetweenGroups,
  barAxisLocation: props.barAxisLocation,
  xTickFormat: props.xTickFormat,
  yTickFormat:props.yTickFormat,
  xMin: props.xMin,
  xMax: props.xMax,
  yMin: props.yMin,
  yMax: props.yMax,
  useOnlyXKey: props.useOnlyXKey
  
})
const tooltip = ref({
      x: 0,
      y: 0,
      values: {}
})
const computedChartValues = computed(() => {
  const visible = props.showThis

  return {
    visible
  }
})

function getMax (array) {
  return d3.max(array.map(item => d3.max(Object.values(item))))
}

function generateVueUID () {
  return Math.random(Number.MAX_SAFE_INTEGER)
}

function populateTooltip (evt, key, group, tickIndex) {
  const svgRect = svgContainer.value.getBoundingClientRect()

  // Calculate tooltip position relative to SVG container
  tooltip.value.x = `${evt.clientX - svgRect.left}px`
  tooltip.value.y = `${evt.clientY - svgRect.top}px`

  tooltip.value.values = plotData.value[tickIndex]
  showTooltip.value = true
  log("info", "{\"populateTooltip\":\"Clicked data in Bar Chart\"}")
}

function removeTooltip () {
  showTooltip.value = false
}

const plotData = computed(() => {
  return useCustomStore.getAggregate(chartProps.structKey)
})

const groupKeys = computed(() => {
  let groupKeys = Object.keys(plotData.value[0]).filter(item => item !== chartProps.xKey)
  if (chartProps.useOnlyXKey) {
    groupKeys = [ chartProps.yKey ]
  }
  return chartProps.direction === "vertical" ? groupKeys : groupKeys.reverse()
})

const legendData = computed(() => {
  let dataForLegend = []
  groupKeys.value.forEach(barGroup => {
    dataForLegend.push({ name: barGroup, color: color.value(barGroup) })
  })
  return dataForLegend
})

const groups = computed(() => {
  if (chartProps.useOnlyXKey) {
    return plotData.value.map(data => ({ [ chartProps.yKey ]: data[chartProps.yKey] }))
  }

  return plotData.value.map(({ [ chartProps.xKey ]: name, ...rest }) => rest)
})

const shouldSubSampleAxis = computed(() => {
  return groups.value.length > 29
})

const height = computed(() => {
  const strokeWidth = 25
  return chartProps.height - chartProps.margin.top - chartProps.margin.bottom - (strokeWidth * 2)
})

const viewBox = computed(() => {
  return `0 0 ${chartProps.width} ${chartProps.height+130}`
})

const bandAxisTicks = computed(() => {
  if(plotData.value)  return plotData.value.map(item => item[chartProps.xKey])
  return null
})

const color = computed(() => {
  return d3.scaleOrdinal().range(colours.value)
})

const bandScale = computed(() => {
  const bandScale = d3.scaleBand()
      .domain(bandAxisTicks.value)
      .padding(chartProps.paddingBetweenGroups)

  return chartProps.direction === "vertical"
      ? bandScale.range([chartProps.margin.left, chartProps.width - chartProps.margin.right])
      : bandScale.range([chartProps.margin.top, chartProps.height - chartProps.margin.bottom])
})

const bandSubgroupScale = computed(() => {
  const bandScaleLoc = d3.scaleBand()
      .domain(groupKeys.value)
      .padding(chartProps.paddingBetweenBars)

  return chartProps.direction === "vertical"
      ? bandScaleLoc.rangeRound([0, bandScale.value.bandwidth()])
      : bandScaleLoc.rangeRound([bandScale.value.bandwidth(), 0])
})

const margin = computed(() => {
  return chartProps.margin
})

const linearScale = computed(() => {
  // determine whether x or y min/max scale limits should be applied based on direction of chart
  const values = chartProps.direction === "vertical" ? [chartProps.yMin, chartProps.yMax] : [chartProps.xMin, chartProps.xMax]
  const minValue = values[0] ? values[0] : 0
  const maxValue = values[1] ? values[1] : getMax(groups.value)

  const linearScale = d3.scaleLinear()
      .domain([minValue, maxValue])
      .nice()
  return chartProps.direction === "vertical"
      ? linearScale.range([chartProps.height - chartProps.margin.bottom, chartProps.margin.top])
      : linearScale.range([chartProps.margin.left, chartProps.width - chartProps.margin.right])
})



</script>

<script>
import { bandaxis } from "@/directives/bandaxis.js"
import { linearaxis } from "@/directives/linearaxis.js"

export default {
  directives: {
    bandaxis,
    linearaxis
  }
}
</script>


<style scoped>
.yAxisLabel {
  transform: rotate(-90deg);
  text-anchor: end;
  font-size: 1.2rem;
  fill: var(--tds-grey-800);
}

.xAxisLabel {
  text-anchor: end;
  font-size: 1.2rem;
  fill: var(--tds-grey-800);
}

.tooltipContainer {
  position: fixed;
  font-size: 0.8rem;
  font-family: "Scania Sans Semi Condensed", "Scania Sans Condensed", Arial, Helvetica, sans-serif;
  color: #2c3e50;
  background-color: #ffffff;
  opacity: 0;
  pointer-events: none;
  border: 1px solid #ddd;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  border-radius: 4px;
  padding: 8px;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
}
.activeTooltip {
  opacity: 0.95;
  z-index: 6;
}
.tooltipContainer span {
  display: block;
}

.svg-container {
  width: 100%;
  vertical-align: top;
  display: block;
  background-color: white;
  color: #2c3e50;
  font-weight: 300;
  font-family: "Scania Sans Semi Condensed", "Scania Sans Condensed", Arial, Helvetica, sans-serif;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
}

.axis {
  color: var(--tds-grey-600);
}

.animate {
  fill: rgb(255, 0, 128);
}

</style>

<style>
.tickText {
  line-height: 1.14;
  font-family: "Scania Sans", arial, helvetica, sans-serif;
  font-weight: bold;
  font-size: 0.875rem;
  letter-spacing: -0.02em;
}
</style>
<template>
  <div
    ref="root"
    v-bind="$attrs"
  >
    <div
      id="popup"
      ref="htmlPopup"
    />
  </div>
  <div v-if="isLoaded">
    <slot />
  </div>
  <div v-else>
    <slot name="loader" />
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl"
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder"
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css"
if (!mapboxgl) {
  throw new Error("mapboxgl is not installed.")
}

const { LngLatBounds, LngLat } = mapboxgl

/**
 * Component's props definition, we need to declare it outside the component
 * to be able to test the default values and the types.
 * @see  https://docs.mapbox.com/mapbox-gl-js/api/#map
 * @type {Object}
 */
const propsConfig = {
  accessToken: {
    type: String,
    default: "no-token"
  },
  container: {
    type: [typeof HTMLElement !== "undefined" && HTMLElement, String],
    default: undefined
  },
  minZoom: {
    type: Number,
    default: 0
  },
  maxZoom: {
    type: Number,
    default: 22
  },
  minPitch: {
    type: Number,
    default: 0
  },
  maxPitch: {
    type: Number,
    default: 60
  },
  mapStyle: {
    type: [Object, String],
    required: true
  },
  hash: {
    type: Boolean,
    default: false
  },
  interactive: {
    type: Boolean,
    default: true
  },
  bearingSnap: {
    type: Number,
    default: 7
  },
  pitchWithRotate: {
    type: Boolean,
    default: true
  },
  clickTolerance: {
    type: Number,
    default: 3
  },
  attributionControl: {
    type: Boolean,
    default: false
  },
  customAttribution: {
    type: [String, Array],
    default: null
  },
  logoPosition: {
    type: String,
    default: "bottom-left"
  },
  failIfMajorPerformanceCaveat: {
    type: Boolean,
    default: false
  },
  preserveDrawingBuffer: {
    type: Boolean,
    default: false
  },
  antialias: {
    type: Boolean,
    default: false
  },
  refreshExpiredTiles: {
    type: Boolean,
    default: true
  },
  maxBounds: {
    type: [LngLatBounds, Array],
    default: undefined
  },
  scrollZoom: {
    type: [Boolean, Object],
    default: true
  },
  boxZoom: {
    type: Boolean,
    default: true
  },
  dragRotate: {
    type: Boolean,
    default: true
  },
  dragPan: {
    type: [Boolean, Object],
    default: true
  },
  keyboard: {
    type: Boolean,
    default: true
  },
  doubleClickZoom: {
    type: Boolean,
    default: true
  },
  touchZoomRotate: {
    type: [Boolean, Object],
    default: true
  },
  trackResize: {
    type: Boolean,
    default: true
  },
  center: {
    type: [LngLat, Array, Object],
    default: () => [0, 0]
  },
  zoom: {
    type: Number,
    default: 0
  },
  bearing: {
    type: Number,
    default: 0
  },
  pitch: {
    type: Number,
    default: 0
  },
  bounds: {
    type: [LngLatBounds, Array],
    default: undefined
  },
  fitBoundsOptions: {
    type: Object,
    default: null
  },
  renderWorldCopies: {
    type: Boolean,
    default: true
  },
  maxTileCacheSize: {
    type: Number,
    default: null
  },
  localIdeographFontFamily: {
    type: String,
    default: "sans-serif"
  },
  transformRequest: {
    type: Function,
    default: null
  },
  collectResourceTiming: {
    type: Boolean,
    default: false
  },
  fadeDuration: {
    type: Number,
    default: 300
  },
  crossSourceCollisions: {
    type: Boolean,
    default: true
  },
  showSearchInput: {
      type: Boolean,
      default: false
    }
}

/**
 * All Map events which will be mapped/bounded to the component
 * @see  https://docs.mapbox.com/mapbox-gl-js/api/#map.event:resize
 * @type {Array}
 */
const events = [
  "boxzoomcancel",
  "boxzoomend",
  "boxzoomstart",
  "click",
  "contextmenu",
  "data",
  "dataloading",
  "dblclick",
  "drag",
  "dragend",
  "dragstart",
  "error",
  "idle",
  "load",
  "mousedown",
  "mouseenter",
  "mouseleave",
  "mousemove",
  "mouseout",
  "mouseover",
  "mouseup",
  "move",
  "moveend",
  "movestart",
  "pitch",
  "pitchend",
  "pitchstart",
  "popupopen",
  "popupclose",
  "remove",
  "render",
  "resize",
  "rotate",
  "rotateend",
  "rotatestart",
  "sourcedata",
  "sourcedataloading",
  "styledata",
  "styledataloading",
  "styleimagemissing",
  "touchcancel",
  "touchend",
  "touchmove",
  "touchstart",
  "webglcontextlost",
  "webglcontextrestored",
  "wheel",
  "zoom",
  "zoomend",
  "zoomstart"
]

export default {
  inheritAttrs: false
}
</script>

<script setup>
import { ref, computed, onMounted, onUnmounted, provide, unref } from "vue"
import { useEventsBinding, usePropsBinding } from "@/composables"
import { log } from "@/plugin/logger.js"

const props = defineProps(propsConfig)
// eslint-disable-next-line vue/valid-define-emits
const emit = defineEmits()

const map = ref()
provide("mapbox-map", map)

const root = ref()
const htmlPopup = ref()
const isLoaded = ref(false)

const options = computed(() => {
  const { accessToken, mapStyle: style, ...options } = props

  // Use current component's element if container is not set
  if (!options.container && root.value) {
    options.container = root.value
  }
  const locale = {locale: { "NavigationControl.ResetBearing": "Hold ´ctrl´ + mouse click and drag to adjust tilt." }}
  return { style, projection: "mercator", ...locale, ...options }
})


useEventsBinding(emit, map, events)
usePropsBinding(props, map, propsConfig)

// Mapbox has some resize issues
// Create an observer on this object
// Call resize on the map when we change size
const resizeObserver = new ResizeObserver(() => {
  setTimeout(() => map.value.resize(), 0)
})

//Local geocoder for reverse geocoding
const LatLngGeocoder = function (query) {
// Match anything which looks like decimal degrees coordinate pair.
  const matches = query.match(
      /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i
  )
  if (!matches) {
    return null
  }

  function latlngFeature(lng, lat) {
    return {
      center: [lng, lat],
      geometry: {
        type: "Point",
        coordinates: [lng, lat]
      },
      place_name: "Lat: " + lat + " Lng: " + lng,
      place_type: ["coordinate"],
      properties: {},
      type: "Feature"
    }
  }

  const coord1 = Number(matches[1])
  const coord2 = Number(matches[2])
  const geocodes = []

  if (coord1 < -90 || coord1 > 90) {
// must be lng, lat
    geocodes.push(latlngFeature(coord1, coord2))
  }

  if (coord2 < -90 || coord2 > 90) {
// must be lat, lng
    geocodes.push(latlngFeature(coord2, coord1))
  }

  if (geocodes.length === 0) {
// else could be either lng, lat or lat, lng
    geocodes.push(latlngFeature(coord1, coord2))
    geocodes.push(latlngFeature(coord2, coord1))
  }

  return geocodes
}

onMounted(() => {
  mapboxgl.accessToken = props.accessToken
  map.value = new mapboxgl.Map(options.value)

  map.value.on("load", () => {
    isLoaded.value = true
  })

  map.value.on("style.load", () => {
    log("info", "Basemap style changed.")
    unref(map).fire("styleChanged")
  })

  emit("mb-created", map.value)

  resizeObserver.observe(options.value.container)

  // Add Search capability for main map only after authorisation of users.
  if(props.showSearchInput){
    addSearchDiv(map)
    addSearchButton(map)
  }
  function addSearchDiv(map) {
    class SearchDiv {
      onAdd(map) {
        const searchDiv = document.createElement("div")
        searchDiv.className = "my-mapbox-geocoder"
        searchDiv.id="my-mapbox-geocoder"
        return searchDiv
      }
    }
    const searchDiv = new SearchDiv()
    map.value.addControl(searchDiv, "bottom-right")
  }
  function addSearchButton(map) {
    class SearchButton {
      onAdd(map) {
        const div = document.createElement("div")
        div.className = "mapboxgl-ctrl mapboxgl-ctrl-group"
        div.innerHTML = `<div class="tools-box" title="Search"><button><svg class="my--icon my--icon-search" viewBox="0 0 18 18" xml:space="preserve" width="18" height="18"><path d="M7.4 2.5c-2.7 0-4.9 2.2-4.9 4.9s2.2 4.9 4.9 4.9c1 0 1.8-.2 2.5-.8l3.7 3.7c.2.2.4.3.8.3.7 0 1.1-.4 1.1-1.1 0-.3-.1-.5-.3-.8L11.4 10c.4-.8.8-1.6.8-2.5.1-2.8-2.1-5-4.8-5zm0 1.6c1.8 0 3.2 1.4 3.2 3.2s-1.4 3.2-3.2 3.2-3.3-1.3-3.3-3.1 1.4-3.3 3.3-3.3z"></path></svg>
            </button></div>`
        div.addEventListener("contextmenu", (e) => e.preventDefault())
        div.addEventListener("click", () => toggleGeocoder(map))
        return  div
      }
    }
    const searchButton = new SearchButton()
    map.value.addControl(searchButton, "bottom-right")
  }

  const showGeocoder = ref(true)

  function toggleGeocoder(map){
    const geocoderDiv = document.getElementById("my-mapbox-geocoder")
    if(showGeocoder.value){
      geocoderDiv.style.display = "block"
      if (geocoderDiv && geocoderDiv.childElementCount < 1){
        let geocoder = new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          localGeocoder: LatLngGeocoder,
          mapboxgl: mapboxgl,
          reverseGeocode: true,
          placeholder: "Input Address"
        })
        geocoderDiv.appendChild(geocoder.onAdd(map))
      }
    } else {
      if (geocoderDiv) {
        geocoderDiv.style.display = "none"
      }
    }
    showGeocoder.value = !showGeocoder.value
  }

  map.value.addControl(new mapboxgl.NavigationControl({
    visualizePitch: true
  }), "bottom-right")

})

onUnmounted(() => {
  resizeObserver.disconnect()
  map.value.remove()
})

defineExpose({ map })


</script>
<style>
.mapboxgl-ctrl-geocoder {
  font-size: 14px;
  line-height: 20px;
  font-family: "Scania Sans";
  position: relative;
  background-color: #0d0f13;
  width: 70%;
  min-width: 150px;
  z-index: 1;
  border-radius: 4px;
  transition: width .25s, min-width .25s;
  opacity:70%;
}

.mapboxgl-ctrl-geocoder--input {
  font: inherit;
  width: 100%;
  border: 0;
  background-color: transparent;
  margin: 0;
  height: 40px;
  color: deeppink;
  padding: 6px 45px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mapboxgl-ctrl-geocoder--input:focus {
  color: #404040; /* fallback */
  color: white;
  outline: 0;
  box-shadow: none;
  outline: thin dotted;
}

/* Icons */
.mapboxgl-ctrl-geocoder--icon {
  display: inline-block;
  vertical-align: middle;
  speak: none;
  fill: black;
  top: 15px;
  display: none;
}

.mapboxgl-ctrl-geocoder--icon-search {
  position: absolute;
  top: 3px;
  left: 4px;
  width: 23px;
  height: 23px;
  display: none;
}

.my--icon {
  display: inline-block;
  vertical-align: middle;
  speak: none;
  fill: black;
  top: 15px;
}

.my--icon-search {
  position: absolute;
  top: 3px;
  left: 4px;
  width: 23px;
  height: 23px;
}

.mapboxgl-ctrl-geocoder--button {
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;
  background: transparent;
  line-height: 1;
}

.mapboxgl-ctrl-geocoder--button:hover .mapboxgl-ctrl-geocoder--icon-close {
  fill: deeppink;
}

.mapboxgl-ctrl-geocoder--powered-by {
  display: none;
  float: left;
  padding: 6px 12px;
  padding-bottom: 9px;
  font-size: 13px;
}

.mapboxgl-ctrl-geocoder--powered-by a {
  color: transparent;
}

.mapboxgl-ctrl-geocoder--powered-by a:not(:hover) {
  text-decoration: none;
}

.my-mapbox-geocoder {
  position: relative;
  width: 300px;
  height:21px;
}

.mapboxgl-ctrl-top-right .mapboxgl-ctrl-group {
  border: 1px solid rgb(255, 0, 128);
  color: rgb(255, 0, 128) !important;
  margin-right: 60px !important;
  margin-top: 20px !important;
  display: flex;
}

.mapboxgl-ctrl-top-right .mapboxgl-ctrl-group button {
  border-right: 1px solid #ddd !important;
}
</style>